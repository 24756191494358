import React, { useMemo } from "react";
import {
  EdgeProps,
  getBezierPath,
  EdgeLabelRenderer,
  BaseEdge,
  Edge,
} from "@xyflow/react";
import { getCustomEdgeStyles } from "./CustomEdge.styles";

const CustomEdge: React.FC<EdgeProps<Edge<{ label: string }>>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label,
  data,
  selected,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const styles = useMemo(() => {
    return getCustomEdgeStyles(selected ?? false, labelX, labelY);
  }, [selected, labelX, labelY]);

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={styles.baseEdge} />
      <EdgeLabelRenderer>
        <div
          style={styles.edgeLabelRenderer}
          className="nodrag nopan"
        >
          {label ?? data?.label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
