import { Grid, Typography, Button, useMediaQuery } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { getBannerStyles } from "./Banner.styles";

export const Banner: React.FC = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const styles = getBannerStyles(matches);
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={styles.rootContainer}
    >
      <Grid item xs={12}>
        <AutoAwesomeIcon sx={styles.magicIcon} />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={styles.welcome}>Welcome to Studio</Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={styles.headlineContainer}>
        <Typography variant="h3" sx={styles.headline} textAlign={"center"}>
          Create, Practice, and Perfect Your System Designs with Our Interactive
          Studio
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" sx={styles.author} textAlign={"center"}>
          — Sunil Gudivada —
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          sx={styles.startNowButton}
          onClick={() => {
            window.open("/flow/new", "_blank");
          }}
        >
          Start Designing Now
        </Button>
      </Grid>
    </Grid>
  );
};
