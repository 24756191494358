import React, { useEffect, useMemo } from "react";
import { getCustomNodeStyles } from "./CustomNode.styles";
import { Grid, Paper, Typography } from "@mui/material";
import { useUpdateNodeInternals } from "@xyflow/react";
import { ICustomNodeProps, IHandle } from "./CustomNode.types";
import { useGetNodeProperties } from "./hooks/useGetNodeProperties";
import { CustomHandle } from "../../../ui-library/custom-handle/CustomHandle";
import { getDefaultHandles } from "./hooks/useGetDefaultHandles";

export const CustomNode: React.FC<ICustomNodeProps> = ({
  id: nodeId,
  data,
  type,
  isConnectable,
  selected,
  width = 0,
  height = 0,
}) => {
  const { label, style: styleOverrides } = data;
  const nodeProperties = useGetNodeProperties(type);

  // const handles = nodeProperties.handles || [];

  console.log(data);

  const styles = useMemo(
    () =>
      getCustomNodeStyles({
        ...nodeProperties,
        backgroundColor:
          styleOverrides?.backgroundColor ?? nodeProperties.backgroundColor,
        styleProperties: styleOverrides,
        isActive: selected,
      }),
    [selected, nodeProperties, styleOverrides]
  );

  useEffect(() => {
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  const defaultHandles: IHandle[] = getDefaultHandles(width, height);

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals(nodeId);
  }, [nodeId, updateNodeInternals]);

  return (
    <Paper elevation={5} sx={styles.rootContainer} key={nodeId}>
      {/* {selected && <NodeResizer />} */}
      {defaultHandles?.map((handle) => (
        <CustomHandle
          key={`${nodeId}-${handle.type}-${handle.position}`}
          type={handle.type}
          position={handle.position}
          id={`${nodeId}-${handle.type}-${handle.position}`}
          isConnectable={isConnectable}
          index={handle.index}
        />
      ))}

      {/* <CustomHandle
        type="source"
        position={Position.Right}
        id={"a"}
        isConnectable={isConnectable}
        index={height / 2}
      /> */}
      <Grid
        container
        display="flex"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <img src={nodeProperties.imgSrc} alt={label} style={styles.image} />
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={styles.label}>
            {label}
          </Typography>
        </Grid>
      </Grid>
      {/* <CustomHandle
        type="target"
        position={Position.Left}
        id={"c"}
        isConnectable={isConnectable}
        index={height / 2}
      /> */}
    </Paper>
  );
};
