import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import stockBrokerImg from "../../../assets/thumbnail/stock-broker-design.png";
import upiImg from "../../../assets/thumbnail/upi-design.png";
import cachingTechinques from "../../../assets/thumbnail/caching-techinques.png";
import instagramCloseFriend from "../../../assets/thumbnail/Instagram-Close-friends-thumbnail.png";
import idempotencyPaymentSystem from "../../../assets/thumbnail/idempotency-payment-system.png";
import { getArticleStyles } from "./Articles.styles";

const itemData = [
  {
    id: "payment-idempotency-system-design",
    link: "/flow/payment-idempotency-system-design",
    img: idempotencyPaymentSystem,
    title: "Handle Idempotency in Payment System Design",
    author: "Sunil Gudivada",
  },
  {
    id: "instagram-close-friends-system-design",
    link: "/flow/instagram-close-friends-system-design",
    img: instagramCloseFriend,
    title: "Instagram Close Friends System Design",
    author: "Sunil Gudivada",
  },
  {
    id: "stock-broker-system-design",
    link: "/flow/stock-broker-system-design",
    img: stockBrokerImg,
    title: "Groww System Design",
    author: "Sunil Gudivada",
  },
  {
    id: "caching-techniques",
    link: "/flow/caching-techniques",
    img: cachingTechinques,
    title: "Caching Strategies",
    author: "Sunil Gudivada",
  },
  {
    id: "upi-system-design",
    link: "https://blog.sunilgudivada.dev/notebook/system-design/upi-payment-system-design",
    img: upiImg,
    title: "UPI System Design",
    author: "Sunil Gudivada",
  },
];

export const Articles: React.FC = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const styles = getArticleStyles(matches);
  return (
    <Grid container sx={styles.rootContainer} alignContent={"center"}>
      <Typography variant="h5">Recent Design</Typography>
      <Grid item>
        <Box>
          <ImageList
            variant="masonry"
            cols={matches ? 3 : 1}
            gap={matches ? 10 : 20}
          >
            {itemData.map((item) => (
              <ImageListItem
                key={item.img}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(item.link, "_blank");
                }}
              >
                <img
                  srcSet={item.img}
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  position="below"
                  title={
                    <Typography variant="h6" sx={styles.author}>
                      {item.title}
                    </Typography>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Grid>
    </Grid>
  );
};
