import { type Node, type Edge } from "@xyflow/react";

export const nodes: Node[] = [
  {
    id: "user",
    type: "client",
    data: {
      label: "Users",
      description:
        "Users interact with the Groww platform through various client applications. They perform actions such as checking portfolios, placing buy/sell orders, and viewing market data. User authentication and authorization are handled to ensure secure access.",
    },
    position: { x: -173.3684542586751, y: 195.8378548895899 },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "api-gateway",
    type: "api_gateway",
    data: {
      label: "API Gateway",
      description:
        "The API Gateway acts as the single entry point for all client requests. It routes the requests to appropriate backend services, handles authentication, rate limiting, and can perform cross-cutting concerns such as logging and metrics collection.",
    },
    position: { x: 55.71230283911673, y: 194.45047318611986 },
    measured: { width: 90, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "identity-service",
    type: "microservice",
    data: {
      label: "Identity Service",
      description:
        "The Identity Service manages user authentication and authorization. It interfaces with the MySQL database to verify user credentials, generate tokens, and maintain session information. This service ensures secure user access to the platform.",
    },
    position: { x: 44.57521013782039, y: 49.66773996429675 },
    measured: { width: 114, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "mysql",
    type: "database",
    data: {
      label: "MySQL",
      description:
        "The MySQL database stores user information, credentials, and session data for the Identity Service. It ensures reliable and secure storage of sensitive user data, supporting ACID transactions for data integrity.",
    },
    position: { x: 62.73242924974568, y: -115.80290079412578 },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "portfolio-service",
    type: "microservice",
    data: {
      label: "Portfolio Service",
      description:
        "The Portfolio Service manages user portfolios, including the retrieval and update of portfolio data. It interacts with the MySQL database to fetch and persist portfolio information, ensuring real-time portfolio management.",
    },
    position: { x: 333.8883540315767, y: 21.689494554981913 },
    measured: { width: 127, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "portfolio-db",
    type: "database",
    data: {
      label: "MySQL DB",
      description:
        "The MySQL DB used by the Portfolio Service stores all user portfolio data. It ensures quick retrieval and updates of portfolio information, supporting efficient and reliable portfolio management.",
    },
    position: { x: 354.9962404669078, y: -108.61219847904214 },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "order-service",
    type: "microservice",
    data: {
      label: "Order Service",
      description:
        "The Order Service handles the placement and management of buy and sell orders. It ensures that orders are correctly processed and routed to the appropriate services for execution, maintaining the integrity of user transactions.",
    },
    position: { x: 355.79667988956714, y: 288.6043446465913 },
    measured: { width: 104, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "price-update-service",
    type: "microservice",
    data: {
      label: "Price Update Service",
      description:
        "The Price Update Service captures real-time price changes and updates relevant services. It interacts with external data sources and Debezium for real-time data capture, ensuring accurate and up-to-date market information.",
    },
    position: { x: 964.3316426783923, y: 562.3541741962817 },
    measured: { width: 142, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "debezium",
    type: "database",
    data: {
      label: "Debezium",
      description:
        "Debezium is used for change data capture, allowing the system to capture and stream changes in database tables in real-time. It ensures that all services have access to the latest data without significant latency.",
    },
    position: { x: 995.3413095606386, y: 684.4936888236067 },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "buy-service",
    type: "message_queue",
    data: {
      label: "Buy",
      description:
        "The Buy Queue receives buy orders from the Order Service and processes them for execution. It ensures that buy orders are correctly matched and executed, maintaining the integrity of user transactions.",
    },
    position: { x: 614.5433603916546, y: 223.09232080291986 },
    measured: { width: 154, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "sell-service",
    type: "message_queue",
    data: {
      label: "Sell",
      description:
        "The Sell Queue receives sell orders from the Order Service and processes them for execution. It ensures that sell orders are correctly matched and executed, maintaining the integrity of user transactions.",
    },
    position: { x: 624.4004628210795, y: 355.03412769502626 },
    measured: { width: 154, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "queue-processor",
    type: "microservice",
    data: {
      label: "Queue Processor",
      description:
        "The Queue Processor processes buy and sell orders from the respective queues and matches them based on market conditions. It ensures that orders are correctly matched and executed, maintaining the integrity of user transactions.",
    },
    position: { x: 971.8469708835703, y: 213.36699500289953 },
    measured: { width: 124, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "order-matcher",
    type: "microservice",
    data: {
      label: "Order Matcher",
      description:
        "The Order Matcher matches buy and sell orders based on market conditions and executes the trades. It ensures that trades are executed accurately and in a timely manner, maintaining the integrity of user transactions.",
    },
    position: { x: 978.9768116099108, y: 79.18221874172303 },
    measured: { width: 110, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "trade-executor",
    type: "microservice",
    data: {
      label: "Trade Executor",
      description:
        "The Trade Executor executes trades on the stock exchange based on matched buy and sell orders. It ensures that trades are executed accurately and in a timely manner, maintaining the integrity of user transactions.",
    },
    position: { x: 1471.2591484032544, y: 73.46548969731545 },
    measured: { width: 114, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "market-service",
    type: "microservice",
    data: {
      label: "Market Service",
      description:
        "The Market Service manages market data, including price feeds and trade status updates. It interacts with the Stock Exchange and other data sources to provide accurate and timely market information to users and other services.",
    },
    position: { x: 1479.7462629767347, y: 212.8118110039205 },
    measured: { width: 111, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "trade-history",
    type: "microservice",
    data: {
      label: "Trade History",
      description:
        "The Trade History Service records and manages trade history data. It ensures that all trade transactions are logged and retrievable for reporting and analysis, interacting with the NoSQL database for efficient data storage.",
    },
    position: { x: 1483.2548356741318, y: 402.71424489405314 },
    measured: { width: 103, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "nosql-db",
    type: "database",
    data: {
      label: "NoSQL DB",
      description:
        "The NoSQL database stores trade history data. It provides high-performance storage and retrieval capabilities, supporting the Trade History Service with efficient and scalable data management.",
    },
    position: { x: 1495.3825648936047, y: 526.8119475154724 },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "stock-exchange",
    type: "external_service",
    data: {
      label: "Stock Exchange",
      description:
        "The Stock Exchange is the external entity where trades are executed. It interacts with the Trade Executor and Market Service to ensure that all buy and sell orders are processed according to market conditions.",
    },
    position: { x: 1729.910016672476, y: 253.77231223142653 },
    measured: { width: 116, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "notification-alert",
    type: "microservice",
    data: {
      label: "Notification & Alert",
      description:
        "The Notification & Alert Service sends notifications and alerts to users based on their preferences. It ensures that users are informed about important events such as order status updates, price changes, and market alerts.",
    },
    position: { x: 708.2736976114892, y: 562.6283916360344 },
    measured: { width: 138, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "dndnode_0",
    type: "client",
    position: { x: 548.3099350793661, y: 666.9855112955589 },
    data: { label: "client" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "dndnode_1",
    type: "client",
    position: { x: 801.2400991570743, y: 724.0357466879434 },
    data: { label: "client" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "dndnode_2",
    type: "client",
    position: { x: 655.890159676461, y: 726.2915418686662 },
    data: { label: "client" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "dndnode_3",
    type: "message_queue",
    position: { x: 1215.4104682623638, y: 213.4901729349023 },
    data: { label: "market" },
    measured: { width: 154, height: 72 },
    selected: false,
    dragging: false,
  },
];

export const edges: Edge[] = [
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "identity-service",
    sourceHandle: "identity-service-source-top",
    target: "mysql",
    targetHandle: "mysql-source-bottom",
    selected: false,
    label: "Fetches Credentials",
    data: { label: "Fetches Credentials" },
    id: "xy-edge__identity-serviceidentity-service-source-top-mysqlmysql-source-bottom",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "api-gateway",
    sourceHandle: "api-gateway-source-top",
    target: "identity-service",
    targetHandle: "identity-service-source-bottom",
    id: "xy-edge__api-gatewayapi-gateway-source-top-identity-serviceidentity-service-source-bottom",
    selected: false,
    label: "Authenticates",
    data: { label: "Authenticates" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "user",
    sourceHandle: "user-source-right",
    target: "api-gateway",
    targetHandle: "api-gateway-source-left",
    id: "xy-edge__useruser-source-right-api-gatewayapi-gateway-source-left",
    selected: false,
    label: "Sends Requests",
    data: { label: "Sends Requests" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "api-gateway",
    sourceHandle: "api-gateway-source-right",
    target: "order-service",
    targetHandle: "order-service-source-left",
    id: "xy-edge__api-gatewayapi-gateway-source-right-order-serviceorder-service-source-left",
    selected: false,
    label: "Routes Order Requests",
    data: { label: "Routes Order Requests" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "api-gateway",
    sourceHandle: "api-gateway-source-right",
    target: "portfolio-service",
    targetHandle: "portfolio-service-source-left",
    id: "xy-edge__api-gatewayapi-gateway-source-right-portfolio-serviceportfolio-service-source-left",
    selected: false,
    label: "Routes Portfolio Requests",
    data: { label: "Routes Portfolio Requests" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "portfolio-service",
    sourceHandle: "portfolio-service-source-top",
    target: "portfolio-db",
    targetHandle: "portfolio-db-source-bottom",
    id: "xy-edge__portfolio-serviceportfolio-service-source-top-portfolio-dbportfolio-db-source-bottom",
    selected: false,
    label: "Fetches/Updates Portfolio Data",
    data: { label: "Fetches/Updates Portfolio Data" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "order-service",
    sourceHandle: "order-service-source-right",
    target: "sell-service",
    targetHandle: "sell-service-source-left",
    id: "xy-edge__order-serviceorder-service-source-right-sell-servicesell-service-source-left",
    selected: false,
    label: "Sends Sell Orders",
    data: { label: "Sends Sell Orders" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "order-service",
    sourceHandle: "order-service-source-right",
    target: "buy-service",
    targetHandle: "buy-service-source-left",
    id: "xy-edge__order-serviceorder-service-source-right-buy-servicebuy-service-source-left",
    selected: false,
    label: "Sends Buy Orders",
    data: { label: "Sends Buy Orders" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "sell-service",
    sourceHandle: "sell-service-source-right",
    target: "queue-processor",
    targetHandle: "queue-processor-source-left",
    id: "xy-edge__sell-servicesell-service-source-right-queue-processorqueue-processor-source-left",
    selected: false,
    label: "Enqueue Sell Orders",
    data: { label: "Enqueue Sell Orders" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "buy-service",
    sourceHandle: "buy-service-source-right",
    target: "queue-processor",
    targetHandle: "queue-processor-source-left",
    id: "xy-edge__buy-servicebuy-service-source-right-queue-processorqueue-processor-source-left",
    selected: false,
    label: "Enqueue Buy Orders",
    data: { label: "Enqueue Buy Orders" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "queue-processor",
    sourceHandle: "queue-processor-source-top",
    target: "order-matcher",
    targetHandle: "order-matcher-source-bottom",
    id: "xy-edge__queue-processorqueue-processor-source-top-order-matcherorder-matcher-source-bottom",
    selected: false,
    label: "Sends Orders for Matching",
    data: { label: "Sends Orders for Matching" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "order-matcher",
    sourceHandle: "order-matcher-source-right",
    target: "trade-executor",
    targetHandle: "trade-executor-source-left",
    id: "xy-edge__order-matcherorder-matcher-source-right-trade-executortrade-executor-source-left",
    selected: false,
    label: "Matches Orders",
    data: { label: "Matches Orders" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "trade-executor",
    sourceHandle: "trade-executor-source-right",
    target: "stock-exchange",
    targetHandle: "stock-exchange-source-top",
    id: "xy-edge__trade-executortrade-executor-source-right-stock-exchangestock-exchange-source-top",
    selected: false,
    label: "Executes Trades",
    data: { label: "Executes Trades" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "stock-exchange",
    sourceHandle: "stock-exchange-source-left",
    target: "market-service",
    targetHandle: "market-service-source-right",
    id: "xy-edge__stock-exchangestock-exchange-source-left-market-servicemarket-service-source-right",
    selected: false,
    label: "Market Feed",
    data: { label: "Market Feed" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "trade-history",
    sourceHandle: "trade-history-source-bottom",
    target: "nosql-db",
    targetHandle: "nosql-db-source-top",
    id: "xy-edge__trade-historytrade-history-source-bottom-nosql-dbnosql-db-source-top",
    selected: false,
    label: "Stores Trade Data",
    data: { label: "Stores Trade Data" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "price-update-service",
    sourceHandle: "price-update-service-source-bottom",
    target: "debezium",
    targetHandle: "debezium-source-top",
    id: "xy-edge__price-update-serviceprice-update-service-source-bottom-debeziumdebezium-source-top",
    selected: false,
    label: "Captures Price Changes",
    data: { label: "Captures Price Changes" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "price-update-service",
    sourceHandle: "price-update-service-source-left",
    target: "notification-alert",
    targetHandle: "notification-alert-source-right",
    selected: false,
    label: "Send Alerts",
    data: { label: "Send Alerts" },
    id: "xy-edge__price-update-serviceprice-update-service-source-left-notification-alertnotification-alert-source-right",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "notification-alert",
    sourceHandle: "notification-alert-source-left",
    target: "dndnode_0",
    targetHandle: "dndnode_0-source-top",
    id: "xy-edge__notification-alertnotification-alert-source-left-dndnode_0dndnode_0-source-top",
    selected: false,
    label: "Price Change",
    data: { label: "Price Change" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "notification-alert",
    sourceHandle: "notification-alert-source-bottom",
    target: "dndnode_1",
    targetHandle: "dndnode_1-source-top",
    id: "xy-edge__notification-alertnotification-alert-source-bottom-dndnode_1dndnode_1-source-top",
    selected: false,
    label: "Portfolio updates",
    data: { label: "Portfolio updates" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "notification-alert",
    sourceHandle: "notification-alert-source-bottom",
    target: "dndnode_2",
    targetHandle: "dndnode_2-source-top",
    id: "xy-edge__notification-alertnotification-alert-source-bottom-dndnode_2dndnode_2-source-top",
    selected: false,
    label: "Trade status",
    data: { label: "Trade status" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "queue-processor",
    sourceHandle: "queue-processor-source-bottom",
    target: "price-update-service",
    targetHandle: "price-update-service-source-top",
    selected: false,
    label: "Price Change",
    data: { label: "Price Change" },
    id: "xy-edge__queue-processorqueue-processor-source-bottom-price-update-serviceprice-update-service-source-top",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "market-service",
    sourceHandle: "market-service-source-left",
    target: "dndnode_3",
    targetHandle: "dndnode_3-source-right",
    id: "xy-edge__market-servicemarket-service-source-left-dndnode_3dndnode_3-source-right",
    selected: false,
    label: "Market Data",
    data: { label: "Market Data" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "stock-exchange",
    sourceHandle: "stock-exchange-source-bottom",
    target: "trade-history",
    targetHandle: "trade-history-source-right",
    id: "xy-edge__stock-exchangestock-exchange-source-bottom-trade-historytrade-history-source-right",
    selected: false,
    label: "Get Trade Status",
    data: { label: "Get Trade Status" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "trade-history",
    sourceHandle: "trade-history-source-top",
    target: "dndnode_3",
    targetHandle: "dndnode_3-source-bottom",
    selected: false,
    id: "xy-edge__trade-historytrade-history-source-top-dndnode_3dndnode_3-source-bottom",
    label: "Portfolio updates",
    data: { label: "Portfolio updates" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "dndnode_3",
    sourceHandle: "dndnode_3-source-left",
    target: "queue-processor",
    targetHandle: "queue-processor-source-right",
    id: "xy-edge__dndnode_3dndnode_3-source-left-queue-processorqueue-processor-source-right",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "queue-processor",
    sourceHandle: "queue-processor-source-left",
    target: "portfolio-service",
    targetHandle: "portfolio-service-source-right",
    id: "xy-edge__queue-processorqueue-processor-source-left-portfolio-serviceportfolio-service-source-right",
    selected: false,
    label: "Portfolio updates from exchange",
    data: { label: "Portfolio updates from exchange" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "trade-history",
    sourceHandle: "trade-history-source-top",
    target: "stock-exchange",
    targetHandle: "stock-exchange-source-left",
    selected: false,
    label: "Send Trade Information",
    data: { label: "Send Trade Information" },
    id: "xy-edge__trade-historytrade-history-source-top-stock-exchangestock-exchange-source-left",
  },
];
