import { IBannerStyles } from "./Banner.types";

export const getBannerStyles = (matches: boolean): IBannerStyles => {
  return {
    rootContainer: { height: matches ? "40vh" : "60vh" },
    magicIcon: { fontSize: 60, color: "#fff" },
    welcome: {
      color: "#fff",
      fontWeight: 500,
      fontSize: { xs: "1.5rem", md: "1.7rem" },
    },
    headline: {
      color: "#fff",
      fontSize: { xs: "2rem", md: "2rem" },
      fontWeight: "bold",
    },
    headlineContainer: {
      width: { xs: "100%", md: "80%", lg: "60%" },
    },
    author: {
      color: "#fff",
      fontFamily: "Brush Script MT, Brush Script Std, cursive",
    },
    startNowButton: { color: "white", borderColor: "white", cursor: "pointer" },
  };
};
