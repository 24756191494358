import { Grid, Paper } from "@mui/material";
import { IHomeProps } from "./Home.types";
import { Header } from "./header/Header";
import { getHomeStyles } from "./Home.styles";
import { Banner } from "./banner/Banner";
import { Articles } from "./article/Articles";
import Footer from "./Footer";
// import { EmpowerMessage } from "./EmpowerMessage";

export const Home: React.FC<IHomeProps> = () => {
  const styles = getHomeStyles();

  return (
    <Paper sx={styles.rootContainer} elevation={0}>
      <Header />
      <Grid
        container
        sx={{ mt: { xs: 10, md: 20 }, backgroundColor: "transparent" }}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sx={{
            height: { xs: "100vh", md: "70vh" },
            width: { xs: "100%", md: "80%" },
          }}
          textAlign={"center"}
        >
          <Banner />
        </Grid>
        {/* <Grid item xs={8}>
          <EmpowerMessage />
        </Grid> */}
        <Grid item xs={12}>
          <Articles />
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Paper>
  );
};
