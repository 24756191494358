import {
  ReactFlow,
  Background,
  Panel,
  BackgroundVariant,
  Controls,
  ConnectionMode,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { ICanvasDesignerProps } from "./CanvasDesigner.types";
import { ConnectedHeaderTitle } from "./panel/header-title/ConnectedHeaderTitle";
import { ConnectedNodeDetailView } from "./panel/node-detail-view/ConnectedNodeDetailView";

export const CanvasDesigner: React.FC<ICanvasDesignerProps> = ({
  title,
  author,
  activeNode,
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  nodeTypes,
  edgeTypes,
  onConnect,
  onNodeDrag,
  fitViewOptions,
  defaultEdgeOptions,
  onNodeDoubleClick,
  onNodeDrop,
  onNodeDragOver,
  onPanelClose,
  onNodeDetailsEdit,
  onCanvasSave,
  onEdgeDoubleClick,
  onReconnect,
}) => {
  const proOptions = { hideAttribution: true };
  const isEditable = false;

  return (
    <div style={{ height: "100vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        onNodeDrag={onNodeDrag}
        onDrop={onNodeDrop}
        onDragOver={onNodeDragOver}
        fitView
        fitViewOptions={fitViewOptions}
        defaultEdgeOptions={defaultEdgeOptions}
        proOptions={proOptions}
        onNodeDoubleClick={onNodeDoubleClick}
        selectionKeyCode={null}
        onEdgeDoubleClick={onEdgeDoubleClick}
        onReconnect={onReconnect}
        connectionMode={ConnectionMode.Loose}
      >
        <Background
          bgColor="#5B3FB5"
          variant={BackgroundVariant.Dots}
          patternClassName="red"
        />
        <Controls
          showZoom={false}
          showInteractive={false}
          position="bottom-right"
        />
        <Panel position="top-right">
          {activeNode && !isEditable ? (
            <ConnectedNodeDetailView
              activeNode={activeNode}
              onPanelClose={onPanelClose}
            />
          ) : null}
        </Panel>
        <Panel position="top-center">
          <ConnectedHeaderTitle title={title ?? ""} author={author ?? ""} />
        </Panel>
      </ReactFlow>
    </div>
  );
};
