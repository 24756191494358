import React, { useEffect } from "react";
import { IToolBarProps } from "./ToolBar.types";
import { IconButton, Popover, Toolbar, colors } from "@mui/material";
import { ConnectedColorPallet } from "./color-pallet-section/ConnectedColorPallet";
import { IColorPalletType } from "./color-pallet-section/ColorPallet.types";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import { useCanvasInformation } from "../../../hooks/useCanvasInformation";
import { useOnSelectionChange } from "@xyflow/react";
import { ConnectedEdgeToolBar } from "./edge-tool-bar/ConnectedEdgeToolBar";
import Divider from "@mui/material/Divider";
import { ConnectedColorOptionsSection } from "./color-options-section/ConnectedColorOptionsSection";

export const ToolBar: React.FC<IToolBarProps> = () => {
  const [colorPalletElement, setColorPalletElement] =
    React.useState<HTMLButtonElement | null>(null);
  const {
    activeNode,
    setActiveNode,
    setActiveEdge,
    setCanvasBackgroundColor,
    setBackgroundColor,
    setTextBackgroundColor,
    setTextColor,
  } = useCanvasInformation();

  const handleOpenColorPalletPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setColorPalletElement(event.currentTarget);
  };

  const handleCloseColorPalletPopover = () => {
    setColorPalletElement(null);
  };

  const open = Boolean(colorPalletElement);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    console.log("Active Node: ", activeNode);
  }, [activeNode]);

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      if (nodes.length === 1 && nodes?.[0]) {
        setActiveNode(nodes[0]);
      } else {
        setActiveNode(undefined);
      }

      if (edges.length === 1 && edges?.[0]) {
        setActiveEdge(edges[0]);
      } else {
        setActiveEdge(undefined);
      }
    },
  });

  const onColorChange = (color: string, palletType: IColorPalletType) => {
    if (palletType === IColorPalletType.CanvasBackground) {
      setCanvasBackgroundColor(color);
    } else if (palletType === IColorPalletType.Text) {
      setTextColor(color);
    } else if (palletType === IColorPalletType.TextBackground) {
      setTextBackgroundColor(color);
    } else if (palletType === IColorPalletType.NodeBackground) {
      setBackgroundColor(color);
    }
  };

  return (
    <Toolbar sx={{ backgroundColor: "white" }} variant="dense">
      <IconButton
        aria-label="delete"
        size="large"
        onClick={handleOpenColorPalletPopover}
      >
        <FormatColorFillIcon
          fontSize="inherit"
          sx={{ color: colors.blueGrey[700] }}
        />
      </IconButton>
      <Divider orientation="vertical" variant="middle" flexItem />
      <ConnectedColorOptionsSection onColorChange={onColorChange} color="" />
      <Divider orientation="vertical" variant="middle" flexItem />
      {/* <ConnectedEdgeToolBar /> */}
      <Popover
        id={id}
        open={open}
        anchorEl={colorPalletElement}
        onClose={handleCloseColorPalletPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              width: "600px",
              pt: 1,
              pl: 1,
              pb: 1,
            },
          },
        }}
      >
        <ConnectedColorPallet
          palletType={IColorPalletType.CanvasBackground}
          onColorChange={onColorChange}
        />
      </Popover>
    </Toolbar>
  );
};
