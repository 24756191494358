import serviceBatchAISVG from "../../../../assets/icons/azure/ai-machine-learning/00028-icon-service-Batch-AI.svg";
import machineLearningStudioSVG from "../../../../assets/icons/azure/ai-machine-learning/00030-icon-service-Machine-Learning-Studio-(Classic)-Web-Services.svg";
import genomicsSVG from "../../../../assets/icons/azure/ai-machine-learning/00031-icon-service-Genomics.svg";
import computerVisionSVG from "../../../../assets/icons/azure/ai-machine-learning/00792-icon-service-Computer-Vision.svg";
import faceAPISVG from "../../../../assets/icons/azure/ai-machine-learning/00794-icon-service-Face-APIs.svg";
import contentModeratorsSVG from "../../../../assets/icons/azure/ai-machine-learning/00795-icon-service-Content-Moderators.svg";
import speechServicesSVG from "../../../../assets/icons/azure/ai-machine-learning/00797-icon-service-Speech-Services.svg";
import qnaMakersSVG from "../../../../assets/icons/azure/ai-machine-learning/00799-icon-service-QnA-Makers.svg";
import translatorTextSVG from "../../../../assets/icons/azure/ai-machine-learning/00800-icon-service-Translator-Text.svg";
import languageUnderstandingSVG from "../../../../assets/icons/azure/ai-machine-learning/00801-icon-service-Language-Understanding.svg";
import immersiveReadersSVG from "../../../../assets/icons/azure/ai-machine-learning/00812-icon-service-Immersive-Readers.svg";
import anomalyDetectorSVG from "../../../../assets/icons/azure/ai-machine-learning/00814-icon-service-Anomaly-Detector.svg";
import formRecognizersSVG from "../../../../assets/icons/azure/ai-machine-learning/00819-icon-service-Form-Recognizers.svg";
import azureExperimentationStudioSVG from "../../../../assets/icons/azure/ai-machine-learning/01239-icon-service-Azure-Experimentation-Studio.svg";
import azureObjectUnderstandingSVG from "../../../../assets/icons/azure/ai-machine-learning/01688-icon-service-Azure-Object-Understanding.svg";
import metricsAdvisorSVG from "../../../../assets/icons/azure/ai-machine-learning/02409-icon-service-Metrics-Advisor.svg";
import azureAppliedAIServicesSVG from "../../../../assets/icons/azure/ai-machine-learning/02749-icon-service-Azure-Applied-AI-Services.svg";
import languageSVG from "../../../../assets/icons/azure/ai-machine-learning/02876-icon-service-Language.svg";
import cognitiveServicesDecisionsSVG from "../../../../assets/icons/azure/ai-machine-learning/03173-icon-service-Cognitive-Services-Decisions.svg";
import serverlessSearchSVG from "../../../../assets/icons/azure/ai-machine-learning/03321-icon-service-Serverless-Search.svg";
import bonsaiSVG from "../../../../assets/icons/azure/ai-machine-learning/03337-icon-service-Bonsai.svg";
import contentSafetySVG from "../../../../assets/icons/azure/ai-machine-learning/03390-icon-service-Content-Safety.svg";
import azureOpenAISVG from "../../../../assets/icons/azure/ai-machine-learning/03438-icon-service-Azure-OpenAI.svg";
import aiStudioSVG from "../../../../assets/icons/azure/ai-machine-learning/03513-icon-service-AI-Studio.svg";
import cognitiveSearchSVG from "../../../../assets/icons/azure/ai-machine-learning/10044-icon-service-Cognitive-Search.svg";
import cognitiveServicesSVG from "../../../../assets/icons/azure/ai-machine-learning/10162-icon-service-Cognitive-Services.svg";
import botServicesSVG from "../../../../assets/icons/azure/ai-machine-learning/10165-icon-service-Bot-Services.svg";
import machineLearningSVG from "../../../../assets/icons/azure/ai-machine-learning/10166-icon-service-Machine-Learning.svg";

import logAnalyticsWorkspacesSVG from "../../../../assets/icons/azure/analytics/00009-icon-service-Log-Analytics-Workspaces.svg";
import eventHubsSVG from "../../../../assets/icons/azure/analytics/00039-icon-service-Event-Hubs.svg";
import streamAnalyticsJobsSVG from "../../../../assets/icons/azure/analytics/00042-icon-service-Stream-Analytics-Jobs.svg";
import endpointAnalyticsSVG from "../../../../assets/icons/azure/analytics/00562-icon-service-Endpoint-Analytics.svg";
import azureSynapseAnalyticsSVG from "../../../../assets/icons/azure/analytics/00606-icon-service-Azure-Synapse-Analytics.svg";
import azureWorkbooksSVG from "../../../../assets/icons/azure/analytics/02189-icon-service-Azure-Workbooks.svg";
import privateLinkServicesSVG from "../../../../assets/icons/azure/analytics/02209-icon-service-Private-Link-Services.svg";
import powerBIEmbeddedSVG from "../../../../assets/icons/azure/analytics/03332-icon-service-Power-BI-Embedded.svg";
import powerPlatformSVG from "../../../../assets/icons/azure/analytics/03335-icon-service-Power-Platform.svg";
import dataFactoriesSVG from "../../../../assets/icons/azure/analytics/10126-icon-service-Data-Factories.svg";
import hdInsightClustersSVG from "../../../../assets/icons/azure/analytics/10142-icon-service-HD-Insight-Clusters.svg";
import dataLakeAnalyticsSVG from "../../../../assets/icons/azure/analytics/10143-icon-service-Data-Lake-Analytics.svg";
import azureDataExplorerClustersSVG from "../../../../assets/icons/azure/analytics/10145-icon-service-Azure-Data-Explorer-Clusters.svg";
import analysisServicesSVG from "../../../../assets/icons/azure/analytics/10148-icon-service-Analysis-Services.svg";
import eventHubClustersSVG from "../../../../assets/icons/azure/analytics/10149-icon-service-Event-Hub-Clusters.svg";
import dataLakeStoreGen1SVG from "../../../../assets/icons/azure/analytics/10150-icon-service-Data-Lake-Store-Gen1.svg";
import azureDatabricksSVG from "../../../../assets/icons/azure/analytics/10787-icon-service-Azure-Databricks.svg";

import appServicePlansSVG from "../../../../assets/icons/azure/app-services/00046-icon-service-App-Service-Plans.svg";
import appServiceCertificatesSVG from "../../../../assets/icons/azure/app-services/00049-icon-service-App-Service-Certificates.svg";
import appServiceDomainsSVG from "../../../../assets/icons/azure/app-services/00050-icon-service-App-Service-Domains.svg";
import cdnProfilesSVG from "../../../../assets/icons/azure/app-services/00056-icon-service-CDN-Profiles.svg";
import appServicesSVG from "../../../../assets/icons/azure/app-services/10035-icon-service-App-Services.svg";
import notificationHubsSVG from "../../../../assets/icons/azure/app-services/10045-icon-service-Notification-Hubs.svg";
import appServiceEnvironmentsSVG from "../../../../assets/icons/azure/app-services/10047-icon-service-App-Service-Environments.svg";

import azureDatabaseMigrationServicesSVG from "../../../../assets/icons/azure/databases/10133-icon-service-Azure-Database-Migration-Services.svg";
import ssisLiftAndShiftIRSVG from "../../../../assets/icons/azure/databases/02392-icon-service-SSIS-Lift-And-Shift-IR.svg";
import azureDatabaseMySQLServerSVG from "../../../../assets/icons/azure/databases/10122-icon-service-Azure-Database-MySQL-Server.svg";
import azurePurviewAccountsSVG from "../../../../assets/icons/azure/databases/02517-icon-service-Azure-Purview-Accounts.svg";
import oracleDatabaseSVG from "../../../../assets/icons/azure/databases/03490-icon-service-Oracle-Database.svg";
import azureSQLServerStretchDatabasesSVG from "../../../../assets/icons/azure/databases/10137-icon-service-Azure-SQL-Server-Stretch-Databases.svg";
import azureDatabasePostgreSQLServerGroupSVG from "../../../../assets/icons/azure/databases/02827-icon-service-Azure-Database-PostgreSQL-Server-Group.svg";
import azureSQLEdgeSVG from "../../../../assets/icons/azure/databases/02750-icon-service-Azure-SQL-Edge.svg";
import instancePoolsSVG from "../../../../assets/icons/azure/databases/10139-icon-service-Instance-Pools.svg";
import elasticJobAgentsSVG from "../../../../assets/icons/azure/databases/10128-icon-service-Elastic-Job-Agents.svg";
import azureCosmosDBSVG from "../../../../assets/icons/azure/databases/10121-icon-service-Azure-Cosmos-DB.svg";
import sqlElasticPoolsSVG from "../../../../assets/icons/azure/databases/10134-icon-service-SQL-Elastic-Pools.svg";
import sqlManagedInstanceSVG from "../../../../assets/icons/azure/databases/10136-icon-service-SQL-Managed-Instance.svg";
import azureDatabaseMariaDBServerSVG from "../../../../assets/icons/azure/databases/10123-icon-service-Azure-Database-MariaDB-Server.svg";
import azureSQLSVG from "../../../../assets/icons/azure/databases/02390-icon-service-Azure-SQL.svg";
import sqlServerRegistriesSVG from "../../../../assets/icons/azure/databases/10351-icon-service-SQL-Server-Registries.svg";
import azureSQLVMSVG from "../../../../assets/icons/azure/databases/10124-icon-service-Azure-SQL-VM.svg";
import virtualClustersSVG from "../../../../assets/icons/azure/databases/10127-icon-service-Virtual-Clusters.svg";
import sqlServerSVG from "../../../../assets/icons/azure/databases/10132-icon-service-SQL-Server.svg";
import managedDatabaseSVG from "../../../../assets/icons/azure/databases/10135-icon-service-Managed-Database.svg";
import cacheRedisSVG from "../../../../assets/icons/azure/databases/10137-icon-service-Cache-Redis.svg";
import sqlDataWarehousesSVG from "../../../../assets/icons/azure/databases/00036-icon-service-SQL-Data-Warehouses.svg";
import azureDatabasePostgreSQLServerSVG from "../../../../assets/icons/azure/databases/10131-icon-service-Azure-Database-PostgreSQL-Server.svg";
import sqlDatabaseSVG from "../../../../assets/icons/azure/databases/10130-icon-service-SQL-Database.svg";

import azureApplicationInsightsSVG from "../../../../assets/icons/azure/devops/00012-icon-service-Application-Insights.svg";

import { INodeTypes } from "../../node.types";

export const cloudNodes = [
  {
    title: "Batch AI",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_BATCH_AI,
    logo: serviceBatchAISVG,
  },
  {
    title: "ML Studio",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_MACHINE_LEARNING_STUDIO,
    logo: machineLearningStudioSVG,
  },
  {
    title: "Genomics",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_GENOMICS,
    logo: genomicsSVG,
  },
  {
    title: "Computer Vision",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_COMPUTER_VISION,
    logo: computerVisionSVG,
  },
  {
    title: "Face APIs",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_FACE_APIS,
    logo: faceAPISVG,
  },
  {
    title: "Content Moderators",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_CONTENT_MODERATORS,
    logo: contentModeratorsSVG,
  },
  {
    title: "Speech Services",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_SPEECH_SERVICES,
    logo: speechServicesSVG,
  },
  {
    title: "QnA Makers",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_QNA_MAKERS,
    logo: qnaMakersSVG,
  },
  {
    title: "Translator Text",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_TRANSLATOR_TEXT,
    logo: translatorTextSVG,
  },
  {
    title: "Language Understanding",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_LANGUAGE_UNDERSTANDING,
    logo: languageUnderstandingSVG,
  },
  {
    title: "Immersive Readers",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_IMMERSIVE_READERS,
    logo: immersiveReadersSVG,
  },
  {
    title: "Anomaly Detector",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_ANOMALY_DETECTOR,
    logo: anomalyDetectorSVG,
  },
  {
    title: "Form Recognizers",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_FORM_RECOGNIZERS,
    logo: formRecognizersSVG,
  },
  {
    title: "Experimentation Studio",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_EXPERIMENTATION_STUDIO,
    logo: azureExperimentationStudioSVG,
  },
  {
    title: "Object Understanding",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_OBJECT_UNDERSTANDING,
    logo: azureObjectUnderstandingSVG,
  },
  {
    title: "Metrics Advisor",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_METRICS_ADVISOR,
    logo: metricsAdvisorSVG,
  },
  {
    title: "Azure Applied AI Services",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_APPLIED_AI_SERVICES,
    logo: azureAppliedAIServicesSVG,
  },
  {
    title: "Language",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_LANGUAGE,
    logo: languageSVG,
  },
  {
    title: "Cognitive Services Decisions",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_COGNITIVE_SERVICES_DECISIONS,
    logo: cognitiveServicesDecisionsSVG,
  },
  {
    title: "Serverless Search",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_SERVERLESS_SEARCH,
    logo: serverlessSearchSVG,
  },
  {
    title: "Bonsai",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_BONSAI,
    logo: bonsaiSVG,
  },
  {
    title: "Content Safety",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_CONTENT_SAFETY,
    logo: contentSafetySVG,
  },
  {
    title: "Azure OpenAI",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_OPENAI,
    logo: azureOpenAISVG,
  },
  {
    title: "AI Studio",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_AI_STUDIO,
    logo: aiStudioSVG,
  },
  {
    title: "Cognitive Search",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_COGNITIVE_SEARCH,
    logo: cognitiveSearchSVG,
  },
  {
    title: "Cognitive Services",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_COGNITIVE_SERVICES,
    logo: cognitiveServicesSVG,
  },
  {
    title: "Bot Services",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_BOT_SERVICES,
    logo: botServicesSVG,
  },
  {
    title: "Machine Learning",
    category: "azure",
    subCategory: "AI + Machine Learning",
    type: INodeTypes.AZURE_MACHINE_LEARNING,
    logo: machineLearningSVG,
  },
  {
    title: "Log Analytics Workspaces",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_LOG_ANALYTICS_WORKSPACES,
    logo: logAnalyticsWorkspacesSVG,
  },
  {
    title: "Event Hubs",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_EVENT_HUBS,
    logo: eventHubsSVG,
  },
  {
    title: "Stream Analytics Jobs",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_STREAM_ANALYTICS_JOBS,
    logo: streamAnalyticsJobsSVG,
  },
  {
    title: "Endpoint Analytics",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_ENDPOINT_ANALYTICS,
    logo: endpointAnalyticsSVG,
  },
  {
    title: "Azure Synapse Analytics",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_SYNAPSE_ANALYTICS,
    logo: azureSynapseAnalyticsSVG,
  },
  {
    title: "Azure Workbooks",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_WORKBOOKS,
    logo: azureWorkbooksSVG,
  },
  {
    title: "Private Link Services",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_PRIVATE_LINK_SERVICES,
    logo: privateLinkServicesSVG,
  },
  {
    title: "Power BI Embedded",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_POWER_BI_EMBEDDED,
    logo: powerBIEmbeddedSVG,
  },
  {
    title: "Power Platform",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_POWER_PLATFORM,
    logo: powerPlatformSVG,
  },
  {
    title: "Data Factories",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_DATA_FACTORIES,
    logo: dataFactoriesSVG,
  },
  {
    title: "HD Insight Clusters",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_HD_INSIGHT_CLUSTERS,
    logo: hdInsightClustersSVG,
  },
  {
    title: "Data Lake Analytics",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_DATA_LAKE_ANALYTICS,
    logo: dataLakeAnalyticsSVG,
  },
  {
    title: "Azure Data Explorer Clusters",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_DATA_EXPLORER_CLUSTERS,
    logo: azureDataExplorerClustersSVG,
  },
  {
    title: "Analysis Services",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_ANALYSIS_SERVICES,
    logo: analysisServicesSVG,
  },
  {
    title: "Event Hub Clusters",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_EVENT_HUB_CLUSTERS,
    logo: eventHubClustersSVG,
  },
  {
    title: "Data Lake Store Gen1",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_DATA_LAKE_STORE_GEN1,
    logo: dataLakeStoreGen1SVG,
  },
  {
    title: "Azure Databricks",
    category: "azure",
    subCategory: "Analytics",
    type: INodeTypes.AZURE_DATABRICKS,
    logo: azureDatabricksSVG,
  },
  {
    title: "App Service Plans",
    category: "azure",
    subCategory: "App Services",
    type: INodeTypes.AZURE_APP_SERVICE_PLANS,
    logo: appServicePlansSVG,
  },
  {
    title: "App Service Certificates",
    category: "azure",
    subCategory: "App Services",
    type: INodeTypes.AZURE_APP_SERVICE_CERTIFICATES,
    logo: appServiceCertificatesSVG,
  },
  {
    title: "App Service Domains",
    category: "azure",
    subCategory: "App Services",
    type: INodeTypes.AZURE_APP_SERVICE_DOMAINS,
    logo: appServiceDomainsSVG,
  },
  {
    title: "CDN Profiles",
    category: "azure",
    subCategory: "App Services",
    type: INodeTypes.AZURE_CDN_PROFILES,
    logo: cdnProfilesSVG,
  },
  {
    title: "App Services",
    category: "azure",
    subCategory: "App Services",
    type: INodeTypes.AZURE_APP_SERVICES,
    logo: appServicesSVG,
  },
  {
    title: "Cognitive Search",
    category: "azure",
    subCategory: "App Services",
    type: INodeTypes.AZURE_COGNITIVE_SEARCH,
    logo: cognitiveSearchSVG,
  },
  {
    title: "Notification Hubs",
    category: "azure",
    subCategory: "App Services",
    type: INodeTypes.AZURE_NOTIFICATION_HUBS,
    logo: notificationHubsSVG,
  },
  {
    title: "App Service Environments",
    category: "azure",
    subCategory: "App Services",
    type: INodeTypes.AZURE_APP_SERVICE_ENVIRONMENTS,
    logo: appServiceEnvironmentsSVG,
  },
  {
    title: "DB Migration Services",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_DATABASE_MIGRATION_SERVICES,
    logo: azureDatabaseMigrationServicesSVG,
  },
  {
    title: "MySQL Server",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_DATABASE_MYSQL_SERVER,
    logo: azureDatabaseMySQLServerSVG,
  },
  {
    title: "Azure Purview Accounts",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_PURVIEW_ACCOUNTS,
    logo: azurePurviewAccountsSVG,
  },
  {
    title: "Oracle Database",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_ORACLE_DATABASE,
    logo: oracleDatabaseSVG,
  },
  {
    title: "SQL Server",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_SQL_SERVER_STRETCH_DATABASES,
    logo: azureSQLServerStretchDatabasesSVG,
  },
  {
    title: "PostgreSQL",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_DATABASE_POSTGRESQL_SERVER_GROUP,
    logo: azureDatabasePostgreSQLServerGroupSVG,
  },
  {
    title: "Azure SQL Edge",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_SQL_EDGE,
    logo: azureSQLEdgeSVG,
  },
  {
    title: "Instance Pools",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_INSTANCE_POOLS,
    logo: instancePoolsSVG,
  },
  {
    title: "Elastic Job Agents",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_ELASTIC_JOB_AGENTS,
    logo: elasticJobAgentsSVG,
  },
  {
    title: "Azure Cosmos DB",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_COSMOS_DB,
    logo: azureCosmosDBSVG,
  },
  {
    title: "SQL Elastic Pools",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_SQL_ELASTIC_POOLS,
    logo: sqlElasticPoolsSVG,
  },
  {
    title: "SQL Managed Instance",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_SQL_MANAGED_INSTANCE,
    logo: sqlManagedInstanceSVG,
  },
  {
    title: "MariaDB",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_DATABASE_MARIADB_SERVER,
    logo: azureDatabaseMariaDBServerSVG,
  },
  {
    title: "Azure SQL",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_SQL,
    logo: azureSQLSVG,
  },
  {
    title: "SQL Server Registries",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_SQL_SERVER_REGISTRIES,
    logo: sqlServerRegistriesSVG,
  },
  {
    title: "Azure SQL VM",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_SQL_VM,
    logo: azureSQLVMSVG,
  },
  {
    title: "Virtual Clusters",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_VIRTUAL_CLUSTERS,
    logo: virtualClustersSVG,
  },
  {
    title: "SQL Server",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_SQL_SERVER,
    logo: sqlServerSVG,
  },
  {
    title: "Managed Database",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_MANAGED_DATABASE,
    logo: managedDatabaseSVG,
  },
  {
    title: "Cache Redis",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_CACHE_REDIS,
    logo: cacheRedisSVG,
  },
  {
    title: "SQL Data Warehouses",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_SQL_DATA_WAREHOUSES,
    logo: sqlDataWarehousesSVG,
  },
  {
    title: "PostgreSQL Server",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_DATABASE_POSTGRESQL_SERVER,
    logo: azureDatabasePostgreSQLServerSVG,
  },
  {
    title: "SQL Database",
    category: "azure",
    subCategory: "Databases",
    type: INodeTypes.AZURE_SQL_DATABASE,
    logo: sqlDatabaseSVG,
  },
  {
    title: "Application Insights",
    category: "azure",
    subCategory: "DevOps",
    type: INodeTypes.AZURE_APPLICATION_INSIGHTS,
    logo: azureApplicationInsightsSVG,
  },
];
