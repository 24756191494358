import React from "react";
import { IConnectedNodeConfigurationProps } from "./NodeConfiguration.types";
import { NodeConfiguration } from "./NodeConfiguration";

export const ConnectedNodeConfiguration: React.FC<
  IConnectedNodeConfigurationProps
> = ({ activeNode, onPanelClose, onEdit }) => {
  return (
    <NodeConfiguration
      activeNode={activeNode}
      onPanelClose={onPanelClose}
      onEdit={onEdit}
    />
  );
};
