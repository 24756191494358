import { CSSProperties } from "react";
import { SxProps } from "@mui/material";
import { INodeCSSProperties, INodeProps, INodeTypes } from "../node.types";
import { Position } from "@xyflow/react";

export interface ICustomNodeStyles {
  rootContainer: SxProps;
  image: CSSProperties;
  label: SxProps;
  handle: CSSProperties;
}

export interface ICustomNodeProps extends INodeProps {
  isActive?: boolean;
}

export enum IHandleType {
  Target = "target",
  Source = "source",
}

export interface IHandle {
  id?: string;
  position: Position;
  index: number;
  type: IHandleType;

  /**
   * Distance from the left of the node
   */
  margin?: number;
}

export interface ICustomNode {
  category?: string;
  subCategory?: string;
  /**
   * Node type
   */
  type: INodeTypes;

  /**
   * Background color
   * @deprecated
   */
  backgroundColor: string;

  /**
   * CSS Properties for the node
   */
  styleProperties?: INodeCSSProperties;

  /**
   * Image Url for the node representation
   */
  imgSrc: string;

  /**
   * Image Size
   */
  imgSize?: number;

  /**
   * Icon to render
   */
  icon?: string;

  /**
   * Number of sources
   */
  numberOfSources: number;

  /**
   * Number of targets
   */
  numberOfTargets: number;

  /**
   * Node name
   */
  nodeName: string;

  /**
   * Height of the node
   */
  height?: number;

  /**
   * Width of the node
   */
  width?: number;

  /**
   * Handles for the node
   */
  handles?: Array<IHandle>;

  /**
   * Is the node active
   */
  isActive?: boolean;
}
