import { colors } from "@mui/material";
import { INodeConfigurationStyles } from "./NodeConfiguration.types";

export const getNodeConfigurationStyles = (): INodeConfigurationStyles => {
  return {
    rootContainer: {
      bgcolor: colors.grey[50],
      height: "calc(100vh - 40px)",
      width: 400,
      padding: 2,
      borderRadius: 2,
      overflow: "auto",
    },
    nodeImg: {
      height: 30,
      width: 30,
      backgroundColor: colors.grey[300],
      padding: 2,
      margin: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      borderRadius: "12px 0px 0px 12px",
    },
    nodeDescription: {
      color: "text.secondary",
      paddingBottom: 1,
    },
    nodeDescriptionContainer: {
      padding: 2,
      marginBottom: 1,
    },
    chipStyles: { height: 36, textTransform: "uppercase" },
  };
};
