import { Position } from "@xyflow/react";
import { IHandleType } from "../CustomNode.types";

export const getDefaultHandles = (width: number, height: number) => {
  const gap = 0;
  return [
    // Top handles
    {
      type: IHandleType.Source,
      position: Position.Top,
      index: width / 2 + gap,
    },
    // {
    //   type: IHandleType.Target,
    //   position: Position.Top,
    //   index: width / 2 - gap,
    // },
    // Left handles
    {
      type: IHandleType.Source,
      position: Position.Left,
      index: height / 2 - gap,
    },
    // {
    //   type: IHandleType.Target,
    //   position: Position.Left,
    //   index: height / 2 + gap,
    // },
    // Right handles
    {
      type: IHandleType.Source,
      position: Position.Right,
      index: height / 2 - gap,
    },
    // {
    //   type: IHandleType.Target,
    //   position: Position.Right,
    //   index: height / 2 + gap,
    // },
    // Bottom handles
    {
      type: IHandleType.Source,
      position: Position.Bottom,
      index: width / 2 - gap,
    },
    // {
    //   type: IHandleType.Target,
    //   position: Position.Bottom,
    //   index: width / 2 + gap,
    // },
  ];
};
