import { Chip, Grid, IconButton, Paper, Typography } from "@mui/material";
import { INodeDetailViewProps } from "./NodeDetailView.types";
import { getNodeDetailViewStyles } from "./NodeDetailView.styles";
import CloseIcon from "@mui/icons-material/Close";
import { useGetNodeProperties } from "../../../nodes/custom-node/hooks/useGetNodeProperties";
import { DiscussionEmbed } from "disqus-react";
import { useId } from "react";

export const NodeDetailView: React.FC<INodeDetailViewProps> = ({
  activeNode,
  onPanelClose,
}) => {
  const styles = getNodeDetailViewStyles();
  const { label, description } = activeNode.data;
  const type = activeNode.type;

  const { imgSrc, backgroundColor, nodeName } = useGetNodeProperties(
    type ?? ""
  );
  return (
    <Paper sx={styles.rootContainer} elevation={7}>
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <Grid container rowSpacing={1}>
            <Grid item sx={{ marginTop: 1 }}>
              <Chip
                sx={{ height: 35, textTransform: "uppercase" }}
                label={`${nodeName}`}
                avatar={
                  <img
                    src={imgSrc}
                    alt={`${label} Details`}
                    style={{
                      ...styles.nodeImg,
                      background: backgroundColor,
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton aria-label="delete" size="small" onClick={onPanelClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sx={styles.nodeDescriptionContainer}>
          <Typography variant="h6">{label + ""}</Typography>
          <Typography variant="body2" sx={styles.nodeDescription}>
            {`${description ?? "No Description Available"}`}
          </Typography>
        </Grid>
        {/* <Grid item sx={styles.disqusContainer}>
          <DiscussionEmbed
            shortname="EXAMPLE"
            config={{
              url: "http://localhost:3000",
              identifier: useId(),
              title: "this.props.article.title",
            }}
          />
        </Grid> */}
      </Grid>
    </Paper>
  );
};
