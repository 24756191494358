import { type Node, type Edge } from "@xyflow/react";

export const cachingTechinquesNodes: Node[] = [
  {
    id: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3",
    type: "application",
    position: { x: 755.5063187301998, y: 149.90992734066475 },
    data: { label: "Application" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "b174d3c7-ac23-4377-80a7-9c97efc480f7",
    type: "redis",
    position: { x: 569.1528211488383, y: 350.66450246673963 },
    data: { label: "Redis" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "b49f154d-97a7-4444-b93e-f8872847add8",
    type: "database",
    position: { x: 986.847305780234, y: 353.55862536558067 },
    data: { label: "Database" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "11b51fd0-46c5-4ecf-a513-1701ac0878a7",
    type: "text",
    position: { x: 709.0126184102875, y: 91.8980436222867 },
    data: { label: "Read Strategy - Cache Aside" },
    measured: { width: 167, height: 14 },
    selected: false,
    dragging: false,
  },
  {
    id: "27234a0a-6762-4447-b48f-294a7ad0b585",
    type: "application",
    position: { x: 1552.119069357218, y: 119.74238093134475 },
    data: { label: "Application" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "72a16608-5506-430e-a58f-ecfed8782052",
    type: "database",
    position: { x: 1635.144254057654, y: 371.5194072428323 },
    data: { label: "Database" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b",
    type: "redis",
    position: { x: 1369.2931562297852, y: 359.4156927431057 },
    data: { label: "Redis" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "ca0d8ba1-f709-4c36-b92a-7b4d1849ffd9",
    type: "text",
    position: { x: 1369.2905526411726, y: 442.6487134440047 },
    data: { label: "2. Cache Miss" },
    measured: { width: 86, height: 14 },
    selected: false,
    dragging: false,
  },
  {
    id: "a6ea4678-3ab0-42a3-9826-4a602c3ecc4d",
    type: "text",
    position: { x: 1422.718998259521, y: 335.68748389779427 },
    data: { label: "5. Update Cache" },
    measured: { width: 101, height: 14 },
    selected: false,
    dragging: false,
  },
  {
    id: "b1553ec9-9482-4e89-a00f-1d05264d9aba",
    type: "text",
    position: { x: 1504.8330119183386, y: 77.5800898745878 },
    data: { label: "Read Strategy - Read Through" },
    measured: { width: 178, height: 14 },
    selected: false,
    dragging: false,
  },
  {
    id: "627c97af-3574-452c-9918-e99b366727b6",
    type: "application",
    position: { x: 493.75752746686976, y: 644.0674735522452 },
    data: { label: "Application" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "f36e2214-3e26-43fa-84a0-15e5407f6159",
    type: "redis",
    position: { x: 289.79902069852335, y: 805.4503637130897 },
    data: { label: "Redis" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "0c183204-d499-4fa0-b222-785a0ae37ca7",
    type: "database",
    position: { x: 705.4947913899659, y: 825.45468932411 },
    data: { label: "Database" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "c313081b-09da-4191-adb1-d953086954cd",
    type: "text",
    position: { x: 427.84746160844054, y: 938.4164445691027 },
    data: { label: "Write Strategy - Write Around" },
    measured: { width: 180, height: 14 },
    selected: false,
    dragging: false,
  },
  {
    id: "c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8",
    type: "application",
    position: { x: 1192.7274906313987, y: 637.3844569752611 },
    data: { label: "Application" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "2208361e-287a-493f-9b08-7730e8bb33a7",
    type: "redis",
    position: { x: 1023.5207116595618, y: 799.4306741851566 },
    data: { label: "Redis" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "1d326a75-bfa4-4aaf-a452-2409c6eeff9c",
    type: "database",
    position: { x: 1356.333712576137, y: 803.0200820510742 },
    data: { label: "Database" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "4a6d7974-115d-4e52-9dc6-73a789254e6a",
    type: "text",
    position: { x: 1159.65259298682, y: 915.0132005968619 },
    data: { label: "Write Strategy - Write Back" },
    measured: { width: 165, height: 14 },
    selected: false,
    dragging: false,
  },
  {
    id: "ffd4aafc-db2a-4754-9744-a5fcab37bab9",
    type: "application",
    position: { x: 1839.9265662757348, y: 588.7516266874936 },
    data: { label: "Application" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "dfe653ad-612b-4bb6-9b69-8c63e072db77",
    type: "redis",
    position: { x: 1724.118795308819, y: 797.7213856311242 },
    data: { label: "Redis" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "013a3ea0-5493-4a82-8132-8d12636b90d6",
    type: "database",
    position: { x: 2001.5442771262249, y: 796.0302410563693 },
    data: { label: "Database" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "1cafe83f-498e-4d2c-803d-d5df7a5e3d4b",
    type: "text",
    position: { x: 1869.869986932648, y: 908.6998693264818 },
    data: { label: "Write Strategy - Write Through" },
    measured: { width: 186, height: 14 },
    selected: false,
    dragging: false,
  },
];
export const cachingTechinquesEdges: Edge[] = [
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3",
    sourceHandle: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3-source-left",
    target: "b174d3c7-ac23-4377-80a7-9c97efc480f7",
    targetHandle: "b174d3c7-ac23-4377-80a7-9c97efc480f7-source-top",
    id: "xy-edge__5a9a2d9e-3386-4501-a061-dfe9a3088bc35a9a2d9e-3386-4501-a061-dfe9a3088bc3-source-left-b174d3c7-ac23-4377-80a7-9c97efc480f7b174d3c7-ac23-4377-80a7-9c97efc480f7-source-top",
    selected: false,
    label: "1. Read",
    data: { label: "1. Read" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "b174d3c7-ac23-4377-80a7-9c97efc480f7",
    sourceHandle: "b174d3c7-ac23-4377-80a7-9c97efc480f7-source-top",
    target: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3",
    targetHandle: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3-source-bottom",
    id: "xy-edge__b174d3c7-ac23-4377-80a7-9c97efc480f7b174d3c7-ac23-4377-80a7-9c97efc480f7-source-top-5a9a2d9e-3386-4501-a061-dfe9a3088bc35a9a2d9e-3386-4501-a061-dfe9a3088bc3-source-bottom",
    selected: false,
    label: "2. Cache Miss",
    data: { label: "2. Cache Miss" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3",
    sourceHandle: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3-source-right",
    target: "b49f154d-97a7-4444-b93e-f8872847add8",
    targetHandle: "b49f154d-97a7-4444-b93e-f8872847add8-source-top",
    id: "xy-edge__5a9a2d9e-3386-4501-a061-dfe9a3088bc35a9a2d9e-3386-4501-a061-dfe9a3088bc3-source-right-b49f154d-97a7-4444-b93e-f8872847add8b49f154d-97a7-4444-b93e-f8872847add8-source-top",
    selected: false,
    label: "3. Read From DB",
    data: { label: "3. Read From DB" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3",
    sourceHandle: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3-source-bottom",
    target: "b174d3c7-ac23-4377-80a7-9c97efc480f7",
    targetHandle: "b174d3c7-ac23-4377-80a7-9c97efc480f7-source-right",
    id: "xy-edge__5a9a2d9e-3386-4501-a061-dfe9a3088bc35a9a2d9e-3386-4501-a061-dfe9a3088bc3-source-bottom-b174d3c7-ac23-4377-80a7-9c97efc480f7b174d3c7-ac23-4377-80a7-9c97efc480f7-source-right",
    selected: false,
    label: "5. Update Cache",
    data: { label: "5. Update Cache" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "27234a0a-6762-4447-b48f-294a7ad0b585",
    sourceHandle: "27234a0a-6762-4447-b48f-294a7ad0b585-source-left",
    target: "3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b",
    targetHandle: "3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b-source-top",
    id: "xy-edge__27234a0a-6762-4447-b48f-294a7ad0b58527234a0a-6762-4447-b48f-294a7ad0b585-source-left-3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b-source-top",
    selected: false,
    label: "1. Read",
    data: { label: "1. Read" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b",
    sourceHandle: "3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b-source-right",
    target: "72a16608-5506-430e-a58f-ecfed8782052",
    targetHandle: "72a16608-5506-430e-a58f-ecfed8782052-source-top",
    id: "xy-edge__3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b-source-right-72a16608-5506-430e-a58f-ecfed878205272a16608-5506-430e-a58f-ecfed8782052-source-top",
    selected: false,
    label: "3. Read DB",
    data: { label: "3. Read DB" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "72a16608-5506-430e-a58f-ecfed8782052",
    sourceHandle: "72a16608-5506-430e-a58f-ecfed8782052-source-bottom",
    target: "3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b",
    targetHandle: "3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b-source-right",
    id: "xy-edge__72a16608-5506-430e-a58f-ecfed878205272a16608-5506-430e-a58f-ecfed8782052-source-bottom-3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b3f7deb1b-b66b-4791-92d4-9bcaa6f17c1b-source-right",
    selected: false,
    label: "4. Get Data",
    data: { label: "4. Get Data" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "f36e2214-3e26-43fa-84a0-15e5407f6159",
    sourceHandle: "f36e2214-3e26-43fa-84a0-15e5407f6159-source-top",
    target: "627c97af-3574-452c-9918-e99b366727b6",
    targetHandle: "627c97af-3574-452c-9918-e99b366727b6-source-left",
    id: "xy-edge__f36e2214-3e26-43fa-84a0-15e5407f6159f36e2214-3e26-43fa-84a0-15e5407f6159-source-top-627c97af-3574-452c-9918-e99b366727b6627c97af-3574-452c-9918-e99b366727b6-source-left",
    selected: false,
    label: "2. Read From Cache",
    data: { label: "2. Read From Cache" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "0c183204-d499-4fa0-b222-785a0ae37ca7",
    sourceHandle: "0c183204-d499-4fa0-b222-785a0ae37ca7-source-left",
    target: "627c97af-3574-452c-9918-e99b366727b6",
    targetHandle: "627c97af-3574-452c-9918-e99b366727b6-source-bottom",
    id: "xy-edge__0c183204-d499-4fa0-b222-785a0ae37ca70c183204-d499-4fa0-b222-785a0ae37ca7-source-left-627c97af-3574-452c-9918-e99b366727b6627c97af-3574-452c-9918-e99b366727b6-source-bottom",
    selected: false,
    label: "3.1 Read from DB",
    data: { label: "3.1 Read from DB" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "b49f154d-97a7-4444-b93e-f8872847add8",
    sourceHandle: "b49f154d-97a7-4444-b93e-f8872847add8-source-left",
    target: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3",
    targetHandle: "5a9a2d9e-3386-4501-a061-dfe9a3088bc3-source-bottom",
    selected: false,
    label: "4. Get Data From DB",
    data: { label: "4. Get Data From DB" },
    id: "xy-edge__b49f154d-97a7-4444-b93e-f8872847add8b49f154d-97a7-4444-b93e-f8872847add8-source-left-5a9a2d9e-3386-4501-a061-dfe9a3088bc35a9a2d9e-3386-4501-a061-dfe9a3088bc3-source-bottom",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "627c97af-3574-452c-9918-e99b366727b6",
    sourceHandle: "627c97af-3574-452c-9918-e99b366727b6-source-right",
    target: "0c183204-d499-4fa0-b222-785a0ae37ca7",
    targetHandle: "0c183204-d499-4fa0-b222-785a0ae37ca7-source-top",
    id: "xy-edge__627c97af-3574-452c-9918-e99b366727b6627c97af-3574-452c-9918-e99b366727b6-source-right-0c183204-d499-4fa0-b222-785a0ae37ca70c183204-d499-4fa0-b222-785a0ae37ca7-source-top",
    selected: false,
    label: "1. Write DB",
    data: { label: "1. Write DB" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "627c97af-3574-452c-9918-e99b366727b6",
    sourceHandle: "627c97af-3574-452c-9918-e99b366727b6-source-bottom",
    target: "f36e2214-3e26-43fa-84a0-15e5407f6159",
    targetHandle: "f36e2214-3e26-43fa-84a0-15e5407f6159-source-right",
    selected: false,
    label: "3.2 Update Cache",
    data: { label: "3.2 Update Cache" },
    id: "xy-edge__627c97af-3574-452c-9918-e99b366727b6627c97af-3574-452c-9918-e99b366727b6-source-bottom-f36e2214-3e26-43fa-84a0-15e5407f6159f36e2214-3e26-43fa-84a0-15e5407f6159-source-right",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "2208361e-287a-493f-9b08-7730e8bb33a7",
    sourceHandle: "2208361e-287a-493f-9b08-7730e8bb33a7-source-right",
    target: "1d326a75-bfa4-4aaf-a452-2409c6eeff9c",
    targetHandle: "1d326a75-bfa4-4aaf-a452-2409c6eeff9c-source-left",
    id: "xy-edge__2208361e-287a-493f-9b08-7730e8bb33a72208361e-287a-493f-9b08-7730e8bb33a7-source-right-1d326a75-bfa4-4aaf-a452-2409c6eeff9c1d326a75-bfa4-4aaf-a452-2409c6eeff9c-source-left",
    selected: false,
    label: "2. Write to DB once in a while",
    data: { label: "2. Write to DB once in a while" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8",
    sourceHandle: "c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8-source-bottom",
    target: "2208361e-287a-493f-9b08-7730e8bb33a7",
    targetHandle: "2208361e-287a-493f-9b08-7730e8bb33a7-source-top",
    id: "xy-edge__c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8-source-bottom-2208361e-287a-493f-9b08-7730e8bb33a72208361e-287a-493f-9b08-7730e8bb33a7-source-top",
    selected: false,
    label: "1. Write to cache",
    data: { label: "1. Write to cache" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8",
    sourceHandle: "c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8-source-left",
    target: "2208361e-287a-493f-9b08-7730e8bb33a7",
    targetHandle: "2208361e-287a-493f-9b08-7730e8bb33a7-source-top",
    id: "xy-edge__c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8-source-left-2208361e-287a-493f-9b08-7730e8bb33a72208361e-287a-493f-9b08-7730e8bb33a7-source-top",
    selected: false,
    label: "1. Write to cache",
    data: { label: "1. Write to cache" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8",
    sourceHandle: "c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8-source-top",
    target: "2208361e-287a-493f-9b08-7730e8bb33a7",
    targetHandle: "2208361e-287a-493f-9b08-7730e8bb33a7-source-top",
    id: "xy-edge__c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8c6cf09ec-7a39-4ace-9a5d-e93ecd6755e8-source-top-2208361e-287a-493f-9b08-7730e8bb33a72208361e-287a-493f-9b08-7730e8bb33a7-source-top",
    selected: false,
    label: "1. Write to cache",
    data: { label: "1. Write to cache" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "ffd4aafc-db2a-4754-9744-a5fcab37bab9",
    sourceHandle: "ffd4aafc-db2a-4754-9744-a5fcab37bab9-source-left",
    target: "dfe653ad-612b-4bb6-9b69-8c63e072db77",
    targetHandle: "dfe653ad-612b-4bb6-9b69-8c63e072db77-source-top",
    id: "xy-edge__ffd4aafc-db2a-4754-9744-a5fcab37bab9ffd4aafc-db2a-4754-9744-a5fcab37bab9-source-left-dfe653ad-612b-4bb6-9b69-8c63e072db77dfe653ad-612b-4bb6-9b69-8c63e072db77-source-top",
    selected: false,
    label: "1. Write to cache",
    data: { label: "1. Write to cache" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "dfe653ad-612b-4bb6-9b69-8c63e072db77",
    sourceHandle: "dfe653ad-612b-4bb6-9b69-8c63e072db77-source-right",
    target: "013a3ea0-5493-4a82-8132-8d12636b90d6",
    targetHandle: "013a3ea0-5493-4a82-8132-8d12636b90d6-source-left",
    id: "xy-edge__dfe653ad-612b-4bb6-9b69-8c63e072db77dfe653ad-612b-4bb6-9b69-8c63e072db77-source-right-013a3ea0-5493-4a82-8132-8d12636b90d6013a3ea0-5493-4a82-8132-8d12636b90d6-source-left",
    selected: false,
    label: "2. Write to DB immediately",
    data: { label: "2. Write to DB immediately" },
  },
];
