import { Chip, Grid, IconButton, Paper, Typography } from "@mui/material";
import { INodeConfigurationProps } from "./NodeConfiguration.types";
import { getNodeConfigurationStyles } from "./NodeConfiguration.styles";
import CloseIcon from "@mui/icons-material/Close";
import { useGetNodeProperties } from "../../../nodes/custom-node/hooks/useGetNodeProperties";
import { Editor } from "../../../../ui-library/editor/Editor";

export const NodeConfiguration: React.FC<INodeConfigurationProps> = ({
  activeNode,
  onPanelClose,
  onEdit,
}) => {
  const styles = getNodeConfigurationStyles();
  const { label, description } = activeNode.data;
  const type = activeNode.type;

  const { imgSrc, backgroundColor, nodeName } = useGetNodeProperties(
    type ?? ""
  );
  return (
    <Paper sx={styles.rootContainer} elevation={7}>
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <Grid container rowSpacing={1}>
            <Grid item sx={{ marginTop: 1 }}>
              <Chip
                sx={styles.chipStyles}
                label={`${nodeName}`}
                avatar={
                  <img
                    src={imgSrc}
                    alt={`${label} Details`}
                    style={{
                      ...styles.nodeImg,
                      background: backgroundColor,
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton aria-label="delete" size="small" onClick={onPanelClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sx={styles.nodeDescriptionContainer}>
          <Typography variant="h6">{label + ""}</Typography>
          <Typography variant="body2" sx={styles.nodeDescription}>
            {`${description ?? "No Description Available"}`}
          </Typography>
        </Grid>
        <Grid item>
          <Editor src={activeNode} onEdit={onEdit} />
        </Grid>
      </Grid>
    </Paper>
  );
};
