import React from "react";
import { LabelBackgroundColor } from "./LabelBackgroundColor";
import { IConnectedLabelBackgroundColorProps } from "./LabelBackgroundColor.types";
import { useCanvasInformation } from "../../../../../hooks/useCanvasInformation";

export const ConnectedLabelBackgroundColor: React.FC<
  IConnectedLabelBackgroundColorProps
> = () => {
  const { activeNode, setTextBackgroundColor } = useCanvasInformation();

  const isNodeSelected = activeNode !== undefined;
  const [colorPalletElement, setColorPalletElement] =
    React.useState<HTMLButtonElement | null>(null);

  const handleOpenColorPalletPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setColorPalletElement(event.currentTarget);
  };

  const handleCloseColorPalletPopover = () => {
    setColorPalletElement(null);
  };

  const handleColorChange = (color: string) => {
    setTextBackgroundColor(color);
    handleCloseColorPalletPopover();
  };

  return (
    <LabelBackgroundColor
      isNodeSelected={isNodeSelected}
      onButtonClick={handleOpenColorPalletPopover}
      colorPalletElement={colorPalletElement}
      onPopoverClose={handleCloseColorPalletPopover}
      setTextBackgroundColor={handleColorChange}
    />
  );
};
