import { colors } from "@mui/material";

export interface IColorType {
  name: string;
  variant: ColorVariant;
  hexCode: string;
}

export enum ColorVariant {
  Amber,
  Blue,
  BlueGrey,
  Brown,
  Cyan,
  DeepOrange,
  DeepPurple,
  Green,
  Grey,
  Indigo,
  LightBlue,
  LightGreen,
  Lime,
  Orange,
  Pink,
  Purple,
  Red,
  Teal,
  Yellow,
}

export interface IColorShades {
  variant: ColorVariant;
  shades: Array<IColorType>;
}

export const MUI_COLORS: Array<IColorType> = [
  {
    name: "amber-50",
    variant: ColorVariant.Amber,
    hexCode: colors.amber[50],
  },
  {
    name: "amber-100",
    variant: ColorVariant.Amber,
    hexCode: colors.amber[100],
  },
  {
    name: "amber-200",
    variant: ColorVariant.Amber,
    hexCode: colors.amber[200],
  },
  {
    name: "amber-300",
    variant: ColorVariant.Amber,
    hexCode: colors.amber[300],
  },
  {
    name: "amber-400",
    variant: ColorVariant.Amber,
    hexCode: colors.amber[400],
  },
  {
    name: "amber-500",
    variant: ColorVariant.Amber,
    hexCode: colors.amber[500],
  },
  {
    name: "amber-600",
    variant: ColorVariant.Amber,
    hexCode: colors.amber[600],
  },
  {
    name: "amber-700",
    variant: ColorVariant.Amber,
    hexCode: colors.amber[700],
  },
  {
    name: "amber-800",
    variant: ColorVariant.Amber,
    hexCode: colors.amber[800],
  },
  {
    name: "amber-900",
    variant: ColorVariant.Amber,
    hexCode: colors.amber[900],
  },
  {
    name: "amber-A100",
    variant: ColorVariant.Amber,
    hexCode: colors.amber["A100"],
  },
  {
    name: "amber-A200",
    variant: ColorVariant.Amber,
    hexCode: colors.amber["A200"],
  },
  {
    name: "amber-A400",
    variant: ColorVariant.Amber,
    hexCode: colors.amber["A400"],
  },
  {
    name: "amber-A700",
    variant: ColorVariant.Amber,
    hexCode: colors.amber["A700"],
  },
  {
    name: "blue-50",
    variant: ColorVariant.Blue,
    hexCode: colors.blue[50],
  },
  {
    name: "blue-100",
    variant: ColorVariant.Blue,
    hexCode: colors.blue[100],
  },
  {
    name: "blue-200",
    variant: ColorVariant.Blue,
    hexCode: colors.blue[200],
  },
  {
    name: "blue-300",
    variant: ColorVariant.Blue,
    hexCode: colors.blue[300],
  },
  {
    name: "blue-400",
    variant: ColorVariant.Blue,
    hexCode: colors.blue[400],
  },
  {
    name: "blue-500",
    variant: ColorVariant.Blue,
    hexCode: colors.blue[500],
  },
  {
    name: "blue-600",
    variant: ColorVariant.Blue,
    hexCode: colors.blue[600],
  },
  {
    name: "blue-700",
    variant: ColorVariant.Blue,
    hexCode: colors.blue[700],
  },
  {
    name: "blue-800",
    variant: ColorVariant.Blue,
    hexCode: colors.blue[800],
  },
  {
    name: "blue-900",
    variant: ColorVariant.Blue,
    hexCode: colors.blue[900],
  },
  {
    name: "blue-A100",
    variant: ColorVariant.Blue,
    hexCode: colors.blue["A100"],
  },
  {
    name: "blue-A200",
    variant: ColorVariant.Blue,
    hexCode: colors.blue["A200"],
  },
  {
    name: "blue-A400",
    variant: ColorVariant.Blue,
    hexCode: colors.blue["A400"],
  },
  {
    name: "blue-A700",
    variant: ColorVariant.Blue,
    hexCode: colors.blue["A700"],
  },
  {
    name: "blueGrey-50",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey[50],
  },
  {
    name: "blueGrey-100",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey[100],
  },
  {
    name: "blueGrey-200",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey[200],
  },
  {
    name: "blueGrey-300",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey[300],
  },
  {
    name: "blueGrey-400",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey[400],
  },
  {
    name: "blueGrey-500",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey[500],
  },
  {
    name: "blueGrey-600",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey[600],
  },
  {
    name: "blueGrey-700",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey[700],
  },
  {
    name: "blueGrey-800",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey[800],
  },
  {
    name: "blueGrey-900",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey[900],
  },
  {
    name: "blueGrey-A100",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey["A100"],
  },
  {
    name: "blueGrey-A200",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey["A200"],
  },
  {
    name: "blueGrey-A400",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey["A400"],
  },
  {
    name: "blueGrey-A700",
    variant: ColorVariant.BlueGrey,
    hexCode: colors.blueGrey["A700"],
  },
  {
    name: "brown-50",
    variant: ColorVariant.Brown,
    hexCode: colors.brown[50],
  },
  {
    name: "brown-100",
    variant: ColorVariant.Brown,
    hexCode: colors.brown[100],
  },
  {
    name: "brown-200",
    variant: ColorVariant.Brown,
    hexCode: colors.brown[200],
  },
  {
    name: "brown-300",
    variant: ColorVariant.Brown,
    hexCode: colors.brown[300],
  },
  {
    name: "brown-400",
    variant: ColorVariant.Brown,
    hexCode: colors.brown[400],
  },
  {
    name: "brown-500",
    variant: ColorVariant.Brown,
    hexCode: colors.brown[500],
  },
  {
    name: "brown-600",
    variant: ColorVariant.Brown,
    hexCode: colors.brown[600],
  },
  {
    name: "brown-700",
    variant: ColorVariant.Brown,
    hexCode: colors.brown[700],
  },
  {
    name: "brown-800",
    variant: ColorVariant.Brown,
    hexCode: colors.brown[800],
  },
  {
    name: "brown-900",
    variant: ColorVariant.Brown,
    hexCode: colors.brown[900],
  },
  {
    name: "brown-A100",
    variant: ColorVariant.Brown,
    hexCode: colors.brown["A100"],
  },
  {
    name: "brown-A200",
    variant: ColorVariant.Brown,
    hexCode: colors.brown["A200"],
  },
  {
    name: "brown-A400",
    variant: ColorVariant.Brown,
    hexCode: colors.brown["A400"],
  },
  {
    name: "brown-A700",
    variant: ColorVariant.Brown,
    hexCode: colors.brown["A700"],
  },
  {
    name: "cyan-50",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan[50],
  },
  {
    name: "cyan-100",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan[100],
  },
  {
    name: "cyan-200",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan[200],
  },
  {
    name: "cyan-300",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan[300],
  },
  {
    name: "cyan-400",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan[400],
  },
  {
    name: "cyan-500",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan[500],
  },
  {
    name: "cyan-600",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan[600],
  },
  {
    name: "cyan-700",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan[700],
  },
  {
    name: "cyan-800",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan[800],
  },
  {
    name: "cyan-900",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan[900],
  },
  {
    name: "cyan-A100",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan["A100"],
  },
  {
    name: "cyan-A200",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan["A200"],
  },
  {
    name: "cyan-A400",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan["A400"],
  },
  {
    name: "cyan-A700",
    variant: ColorVariant.Cyan,
    hexCode: colors.cyan["A700"],
  },
  {
    name: "deepOrange-50",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange[50],
  },
  {
    name: "deepOrange-100",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange[100],
  },
  {
    name: "deepOrange-200",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange[200],
  },
  {
    name: "deepOrange-300",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange[300],
  },
  {
    name: "deepOrange-400",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange[400],
  },
  {
    name: "deepOrange-500",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange[500],
  },
  {
    name: "deepOrange-600",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange[600],
  },
  {
    name: "deepOrange-700",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange[700],
  },
  {
    name: "deepOrange-800",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange[800],
  },
  {
    name: "deepOrange-900",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange[900],
  },
  {
    name: "deepOrange-A100",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange["A100"],
  },
  {
    name: "deepOrange-A200",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange["A200"],
  },
  {
    name: "deepOrange-A400",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange["A400"],
  },
  {
    name: "deepOrange-A700",
    variant: ColorVariant.DeepOrange,
    hexCode: colors.deepOrange["A700"],
  },
  {
    name: "deepPurple-50",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple[50],
  },
  {
    name: "deepPurple-100",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple[100],
  },
  {
    name: "deepPurple-200",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple[200],
  },
  {
    name: "deepPurple-300",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple[300],
  },
  {
    name: "deepPurple-400",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple[400],
  },
  {
    name: "deepPurple-500",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple[500],
  },
  {
    name: "deepPurple-600",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple[600],
  },
  {
    name: "deepPurple-700",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple[700],
  },
  {
    name: "deepPurple-800",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple[800],
  },
  {
    name: "deepPurple-900",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple[900],
  },
  {
    name: "deepPurple-A100",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple["A100"],
  },
  {
    name: "deepPurple-A200",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple["A200"],
  },
  {
    name: "deepPurple-A400",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple["A400"],
  },
  {
    name: "deepPurple-A700",
    variant: ColorVariant.DeepPurple,
    hexCode: colors.deepPurple["A700"],
  },
  {
    name: "green-50",
    variant: ColorVariant.Green,
    hexCode: colors.green[50],
  },
  {
    name: "green-100",
    variant: ColorVariant.Green,
    hexCode: colors.green[100],
  },
  {
    name: "green-200",
    variant: ColorVariant.Green,
    hexCode: colors.green[200],
  },
  {
    name: "green-300",
    variant: ColorVariant.Green,
    hexCode: colors.green[300],
  },
  {
    name: "green-400",
    variant: ColorVariant.Green,
    hexCode: colors.green[400],
  },
  {
    name: "green-500",
    variant: ColorVariant.Green,
    hexCode: colors.green[500],
  },
  {
    name: "green-600",
    variant: ColorVariant.Green,
    hexCode: colors.green[600],
  },
  {
    name: "green-700",
    variant: ColorVariant.Green,
    hexCode: colors.green[700],
  },
  {
    name: "green-800",
    variant: ColorVariant.Green,
    hexCode: colors.green[800],
  },
  {
    name: "green-900",
    variant: ColorVariant.Green,
    hexCode: colors.green[900],
  },
  {
    name: "green-A100",
    variant: ColorVariant.Green,
    hexCode: colors.green["A100"],
  },
  {
    name: "green-A200",
    variant: ColorVariant.Green,
    hexCode: colors.green["A200"],
  },
  {
    name: "green-A400",
    variant: ColorVariant.Green,
    hexCode: colors.green["A400"],
  },
  {
    name: "green-A700",
    variant: ColorVariant.Green,
    hexCode: colors.green["A700"],
  },
  {
    name: "grey-50",
    variant: ColorVariant.Grey,
    hexCode: colors.grey[50],
  },
  {
    name: "grey-100",
    variant: ColorVariant.Grey,
    hexCode: colors.grey[100],
  },
  {
    name: "grey-200",
    variant: ColorVariant.Grey,
    hexCode: colors.grey[200],
  },
  {
    name: "grey-300",
    variant: ColorVariant.Grey,
    hexCode: colors.grey[300],
  },
  {
    name: "grey-400",
    variant: ColorVariant.Grey,
    hexCode: colors.grey[400],
  },
  {
    name: "grey-500",
    variant: ColorVariant.Grey,
    hexCode: colors.grey[500],
  },
  {
    name: "grey-600",
    variant: ColorVariant.Grey,
    hexCode: colors.grey[600],
  },
  {
    name: "grey-700",
    variant: ColorVariant.Grey,
    hexCode: colors.grey[700],
  },
  {
    name: "grey-800",
    variant: ColorVariant.Grey,
    hexCode: colors.grey[800],
  },
  {
    name: "grey-900",
    variant: ColorVariant.Grey,
    hexCode: colors.grey[900],
  },
  {
    name: "grey-A100",
    variant: ColorVariant.Grey,
    hexCode: colors.grey["A100"],
  },
  {
    name: "grey-A200",
    variant: ColorVariant.Grey,
    hexCode: colors.grey["A200"],
  },
  {
    name: "grey-A400",
    variant: ColorVariant.Grey,
    hexCode: colors.grey["A400"],
  },
  {
    name: "grey-A700",
    variant: ColorVariant.Grey,
    hexCode: colors.grey["A700"],
  },
  {
    name: "indigo-50",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo[50],
  },
  {
    name: "indigo-100",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo[100],
  },
  {
    name: "indigo-200",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo[200],
  },
  {
    name: "indigo-300",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo[300],
  },
  {
    name: "indigo-400",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo[400],
  },
  {
    name: "indigo-500",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo[500],
  },
  {
    name: "indigo-600",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo[600],
  },
  {
    name: "indigo-700",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo[700],
  },
  {
    name: "indigo-800",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo[800],
  },
  {
    name: "indigo-900",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo[900],
  },
  {
    name: "indigo-A100",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo["A100"],
  },
  {
    name: "indigo-A200",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo["A200"],
  },
  {
    name: "indigo-A400",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo["A400"],
  },
  {
    name: "indigo-A700",
    variant: ColorVariant.Indigo,
    hexCode: colors.indigo["A700"],
  },
  {
    name: "lightBlue-50",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue[50],
  },
  {
    name: "lightBlue-100",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue[100],
  },
  {
    name: "lightBlue-200",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue[200],
  },
  {
    name: "lightBlue-300",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue[300],
  },
  {
    name: "lightBlue-400",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue[400],
  },
  {
    name: "lightBlue-500",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue[500],
  },
  {
    name: "lightBlue-600",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue[600],
  },
  {
    name: "lightBlue-700",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue[700],
  },
  {
    name: "lightBlue-800",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue[800],
  },
  {
    name: "lightBlue-900",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue[900],
  },
  {
    name: "lightBlue-A100",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue["A100"],
  },
  {
    name: "lightBlue-A200",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue["A200"],
  },
  {
    name: "lightBlue-A400",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue["A400"],
  },
  {
    name: "lightBlue-A700",
    variant: ColorVariant.LightBlue,
    hexCode: colors.lightBlue["A700"],
  },
  {
    name: "lightGreen-50",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen[50],
  },
  {
    name: "lightGreen-100",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen[100],
  },
  {
    name: "lightGreen-200",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen[200],
  },
  {
    name: "lightGreen-300",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen[300],
  },
  {
    name: "lightGreen-400",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen[400],
  },
  {
    name: "lightGreen-500",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen[500],
  },
  {
    name: "lightGreen-600",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen[600],
  },
  {
    name: "lightGreen-700",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen[700],
  },
  {
    name: "lightGreen-800",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen[800],
  },
  {
    name: "lightGreen-900",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen[900],
  },
  {
    name: "lightGreen-A100",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen["A100"],
  },
  {
    name: "lightGreen-A200",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen["A200"],
  },
  {
    name: "lightGreen-A400",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen["A400"],
  },
  {
    name: "lightGreen-A700",
    variant: ColorVariant.LightGreen,
    hexCode: colors.lightGreen["A700"],
  },
  {
    name: "lime-50",
    variant: ColorVariant.Lime,
    hexCode: colors.lime[50],
  },
  {
    name: "lime-100",
    variant: ColorVariant.Lime,
    hexCode: colors.lime[100],
  },
  {
    name: "lime-200",
    variant: ColorVariant.Lime,
    hexCode: colors.lime[200],
  },
  {
    name: "lime-300",
    variant: ColorVariant.Lime,
    hexCode: colors.lime[300],
  },
  {
    name: "lime-400",
    variant: ColorVariant.Lime,
    hexCode: colors.lime[400],
  },
  {
    name: "lime-500",
    variant: ColorVariant.Lime,
    hexCode: colors.lime[500],
  },
  {
    name: "lime-600",
    variant: ColorVariant.Lime,
    hexCode: colors.lime[600],
  },
  {
    name: "lime-700",
    variant: ColorVariant.Lime,
    hexCode: colors.lime[700],
  },
  {
    name: "lime-800",
    variant: ColorVariant.Lime,
    hexCode: colors.lime[800],
  },
  {
    name: "lime-900",
    variant: ColorVariant.Lime,
    hexCode: colors.lime[900],
  },
  {
    name: "lime-A100",
    variant: ColorVariant.Lime,
    hexCode: colors.lime["A100"],
  },
  {
    name: "lime-A200",
    variant: ColorVariant.Lime,
    hexCode: colors.lime["A200"],
  },
  {
    name: "lime-A400",
    variant: ColorVariant.Lime,
    hexCode: colors.lime["A400"],
  },
  {
    name: "lime-A700",
    variant: ColorVariant.Lime,
    hexCode: colors.lime["A700"],
  },
  {
    name: "orange-50",
    variant: ColorVariant.Orange,
    hexCode: colors.orange[50],
  },
  {
    name: "orange-100",
    variant: ColorVariant.Orange,
    hexCode: colors.orange[100],
  },
  {
    name: "orange-200",
    variant: ColorVariant.Orange,
    hexCode: colors.orange[200],
  },
  {
    name: "orange-300",
    variant: ColorVariant.Orange,
    hexCode: colors.orange[300],
  },
  {
    name: "orange-400",
    variant: ColorVariant.Orange,
    hexCode: colors.orange[400],
  },
  {
    name: "orange-500",
    variant: ColorVariant.Orange,
    hexCode: colors.orange[500],
  },
  {
    name: "orange-600",
    variant: ColorVariant.Orange,
    hexCode: colors.orange[600],
  },
  {
    name: "orange-700",
    variant: ColorVariant.Orange,
    hexCode: colors.orange[700],
  },
  {
    name: "orange-800",
    variant: ColorVariant.Orange,
    hexCode: colors.orange[800],
  },
  {
    name: "orange-900",
    variant: ColorVariant.Orange,
    hexCode: colors.orange[900],
  },
  {
    name: "orange-A100",
    variant: ColorVariant.Orange,
    hexCode: colors.orange["A100"],
  },
  {
    name: "orange-A200",
    variant: ColorVariant.Orange,
    hexCode: colors.orange["A200"],
  },
  {
    name: "orange-A400",
    variant: ColorVariant.Orange,
    hexCode: colors.orange["A400"],
  },
  {
    name: "orange-A700",
    variant: ColorVariant.Orange,
    hexCode: colors.orange["A700"],
  },
  {
    name: "pink-50",
    variant: ColorVariant.Pink,
    hexCode: colors.pink[50],
  },
  {
    name: "pink-100",
    variant: ColorVariant.Pink,
    hexCode: colors.pink[100],
  },
  {
    name: "pink-200",
    variant: ColorVariant.Pink,
    hexCode: colors.pink[200],
  },
  {
    name: "pink-300",
    variant: ColorVariant.Pink,
    hexCode: colors.pink[300],
  },
  {
    name: "pink-400",
    variant: ColorVariant.Pink,
    hexCode: colors.pink[400],
  },
  {
    name: "pink-500",
    variant: ColorVariant.Pink,
    hexCode: colors.pink[500],
  },
  {
    name: "pink-600",
    variant: ColorVariant.Pink,
    hexCode: colors.pink[600],
  },
  {
    name: "pink-700",
    variant: ColorVariant.Pink,
    hexCode: colors.pink[700],
  },
  {
    name: "pink-800",
    variant: ColorVariant.Pink,
    hexCode: colors.pink[800],
  },
  {
    name: "pink-900",
    variant: ColorVariant.Pink,
    hexCode: colors.pink[900],
  },
  {
    name: "pink-A100",
    variant: ColorVariant.Pink,
    hexCode: colors.pink["A100"],
  },
  {
    name: "pink-A200",
    variant: ColorVariant.Pink,
    hexCode: colors.pink["A200"],
  },
  {
    name: "pink-A400",
    variant: ColorVariant.Pink,
    hexCode: colors.pink["A400"],
  },
  {
    name: "pink-A700",
    variant: ColorVariant.Pink,
    hexCode: colors.pink["A700"],
  },
  {
    name: "purple-50",
    variant: ColorVariant.Purple,
    hexCode: colors.purple[50],
  },
  {
    name: "purple-100",
    variant: ColorVariant.Purple,
    hexCode: colors.purple[100],
  },
  {
    name: "purple-200",
    variant: ColorVariant.Purple,
    hexCode: colors.purple[200],
  },
  {
    name: "purple-300",
    variant: ColorVariant.Purple,
    hexCode: colors.purple[300],
  },
  {
    name: "purple-400",
    variant: ColorVariant.Purple,
    hexCode: colors.purple[400],
  },
  {
    name: "purple-500",
    variant: ColorVariant.Purple,
    hexCode: colors.purple[500],
  },
  {
    name: "purple-600",
    variant: ColorVariant.Purple,
    hexCode: colors.purple[600],
  },
  {
    name: "purple-700",
    variant: ColorVariant.Purple,
    hexCode: colors.purple[700],
  },
  {
    name: "purple-800",
    variant: ColorVariant.Purple,
    hexCode: colors.purple[800],
  },
  {
    name: "purple-900",
    variant: ColorVariant.Purple,
    hexCode: colors.purple[900],
  },
  {
    name: "purple-A100",
    variant: ColorVariant.Purple,
    hexCode: colors.purple["A100"],
  },
  {
    name: "purple-A200",
    variant: ColorVariant.Purple,
    hexCode: colors.purple["A200"],
  },
  {
    name: "purple-A400",
    variant: ColorVariant.Purple,
    hexCode: colors.purple["A400"],
  },
  {
    name: "purple-A700",
    variant: ColorVariant.Purple,
    hexCode: colors.purple["A700"],
  },
  {
    name: "red-50",
    variant: ColorVariant.Red,
    hexCode: colors.red[50],
  },
  {
    name: "red-100",
    variant: ColorVariant.Red,
    hexCode: colors.red[100],
  },
  {
    name: "red-200",
    variant: ColorVariant.Red,
    hexCode: colors.red[200],
  },
  {
    name: "red-300",
    variant: ColorVariant.Red,
    hexCode: colors.red[300],
  },
  {
    name: "red-400",
    variant: ColorVariant.Red,
    hexCode: colors.red[400],
  },
  {
    name: "red-500",
    variant: ColorVariant.Red,
    hexCode: colors.red[500],
  },
  {
    name: "red-600",
    variant: ColorVariant.Red,
    hexCode: colors.red[600],
  },
  {
    name: "red-700",
    variant: ColorVariant.Red,
    hexCode: colors.red[700],
  },
  {
    name: "red-800",
    variant: ColorVariant.Red,
    hexCode: colors.red[800],
  },
  {
    name: "red-900",
    variant: ColorVariant.Red,
    hexCode: colors.red[900],
  },
  {
    name: "red-A100",
    variant: ColorVariant.Red,
    hexCode: colors.red["A100"],
  },
  {
    name: "red-A200",
    variant: ColorVariant.Red,
    hexCode: colors.red["A200"],
  },
  {
    name: "red-A400",
    variant: ColorVariant.Red,
    hexCode: colors.red["A400"],
  },
  {
    name: "red-A700",
    variant: ColorVariant.Red,
    hexCode: colors.red["A700"],
  },
  {
    name: "teal-50",
    variant: ColorVariant.Teal,
    hexCode: colors.teal[50],
  },
  {
    name: "teal-100",
    variant: ColorVariant.Teal,
    hexCode: colors.teal[100],
  },
  {
    name: "teal-200",
    variant: ColorVariant.Teal,
    hexCode: colors.teal[200],
  },
  {
    name: "teal-300",
    variant: ColorVariant.Teal,
    hexCode: colors.teal[300],
  },
  {
    name: "teal-400",
    variant: ColorVariant.Teal,
    hexCode: colors.teal[400],
  },
  {
    name: "teal-500",
    variant: ColorVariant.Teal,
    hexCode: colors.teal[500],
  },
  {
    name: "teal-600",
    variant: ColorVariant.Teal,
    hexCode: colors.teal[600],
  },
  {
    name: "teal-700",
    variant: ColorVariant.Teal,
    hexCode: colors.teal[700],
  },
  {
    name: "teal-800",
    variant: ColorVariant.Teal,
    hexCode: colors.teal[800],
  },
  {
    name: "teal-900",
    variant: ColorVariant.Teal,
    hexCode: colors.teal[900],
  },
  {
    name: "teal-A100",
    variant: ColorVariant.Teal,
    hexCode: colors.teal["A100"],
  },
  {
    name: "teal-A200",
    variant: ColorVariant.Teal,
    hexCode: colors.teal["A200"],
  },
  {
    name: "teal-A400",
    variant: ColorVariant.Teal,
    hexCode: colors.teal["A400"],
  },
  {
    name: "teal-A700",
    variant: ColorVariant.Teal,
    hexCode: colors.teal["A700"],
  },
  {
    name: "yellow-50",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow[50],
  },
  {
    name: "yellow-100",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow[100],
  },
  {
    name: "yellow-200",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow[200],
  },
  {
    name: "yellow-300",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow[300],
  },
  {
    name: "yellow-400",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow[400],
  },
  {
    name: "yellow-500",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow[500],
  },
  {
    name: "yellow-600",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow[600],
  },
  {
    name: "yellow-700",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow[700],
  },
  {
    name: "yellow-800",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow[800],
  },
  {
    name: "yellow-900",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow[900],
  },
  {
    name: "yellow-A100",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow["A100"],
  },
  {
    name: "yellow-A200",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow["A200"],
  },
  {
    name: "yellow-A400",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow["A400"],
  },
  {
    name: "yellow-A700",
    variant: ColorVariant.Yellow,
    hexCode: colors.yellow["A700"],
  },
];

export const MUI_COLOR_SHADES: IColorShades[] = [
  {
    variant: ColorVariant.Blue,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Blue),
  },
  {
    variant: ColorVariant.Red,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Red),
  },
  {
    variant: ColorVariant.Amber,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Amber),
  },
  {
    variant: ColorVariant.BlueGrey,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.BlueGrey),
  },
  {
    variant: ColorVariant.Brown,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Brown),
  },
  {
    variant: ColorVariant.Cyan,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Cyan),
  },
  {
    variant: ColorVariant.DeepOrange,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.DeepOrange),
  },
  {
    variant: ColorVariant.DeepPurple,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.DeepPurple),
  },
  {
    variant: ColorVariant.Green,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Green),
  },
  {
    variant: ColorVariant.Grey,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Grey),
  },
  {
    variant: ColorVariant.Indigo,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Indigo),
  },
  {
    variant: ColorVariant.LightBlue,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.LightBlue),
  },
  {
    variant: ColorVariant.LightGreen,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.LightGreen),
  },
  {
    variant: ColorVariant.Lime,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Lime),
  },
  {
    variant: ColorVariant.Orange,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Orange),
  },
  {
    variant: ColorVariant.Pink,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Pink),
  },
  {
    variant: ColorVariant.Purple,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Purple),
  },
  {
    variant: ColorVariant.Teal,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Teal),
  },
  {
    variant: ColorVariant.Yellow,
    shades: MUI_COLORS.filter((c) => c.variant === ColorVariant.Yellow),
  },
];

/**
 * Contains the best colors suitable for flow diagrams from each color variant
 */
export const STUDIO_COLOR_SHADES: IColorShades[] = [

];
