import React from "react";
import { ICatalogProps } from "./Catalog.types";
import { Grid, Paper } from "@mui/material";
import { getCatalogStyles } from "./Catalog.styles";
import { ConnectedNodeList } from "./node-list/ConnectedNodeList";
import { useBoolean } from "usehooks-ts";

export const Catalog: React.FC<ICatalogProps> = () => {
  const { value, toggle } = useBoolean(true);

  const styles = getCatalogStyles(value);
  return (
    <Paper sx={styles.rootContainer} elevation={0}>
      <Grid container sx={styles.header} onClick={toggle}>
        {/* <Grid item sx={styles.title} xs={6}>
          <Typography variant="body1" sx={styles.catalogTitle}>
            Catalog
          </Typography>
        </Grid> */}
      </Grid>
      {value && (
        <Grid item sx={styles.nodeListContainer}>
          <ConnectedNodeList />
        </Grid>
      )}
    </Paper>
  );
};
