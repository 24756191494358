import React from "react";
import { IHeaderTitleProps } from "./HeaderTitle.types";
import { Grid, Paper, Typography } from "@mui/material";
import { getHeaderTitleStyles } from "./HeaderTitle.styles";

export const HeaderTitle: React.FC<IHeaderTitleProps> = ({ title, author }) => {
  const styles = getHeaderTitleStyles();
  return (
    <Paper sx={styles.rootContainer} elevation={0}>
      <Grid container flexDirection="column" textAlign="center">
        <Grid item>
          <Typography variant="h5" sx={styles.title}>
            {title}
          </Typography>
          <Typography variant="body2" sx={styles.secondaryTitle}>
            {author}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
