import { colors, keyframes } from "@mui/material";
import { ICatalogStyles } from "./Catalog.types";

export const rotateAnimation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const getCatalogStyles = (isOpen: boolean): ICatalogStyles => {
  return {
    rootContainer: {
      // p: isOpen ? 1 : 0.8,
      // pr: 2,
      // bgcolor: "#5B3FB5", // "rgba(175,154,243,0.5)",
      // backgroundColor: "#3e2492",
      // borderRadius: isOpen ? 4 : 10,
      transition: "border-radius 0.6s cubic-bezier(0.4, 0, 1, 1)",
      // boxShadow: isOpen
      //   ? "10px 0 15px rgba(42, 138, 246, 0.3), -2px 0 15px rgba(233, 42, 103, 0.3)"
      //   : undefined,
    },
    header: {
      cursor: "pointer",
    },
    title: {
      pl: 1,
      color: "#5B3FB5",
      fontWeight: "bold",
    },
    secondaryTitle: { color: "white", fontSize: 12 },
    nodeListContainer: {
      width: !isOpen ? 0 : 280,
      height: "90vh",
      transition: "width 1s ease",
      // overflow: "scroll",
      overflowY: "scroll",
      backgroundColor: "white",
    },
    catalogTitle: {
      fontWeight: "bold",
      pt: 0.3,
      pl: 1,
    },
    iconButton: {
      color: "white",
      cursor: "pointer",
      height: 30,
      width: 30,
      // animation: `${rotateAnimation} 8s linear infinite`,
    },
    closeButton: {
      color: colors.purple[300],
      cursor: "pointer",
    },
  };
};
