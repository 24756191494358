import React from "react";
import { LabelColor } from "./LabelColor";
import { IConnectedLabelColorProps } from "./LabelColor.types";
import { useCanvasInformation } from "../../../../../hooks/useCanvasInformation";

export const ConnectedLabelColor: React.FC<IConnectedLabelColorProps> = () => {
  const { activeNode, setTextColor } = useCanvasInformation();

  const isNodeSelected = activeNode !== undefined;
  const [colorPalletElement, setColorPalletElement] =
    React.useState<HTMLButtonElement | null>(null);

  const handleOpenColorPalletPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setColorPalletElement(event.currentTarget);
  };

  const handleCloseColorPalletPopover = () => {
    setColorPalletElement(null);
  };

  const handleColorChange = (color: string) => {
    setTextColor(color);
    handleCloseColorPalletPopover();
  };

  return (
    <LabelColor
      isNodeSelected={isNodeSelected}
      onButtonClick={handleOpenColorPalletPopover}
      colorPalletElement={colorPalletElement}
      onPopoverClose={handleCloseColorPalletPopover}
      setTextColor={handleColorChange}
    />
  );
};
