import {
  nodes as growwNodes,
  edges as growwEdges,
} from "./groww-system-design";
import {
  cachingTechinquesEdges,
  cachingTechinquesNodes,
} from "./caching-strategies";
import {
  instagramCloseFriendsEdges,
  instagramCloseFriendsNodes,
} from "./instagram-close-friends-only";
import {
  paymentIdempotencyNodes,
  paymentIdempotencyEdges,
} from "./payment-idempotency-design";

import {
  nodes as lbagNodes,
  edges as lbagEdges,
} from "./loadbalancer-vs-api-gateway";

export const useGetFlowData = (id: string) => {
  switch (id) {
    case "stock-broker-system-design":
      return {
        title: "Stock Broker System Design",
        author: "Sunil Gudivada",
        nodes: growwNodes,
        edges: growwEdges,
      };
    case "caching-techniques":
      return {
        title: "Caching Techniques",
        author: "Sunil Gudivada",
        nodes: cachingTechinquesNodes,
        edges: cachingTechinquesEdges,
      };
    case "instagram-close-friends-system-design":
      return {
        title: "Instagram Close Friends System Design",
        author: "Sunil Gudivada",
        nodes: instagramCloseFriendsNodes,
        edges: instagramCloseFriendsEdges,
      };
    case "payment-idempotency-system-design":
      return {
        title: "Handle Idempotency in Payment System Design",
        author: "Sunil Gudivada",
        nodes: paymentIdempotencyNodes,
        edges: paymentIdempotencyEdges,
      };
    case "loadbalancer-vs-api-gateway":
      return {
        title: "Load Balancer Vs API Gateway",
        author: "Sunil Gudivada",
        nodes: lbagNodes,
        edges: lbagEdges,
      };
    default:
      return {
        nodes: [],
        edges: [],
      };
  }
};
