import React from "react";
import "./App.css";
import { ConnectedCanvasDesigner } from "./components/canvas-designer/ConnectedCanvasDesigner";
import { ConnectedCanvasDesigner as DesignerView } from "./components/flow/ConnectedCanvasDesigner";
import { ReactFlowProvider } from "@xyflow/react";
import { Routes, Route } from "react-router-dom";
import { ConnectedHome } from "./components/home/ConnectedHome";
import { createTheme, ThemeProvider } from "@mui/material";
import { ToolbarProvider } from "./context/ToolbarContext";

const App: React.FC = () => {
  const theme = createTheme({
    typography: {
      fontFamily: `"Poppins"`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<ConnectedHome />} />
        {/* <Route
          path="/designer"
          element={
            <ReactFlowProvider>
              <ConnectedCanvasDesigner />
            </ReactFlowProvider>
          }
        /> */}
        <Route
          path="/flow/:flowId"
          element={
            <ReactFlowProvider>
              <DesignerView />
            </ReactFlowProvider>
          }
        />
        <Route
          path="/flow/new"
          element={
            <ReactFlowProvider>
              <ToolbarProvider>
                <ConnectedCanvasDesigner />
              </ToolbarProvider>
            </ReactFlowProvider>
          }
        />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
