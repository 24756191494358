import { IHeaderTitleStyles } from "./HeaderTitle.types";

export const getHeaderTitleStyles = (): IHeaderTitleStyles => {
  return {
    rootContainer: {
      p: 1,
      bgcolor: "#5B3FB5", // "rgba(175,154,243,0.5)",
      // background: "linear-gradient(-45deg, #cc2b5e, #753a88)",
      borderColor: "white",
      // border: "1px solid white",
    },
    title: {
      color: "white",
      fontWeight: "bold",
    },
    secondaryTitle: { color: "white", fontSize: 12 },
  };
};
