import { type Node, type Edge } from "@xyflow/react";

export const nodes: Node[] = [
  {
    id: "a72cd214-ff26-4382-b78a-fd0dcc59cd23",
    type: "client",
    position: { x: 235.28892737750164, y: 485.97768032646894 },
    data: { label: "Client" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "32388be4-e95c-4041-8fc0-e26d9c31a830",
    type: "client",
    position: { x: 240, y: 300 },
    data: { label: "Client" },
    measured: { width: 84, height: 72 },
  },
  {
    id: "86830949-3f5d-458a-bf5c-c97bd61f1533",
    type: "microservice",
    position: { x: 1167.4152129976285, y: -94.12301754906393 },
    data: { label: "Microservice" },
    measured: { width: 92, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "148ac3a5-b762-44c6-93fb-3dca0429b6ce",
    type: "load_balancer",
    position: { x: 923.014574374717, y: 15.266966485363227 },
    data: { label: "Load Balancer" },
    measured: { width: 99, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "0342c531-424a-4f4e-8e35-179167bdc10a",
    type: "load_balancer",
    position: { x: 919.806722189133, y: 387.5592263655641 },
    data: { label: "Load Balancer" },
    measured: { width: 99, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "b3027dc8-947b-458b-bba3-57a36e7c236e",
    type: "load_balancer",
    position: { x: 922.8422757071313, y: 727.2310836837388 },
    data: { label: "Load Balancer" },
    measured: { width: 99, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "5e02b06a-d6e6-42f4-a730-7bc954098613",
    type: "microservice",
    position: { x: 1165.1589536016434, y: 118.49285867317424 },
    data: { label: "Microservice" },
    measured: { width: 92, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "f3f80387-428a-44ba-bef6-98041fd9b526",
    type: "microservice",
    position: { x: 1162.2846861337264, y: 10.433455497888772 },
    data: { label: "Microservice" },
    measured: { width: 92, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "868e26d5-bc95-4696-a618-5ff664912133",
    type: "client",
    position: { x: 105, y: 390 },
    data: { label: "Client" },
    measured: { width: 84, height: 72 },
    selected: false,
  },
  {
    id: "fa230146-5cad-46e5-9748-bb43df69de87",
    type: "api_gateway",
    position: { x: 433.52885136674945, y: 391.804920892271 },
    data: { label: "API Gateway" },
    measured: { width: 85, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "647ff90a-a694-486d-b2f4-76ca0753e2fe",
    type: "microservice",
    position: { x: 1169.8017578128902, y: 322.9170474646374 },
    data: { label: "Microservice" },
    measured: { width: 92, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "32ff42b4-90f4-48a5-8d2b-755a4cb9ee42",
    type: "microservice",
    position: { x: 1164.7219683569083, y: 454.5806677475384 },
    data: { label: "Microservice" },
    measured: { width: 92, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "8b91d258-adba-4716-b200-fce1026c5812",
    type: "microservice",
    position: { x: 1164.5760561962575, y: 610.6472471835193 },
    data: { label: "Microservice" },
    measured: { width: 92, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "d8f6aa53-88bc-4b64-bf66-ed00fc8d358a",
    type: "microservice",
    position: { x: 1163.035595493631, y: 726.2944943670387 },
    data: { label: "Microservice" },
    measured: { width: 92, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "38be9957-bd52-4257-abf8-915fc34cd7aa",
    type: "microservice",
    position: { x: 1158.213572961786, y: 854.3300898606698 },
    data: { label: "Microservice" },
    measured: { width: 92, height: 72 },
    selected: false,
    dragging: false,
  },
];
export const edges: Edge[] = [
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "868e26d5-bc95-4696-a618-5ff664912133",
    sourceHandle: "868e26d5-bc95-4696-a618-5ff664912133-source-right",
    target: "fa230146-5cad-46e5-9748-bb43df69de87",
    targetHandle: "fa230146-5cad-46e5-9748-bb43df69de87-source-left",
    id: "xy-edge__868e26d5-bc95-4696-a618-5ff664912133868e26d5-bc95-4696-a618-5ff664912133-source-right-fa230146-5cad-46e5-9748-bb43df69de87fa230146-5cad-46e5-9748-bb43df69de87-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "32388be4-e95c-4041-8fc0-e26d9c31a830",
    sourceHandle: "32388be4-e95c-4041-8fc0-e26d9c31a830-source-right",
    target: "fa230146-5cad-46e5-9748-bb43df69de87",
    targetHandle: "fa230146-5cad-46e5-9748-bb43df69de87-source-left",
    id: "xy-edge__32388be4-e95c-4041-8fc0-e26d9c31a83032388be4-e95c-4041-8fc0-e26d9c31a830-source-right-fa230146-5cad-46e5-9748-bb43df69de87fa230146-5cad-46e5-9748-bb43df69de87-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "a72cd214-ff26-4382-b78a-fd0dcc59cd23",
    sourceHandle: "a72cd214-ff26-4382-b78a-fd0dcc59cd23-source-right",
    target: "fa230146-5cad-46e5-9748-bb43df69de87",
    targetHandle: "fa230146-5cad-46e5-9748-bb43df69de87-source-left",
    id: "xy-edge__a72cd214-ff26-4382-b78a-fd0dcc59cd23a72cd214-ff26-4382-b78a-fd0dcc59cd23-source-right-fa230146-5cad-46e5-9748-bb43df69de87fa230146-5cad-46e5-9748-bb43df69de87-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "fa230146-5cad-46e5-9748-bb43df69de87",
    sourceHandle: "fa230146-5cad-46e5-9748-bb43df69de87-source-right",
    target: "148ac3a5-b762-44c6-93fb-3dca0429b6ce",
    targetHandle: "148ac3a5-b762-44c6-93fb-3dca0429b6ce-source-left",
    id: "xy-edge__fa230146-5cad-46e5-9748-bb43df69de87fa230146-5cad-46e5-9748-bb43df69de87-source-right-148ac3a5-b762-44c6-93fb-3dca0429b6ce148ac3a5-b762-44c6-93fb-3dca0429b6ce-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "fa230146-5cad-46e5-9748-bb43df69de87",
    sourceHandle: "fa230146-5cad-46e5-9748-bb43df69de87-source-right",
    target: "0342c531-424a-4f4e-8e35-179167bdc10a",
    targetHandle: "0342c531-424a-4f4e-8e35-179167bdc10a-source-left",
    id: "xy-edge__fa230146-5cad-46e5-9748-bb43df69de87fa230146-5cad-46e5-9748-bb43df69de87-source-right-0342c531-424a-4f4e-8e35-179167bdc10a0342c531-424a-4f4e-8e35-179167bdc10a-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "fa230146-5cad-46e5-9748-bb43df69de87",
    sourceHandle: "fa230146-5cad-46e5-9748-bb43df69de87-source-right",
    target: "b3027dc8-947b-458b-bba3-57a36e7c236e",
    targetHandle: "b3027dc8-947b-458b-bba3-57a36e7c236e-source-left",
    id: "xy-edge__fa230146-5cad-46e5-9748-bb43df69de87fa230146-5cad-46e5-9748-bb43df69de87-source-right-b3027dc8-947b-458b-bba3-57a36e7c236eb3027dc8-947b-458b-bba3-57a36e7c236e-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "148ac3a5-b762-44c6-93fb-3dca0429b6ce",
    sourceHandle: "148ac3a5-b762-44c6-93fb-3dca0429b6ce-source-right",
    target: "86830949-3f5d-458a-bf5c-c97bd61f1533",
    targetHandle: "86830949-3f5d-458a-bf5c-c97bd61f1533-source-left",
    id: "xy-edge__148ac3a5-b762-44c6-93fb-3dca0429b6ce148ac3a5-b762-44c6-93fb-3dca0429b6ce-source-right-86830949-3f5d-458a-bf5c-c97bd61f153386830949-3f5d-458a-bf5c-c97bd61f1533-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "148ac3a5-b762-44c6-93fb-3dca0429b6ce",
    sourceHandle: "148ac3a5-b762-44c6-93fb-3dca0429b6ce-source-right",
    target: "f3f80387-428a-44ba-bef6-98041fd9b526",
    targetHandle: "f3f80387-428a-44ba-bef6-98041fd9b526-source-left",
    id: "xy-edge__148ac3a5-b762-44c6-93fb-3dca0429b6ce148ac3a5-b762-44c6-93fb-3dca0429b6ce-source-right-f3f80387-428a-44ba-bef6-98041fd9b526f3f80387-428a-44ba-bef6-98041fd9b526-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "148ac3a5-b762-44c6-93fb-3dca0429b6ce",
    sourceHandle: "148ac3a5-b762-44c6-93fb-3dca0429b6ce-source-right",
    target: "5e02b06a-d6e6-42f4-a730-7bc954098613",
    targetHandle: "5e02b06a-d6e6-42f4-a730-7bc954098613-source-left",
    id: "xy-edge__148ac3a5-b762-44c6-93fb-3dca0429b6ce148ac3a5-b762-44c6-93fb-3dca0429b6ce-source-right-5e02b06a-d6e6-42f4-a730-7bc9540986135e02b06a-d6e6-42f4-a730-7bc954098613-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "0342c531-424a-4f4e-8e35-179167bdc10a",
    sourceHandle: "0342c531-424a-4f4e-8e35-179167bdc10a-source-right",
    target: "647ff90a-a694-486d-b2f4-76ca0753e2fe",
    targetHandle: "647ff90a-a694-486d-b2f4-76ca0753e2fe-source-left",
    id: "xy-edge__0342c531-424a-4f4e-8e35-179167bdc10a0342c531-424a-4f4e-8e35-179167bdc10a-source-right-647ff90a-a694-486d-b2f4-76ca0753e2fe647ff90a-a694-486d-b2f4-76ca0753e2fe-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "0342c531-424a-4f4e-8e35-179167bdc10a",
    sourceHandle: "0342c531-424a-4f4e-8e35-179167bdc10a-source-right",
    target: "32ff42b4-90f4-48a5-8d2b-755a4cb9ee42",
    targetHandle: "32ff42b4-90f4-48a5-8d2b-755a4cb9ee42-source-left",
    id: "xy-edge__0342c531-424a-4f4e-8e35-179167bdc10a0342c531-424a-4f4e-8e35-179167bdc10a-source-right-32ff42b4-90f4-48a5-8d2b-755a4cb9ee4232ff42b4-90f4-48a5-8d2b-755a4cb9ee42-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "b3027dc8-947b-458b-bba3-57a36e7c236e",
    sourceHandle: "b3027dc8-947b-458b-bba3-57a36e7c236e-source-right",
    target: "8b91d258-adba-4716-b200-fce1026c5812",
    targetHandle: "8b91d258-adba-4716-b200-fce1026c5812-source-left",
    id: "xy-edge__b3027dc8-947b-458b-bba3-57a36e7c236eb3027dc8-947b-458b-bba3-57a36e7c236e-source-right-8b91d258-adba-4716-b200-fce1026c58128b91d258-adba-4716-b200-fce1026c5812-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "b3027dc8-947b-458b-bba3-57a36e7c236e",
    sourceHandle: "b3027dc8-947b-458b-bba3-57a36e7c236e-source-right",
    target: "d8f6aa53-88bc-4b64-bf66-ed00fc8d358a",
    targetHandle: "d8f6aa53-88bc-4b64-bf66-ed00fc8d358a-source-left",
    id: "xy-edge__b3027dc8-947b-458b-bba3-57a36e7c236eb3027dc8-947b-458b-bba3-57a36e7c236e-source-right-d8f6aa53-88bc-4b64-bf66-ed00fc8d358ad8f6aa53-88bc-4b64-bf66-ed00fc8d358a-source-left",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "b3027dc8-947b-458b-bba3-57a36e7c236e",
    sourceHandle: "b3027dc8-947b-458b-bba3-57a36e7c236e-source-right",
    target: "38be9957-bd52-4257-abf8-915fc34cd7aa",
    targetHandle: "38be9957-bd52-4257-abf8-915fc34cd7aa-source-left",
    id: "xy-edge__b3027dc8-947b-458b-bba3-57a36e7c236eb3027dc8-947b-458b-bba3-57a36e7c236e-source-right-38be9957-bd52-4257-abf8-915fc34cd7aa38be9957-bd52-4257-abf8-915fc34cd7aa-source-left",
  },
];
