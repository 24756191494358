import { type Node, type Edge } from "@xyflow/react";

export const instagramCloseFriendsNodes: Node[] = [
  {
    id: "f97e7246-cbd3-4109-a809-10dd896551ef",
    type: "microservice",
    position: { x: 589.4128888310861, y: -143.53335774213306 },
    data: { label: "User Service" },
    measured: { width: 86, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "98c5d361-5f2d-4505-b39a-315f5babb7d3",
    type: "microservice",
    position: { x: 557.8361423682394, y: 57.92140513259176 },
    data: { label: "Access Control Service" },
    measured: { width: 157, height: 71 },
    selected: false,
    dragging: false,
    width: 157,
    height: 71,
    resizing: false,
  },
  {
    id: "c5d40286-571a-464d-b1f9-e813de59b93f",
    type: "microservice",
    position: { x: 1229.7921034312142, y: 199.14018872708783 },
    data: { label: "Notification Service" },
    measured: { width: 129, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "e4567aa3-fedc-4ecc-bc0b-05008ec35e0b",
    type: "microservice",
    position: { x: 832.6488432429877, y: -144.12957859605694 },
    data: { label: "Close friends Service" },
    measured: { width: 140, height: 72 },
    selected: false,
    dragging: false,
    width: 140,
    height: 72,
    resizing: false,
  },
  {
    id: "61ed1332-c17c-4e85-9497-6ca85d7b7f15",
    type: "microservice",
    position: { x: 583.5516561655799, y: 206.26808886678634 },
    data: { label: "Content Service" },
    measured: { width: 107, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "623c3fb0-d180-409b-b7ec-baf6270677ca",
    type: "load_balancer",
    position: { x: 348.27944095548077, y: 111.96831547077596 },
    data: { label: "Load Balancer" },
    measured: { width: 99, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "5aba965f-48a8-41f0-8bb1-b85d2d2cec27",
    type: "client",
    position: { x: 143.79192121437026, y: -14.296752931533565 },
    data: { label: "Client" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "ddaedf29-28c3-4d90-862c-deeab0f5859d",
    type: "client",
    position: { x: 113.99441266975757, y: 113.35987274879813 },
    data: { label: "Client" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "232eda58-d7b5-4546-8a66-01a0bf632cac",
    type: "client",
    position: { x: 145.43222575162957, y: 254.00874079348358 },
    data: { label: "Client" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "064fc74b-93e5-4d83-a059-6fd2d61190bd",
    type: "database",
    position: { x: 589.9871337619219, y: -272.3363499494262 },
    data: { label: "MySQL DB" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "9b3932ad-6093-4f63-8c68-a6972f4b3442",
    type: "database",
    position: { x: 859.7170517803597, y: -272.7559763225611 },
    data: { label: "MySQL DB" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "cb96160b-7550-4cf3-a64b-eeef04c4a340",
    type: "message_queue",
    position: { x: 884.1067331561046, y: 202.32355847292814 },
    data: { label: "Queue" },
    measured: { width: 154, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "bd112e78-882c-45f2-b2bb-c47a2611c5a5",
    type: "database",
    position: { x: 487.8156266390297, y: 372.07697389264507 },
    data: { label: "Metadata DB" },
    measured: { width: 88, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "11aaa339-bea4-4b6d-bec3-9a6cf7732dd4",
    type: "cloud_server",
    position: { x: 794.5390745165789, y: 369.88662301327594 },
    data: { label: "Cloud Storage" },
    measured: { width: 101, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "f43a40f7-3eaa-4704-85fa-33804dd54be3",
    type: "client",
    position: { x: 1444.1148604188427, y: 68.93927876314743 },
    data: { label: "Client" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "160ea301-563d-4273-bf71-ddebe15828c5",
    type: "client",
    position: { x: 1457.5812842476257, y: 321.62143848242386 },
    data: { label: "Client" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
];

export const instagramCloseFriendsEdges: Edge[] = [
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "5aba965f-48a8-41f0-8bb1-b85d2d2cec27",
    sourceHandle: "5aba965f-48a8-41f0-8bb1-b85d2d2cec27-source-right",
    target: "623c3fb0-d180-409b-b7ec-baf6270677ca",
    targetHandle: "623c3fb0-d180-409b-b7ec-baf6270677ca-source-left",
    id: "xy-edge__5aba965f-48a8-41f0-8bb1-b85d2d2cec275aba965f-48a8-41f0-8bb1-b85d2d2cec27-source-right-623c3fb0-d180-409b-b7ec-baf6270677ca623c3fb0-d180-409b-b7ec-baf6270677ca-source-left",
    selected: false,
    label: "View story",
    data: { label: "View story" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "ddaedf29-28c3-4d90-862c-deeab0f5859d",
    sourceHandle: "ddaedf29-28c3-4d90-862c-deeab0f5859d-source-right",
    target: "623c3fb0-d180-409b-b7ec-baf6270677ca",
    targetHandle: "623c3fb0-d180-409b-b7ec-baf6270677ca-source-left",
    id: "xy-edge__ddaedf29-28c3-4d90-862c-deeab0f5859dddaedf29-28c3-4d90-862c-deeab0f5859d-source-right-623c3fb0-d180-409b-b7ec-baf6270677ca623c3fb0-d180-409b-b7ec-baf6270677ca-source-left",
    selected: false,
    label: "Upload Media",
    data: { label: "Upload Media" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "232eda58-d7b5-4546-8a66-01a0bf632cac",
    sourceHandle: "232eda58-d7b5-4546-8a66-01a0bf632cac-source-right",
    target: "623c3fb0-d180-409b-b7ec-baf6270677ca",
    targetHandle: "623c3fb0-d180-409b-b7ec-baf6270677ca-source-left",
    id: "xy-edge__232eda58-d7b5-4546-8a66-01a0bf632cac232eda58-d7b5-4546-8a66-01a0bf632cac-source-right-623c3fb0-d180-409b-b7ec-baf6270677ca623c3fb0-d180-409b-b7ec-baf6270677ca-source-left",
    selected: false,
    label: "Visits Timeline Feed",
    data: { label: "Visits Timeline Feed" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "623c3fb0-d180-409b-b7ec-baf6270677ca",
    sourceHandle: "623c3fb0-d180-409b-b7ec-baf6270677ca-source-right",
    target: "f97e7246-cbd3-4109-a809-10dd896551ef",
    targetHandle: "f97e7246-cbd3-4109-a809-10dd896551ef-source-left",
    id: "xy-edge__623c3fb0-d180-409b-b7ec-baf6270677ca623c3fb0-d180-409b-b7ec-baf6270677ca-source-right-f97e7246-cbd3-4109-a809-10dd896551eff97e7246-cbd3-4109-a809-10dd896551ef-source-left",
    selected: false,
    label: "Get / Update Profile Information",
    data: { label: "Get / Update Profile Information" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "f97e7246-cbd3-4109-a809-10dd896551ef",
    sourceHandle: "f97e7246-cbd3-4109-a809-10dd896551ef-source-right",
    target: "e4567aa3-fedc-4ecc-bc0b-05008ec35e0b",
    targetHandle: "e4567aa3-fedc-4ecc-bc0b-05008ec35e0b-source-left",
    id: "xy-edge__f97e7246-cbd3-4109-a809-10dd896551eff97e7246-cbd3-4109-a809-10dd896551ef-source-right-e4567aa3-fedc-4ecc-bc0b-05008ec35e0be4567aa3-fedc-4ecc-bc0b-05008ec35e0b-source-left",
    selected: false,
    label: "Get Close friends List",
    data: { label: "Get Close friends List" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "f97e7246-cbd3-4109-a809-10dd896551ef",
    sourceHandle: "f97e7246-cbd3-4109-a809-10dd896551ef-source-top",
    target: "064fc74b-93e5-4d83-a059-6fd2d61190bd",
    targetHandle: "064fc74b-93e5-4d83-a059-6fd2d61190bd-source-bottom",
    id: "xy-edge__f97e7246-cbd3-4109-a809-10dd896551eff97e7246-cbd3-4109-a809-10dd896551ef-source-top-064fc74b-93e5-4d83-a059-6fd2d61190bd064fc74b-93e5-4d83-a059-6fd2d61190bd-source-bottom",
    selected: false,
    label: "Get user profile information",
    data: { label: "Get user profile information" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "e4567aa3-fedc-4ecc-bc0b-05008ec35e0b",
    sourceHandle: "e4567aa3-fedc-4ecc-bc0b-05008ec35e0b-source-top",
    target: "9b3932ad-6093-4f63-8c68-a6972f4b3442",
    targetHandle: "9b3932ad-6093-4f63-8c68-a6972f4b3442-source-bottom",
    id: "xy-edge__e4567aa3-fedc-4ecc-bc0b-05008ec35e0be4567aa3-fedc-4ecc-bc0b-05008ec35e0b-source-top-9b3932ad-6093-4f63-8c68-a6972f4b34429b3932ad-6093-4f63-8c68-a6972f4b3442-source-bottom",
    selected: false,
    label: "Get user friends information",
    data: { label: "Get user friends information" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "61ed1332-c17c-4e85-9497-6ca85d7b7f15",
    sourceHandle: "61ed1332-c17c-4e85-9497-6ca85d7b7f15-source-right",
    target: "cb96160b-7550-4cf3-a64b-eeef04c4a340",
    targetHandle: "cb96160b-7550-4cf3-a64b-eeef04c4a340-source-left",
    id: "xy-edge__61ed1332-c17c-4e85-9497-6ca85d7b7f1561ed1332-c17c-4e85-9497-6ca85d7b7f15-source-right-cb96160b-7550-4cf3-a64b-eeef04c4a340cb96160b-7550-4cf3-a64b-eeef04c4a340-source-left",
    selected: false,
    label: "Publish the content sharing event",
    data: { label: "Publish the content sharing event" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "cb96160b-7550-4cf3-a64b-eeef04c4a340",
    sourceHandle: "cb96160b-7550-4cf3-a64b-eeef04c4a340-source-right",
    target: "c5d40286-571a-464d-b1f9-e813de59b93f",
    targetHandle: "c5d40286-571a-464d-b1f9-e813de59b93f-source-left",
    id: "xy-edge__cb96160b-7550-4cf3-a64b-eeef04c4a340cb96160b-7550-4cf3-a64b-eeef04c4a340-source-right-c5d40286-571a-464d-b1f9-e813de59b93fc5d40286-571a-464d-b1f9-e813de59b93f-source-left",
    selected: false,
    label: "Consume notification event",
    data: { label: "Consume notification event" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "623c3fb0-d180-409b-b7ec-baf6270677ca",
    sourceHandle: "623c3fb0-d180-409b-b7ec-baf6270677ca-source-right",
    target: "61ed1332-c17c-4e85-9497-6ca85d7b7f15",
    targetHandle: "61ed1332-c17c-4e85-9497-6ca85d7b7f15-source-left",
    id: "xy-edge__623c3fb0-d180-409b-b7ec-baf6270677ca623c3fb0-d180-409b-b7ec-baf6270677ca-source-right-61ed1332-c17c-4e85-9497-6ca85d7b7f1561ed1332-c17c-4e85-9497-6ca85d7b7f15-source-left",
    selected: false,
    label: "Upload / View the content",
    data: { label: "Upload / View the content" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "61ed1332-c17c-4e85-9497-6ca85d7b7f15",
    sourceHandle: "61ed1332-c17c-4e85-9497-6ca85d7b7f15-source-top",
    target: "98c5d361-5f2d-4505-b39a-315f5babb7d3",
    targetHandle: "98c5d361-5f2d-4505-b39a-315f5babb7d3-source-bottom",
    id: "xy-edge__61ed1332-c17c-4e85-9497-6ca85d7b7f1561ed1332-c17c-4e85-9497-6ca85d7b7f15-source-top-98c5d361-5f2d-4505-b39a-315f5babb7d398c5d361-5f2d-4505-b39a-315f5babb7d3-source-bottom",
    selected: false,
    label: "Get access control to the content",
    data: { label: "Get access control to the content" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "f97e7246-cbd3-4109-a809-10dd896551ef",
    sourceHandle: "f97e7246-cbd3-4109-a809-10dd896551ef-source-bottom",
    target: "98c5d361-5f2d-4505-b39a-315f5babb7d3",
    targetHandle: "98c5d361-5f2d-4505-b39a-315f5babb7d3-source-top",
    id: "xy-edge__f97e7246-cbd3-4109-a809-10dd896551eff97e7246-cbd3-4109-a809-10dd896551ef-source-bottom-98c5d361-5f2d-4505-b39a-315f5babb7d398c5d361-5f2d-4505-b39a-315f5babb7d3-source-top",
    selected: false,
    label: "Check if user have access to content",
    data: { label: "Check if user have access to content" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "61ed1332-c17c-4e85-9497-6ca85d7b7f15",
    sourceHandle: "61ed1332-c17c-4e85-9497-6ca85d7b7f15-source-bottom",
    target: "bd112e78-882c-45f2-b2bb-c47a2611c5a5",
    targetHandle: "bd112e78-882c-45f2-b2bb-c47a2611c5a5-source-top",
    id: "xy-edge__61ed1332-c17c-4e85-9497-6ca85d7b7f1561ed1332-c17c-4e85-9497-6ca85d7b7f15-source-bottom-bd112e78-882c-45f2-b2bb-c47a2611c5a5bd112e78-882c-45f2-b2bb-c47a2611c5a5-source-top",
    selected: false,
    label: "Get / Update Metadata information",
    data: { label: "Get / Update Metadata information" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "61ed1332-c17c-4e85-9497-6ca85d7b7f15",
    sourceHandle: "61ed1332-c17c-4e85-9497-6ca85d7b7f15-source-bottom",
    target: "11aaa339-bea4-4b6d-bec3-9a6cf7732dd4",
    targetHandle: "11aaa339-bea4-4b6d-bec3-9a6cf7732dd4-source-top",
    id: "xy-edge__61ed1332-c17c-4e85-9497-6ca85d7b7f1561ed1332-c17c-4e85-9497-6ca85d7b7f15-source-bottom-11aaa339-bea4-4b6d-bec3-9a6cf7732dd411aaa339-bea4-4b6d-bec3-9a6cf7732dd4-source-top",
    selected: false,
    label: "Distributed Storage",
    data: { label: "Distributed Storage" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "c5d40286-571a-464d-b1f9-e813de59b93f",
    sourceHandle: "c5d40286-571a-464d-b1f9-e813de59b93f-source-right",
    target: "f43a40f7-3eaa-4704-85fa-33804dd54be3",
    targetHandle: "f43a40f7-3eaa-4704-85fa-33804dd54be3-source-left",
    id: "xy-edge__c5d40286-571a-464d-b1f9-e813de59b93fc5d40286-571a-464d-b1f9-e813de59b93f-source-right-f43a40f7-3eaa-4704-85fa-33804dd54be3f43a40f7-3eaa-4704-85fa-33804dd54be3-source-left",
    selected: false,
    label: "New Post Notification via websockets",
    data: { label: "New Post Notification via websockets" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "c5d40286-571a-464d-b1f9-e813de59b93f",
    sourceHandle: "c5d40286-571a-464d-b1f9-e813de59b93f-source-right",
    target: "160ea301-563d-4273-bf71-ddebe15828c5",
    targetHandle: "160ea301-563d-4273-bf71-ddebe15828c5-source-left",
    id: "xy-edge__c5d40286-571a-464d-b1f9-e813de59b93fc5d40286-571a-464d-b1f9-e813de59b93f-source-right-160ea301-563d-4273-bf71-ddebe15828c5160ea301-563d-4273-bf71-ddebe15828c5-source-left",
    selected: false,
    label: "New Story Notification via websocket ",
    data: { label: "New Story Notification via websocket " },
  },
];
