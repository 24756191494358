import { IArticleStyles } from "./Articles.types";

export const getArticleStyles = (matches: boolean): IArticleStyles => {
  return {
    rootContainer: {
      backgroundColor: "white",
      p: matches ? 10 : 4,
      mt: 10,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      width: { xs: "100%", md: "auto" },
    },
    author: {
      width: "400px",
    },
  };
};
