import React from "react";
import { IConnectedNodeListProps } from "./NodeList.types";
import { NodeList } from "./NodeList";
import { INodeTypes } from "../../../../nodes/node.types";
import { useGetNodeProperties } from "../../../../nodes/custom-node/hooks/useGetNodeProperties";

export const ConnectedNodeList: React.FC<IConnectedNodeListProps> = () => {
  const onDragStart = (event: any, nodeType: INodeTypes) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const nodeList = [
    useGetNodeProperties(INodeTypes.TEXT),
    useGetNodeProperties(INodeTypes.MICROSERVICE),
    useGetNodeProperties(INodeTypes.DATABASE),
    useGetNodeProperties(INodeTypes.MESSAGE_QUEUE),
    useGetNodeProperties(INodeTypes.CLIENT),
    useGetNodeProperties(INodeTypes.EXTERNAL_SERVICE),
    useGetNodeProperties(INodeTypes.API_GATEWAY),
    useGetNodeProperties(INodeTypes.LOAD_BALANCER),
    useGetNodeProperties(INodeTypes.CLOUD_SERVER),
    useGetNodeProperties(INodeTypes.DNS),
    useGetNodeProperties(INodeTypes.APPLICATION),
    useGetNodeProperties(INodeTypes.REDIS),
    useGetNodeProperties(INodeTypes.AZURE_BATCH_AI),
    useGetNodeProperties(INodeTypes.AZURE_MACHINE_LEARNING_STUDIO),
    useGetNodeProperties(INodeTypes.AZURE_GENOMICS),
    useGetNodeProperties(INodeTypes.AZURE_FACE_APIS),
    useGetNodeProperties(INodeTypes.AZURE_CONTENT_MODERATORS),
    useGetNodeProperties(INodeTypes.AZURE_SPEECH_SERVICES),
    useGetNodeProperties(INodeTypes.AZURE_QNA_MAKERS),
    useGetNodeProperties(INodeTypes.AZURE_TRANSLATOR_TEXT),
    useGetNodeProperties(INodeTypes.AZURE_LANGUAGE_UNDERSTANDING),
    useGetNodeProperties(INodeTypes.AZURE_IMMERSIVE_READERS),
    useGetNodeProperties(INodeTypes.AZURE_ANOMALY_DETECTOR),
    useGetNodeProperties(INodeTypes.AZURE_FORM_RECOGNIZERS),
    useGetNodeProperties(INodeTypes.AZURE_EXPERIMENTATION_STUDIO),
    useGetNodeProperties(INodeTypes.AZURE_OBJECT_UNDERSTANDING),
    useGetNodeProperties(INodeTypes.AZURE_METRICS_ADVISOR),
    useGetNodeProperties(INodeTypes.AZURE_APPLIED_AI_SERVICES),
    useGetNodeProperties(INodeTypes.AZURE_LANGUAGE),
    useGetNodeProperties(INodeTypes.AZURE_COGNITIVE_SERVICES_DECISIONS),
    useGetNodeProperties(INodeTypes.AZURE_SERVERLESS_SEARCH),
    useGetNodeProperties(INodeTypes.AZURE_BONSAI),
    useGetNodeProperties(INodeTypes.AZURE_CONTENT_SAFETY),
    useGetNodeProperties(INodeTypes.AZURE_OPENAI),
    useGetNodeProperties(INodeTypes.AZURE_AI_STUDIO),
    useGetNodeProperties(INodeTypes.AZURE_COGNITIVE_SEARCH),
    useGetNodeProperties(INodeTypes.AZURE_COGNITIVE_SERVICES),
    useGetNodeProperties(INodeTypes.AZURE_BOT_SERVICES),
    useGetNodeProperties(INodeTypes.AZURE_MACHINE_LEARNING),
    useGetNodeProperties(INodeTypes.AZURE_LOG_ANALYTICS_WORKSPACES),
    useGetNodeProperties(INodeTypes.AZURE_EVENT_HUBS),
    useGetNodeProperties(INodeTypes.AZURE_STREAM_ANALYTICS_JOBS),
    useGetNodeProperties(INodeTypes.AZURE_ENDPOINT_ANALYTICS),
    useGetNodeProperties(INodeTypes.AZURE_SYNAPSE_ANALYTICS),
    useGetNodeProperties(INodeTypes.AZURE_WORKBOOKS),
    useGetNodeProperties(INodeTypes.AZURE_PRIVATE_LINK_SERVICES),
    useGetNodeProperties(INodeTypes.AZURE_POWER_BI_EMBEDDED),
    useGetNodeProperties(INodeTypes.AZURE_POWER_PLATFORM),
    useGetNodeProperties(INodeTypes.AZURE_DATA_FACTORIES),
    useGetNodeProperties(INodeTypes.AZURE_HD_INSIGHT_CLUSTERS),
    useGetNodeProperties(INodeTypes.AZURE_DATA_LAKE_ANALYTICS),
    useGetNodeProperties(INodeTypes.AZURE_DATA_EXPLORER_CLUSTERS),
    useGetNodeProperties(INodeTypes.AZURE_ANALYSIS_SERVICES),
    useGetNodeProperties(INodeTypes.AZURE_EVENT_HUB_CLUSTERS),
    useGetNodeProperties(INodeTypes.AZURE_DATA_LAKE_STORE_GEN1),
    useGetNodeProperties(INodeTypes.AZURE_DATABRICKS),
    useGetNodeProperties(INodeTypes.AZURE_APP_SERVICE_PLANS),
    useGetNodeProperties(INodeTypes.AZURE_APP_SERVICE_CERTIFICATES),
    useGetNodeProperties(INodeTypes.AZURE_APP_SERVICE_DOMAINS),
    useGetNodeProperties(INodeTypes.AZURE_CDN_PROFILES),
    useGetNodeProperties(INodeTypes.AZURE_APP_SERVICES),
    useGetNodeProperties(INodeTypes.AZURE_COGNITIVE_SEARCH),
    useGetNodeProperties(INodeTypes.AZURE_NOTIFICATION_HUBS),
    useGetNodeProperties(INodeTypes.AZURE_APP_SERVICE_ENVIRONMENTS),
    useGetNodeProperties(INodeTypes.AZURE_DATABASE_MIGRATION_SERVICES),
    useGetNodeProperties(INodeTypes.AZURE_DATABASE_MYSQL_SERVER),
    useGetNodeProperties(INodeTypes.AZURE_PURVIEW_ACCOUNTS),
    useGetNodeProperties(INodeTypes.AZURE_ORACLE_DATABASE),
    useGetNodeProperties(INodeTypes.AZURE_SQL_SERVER_STRETCH_DATABASES),
    useGetNodeProperties(INodeTypes.AZURE_DATABASE_POSTGRESQL_SERVER_GROUP),
    useGetNodeProperties(INodeTypes.AZURE_SQL_EDGE),
    useGetNodeProperties(INodeTypes.AZURE_INSTANCE_POOLS),
    useGetNodeProperties(INodeTypes.AZURE_ELASTIC_JOB_AGENTS),
    useGetNodeProperties(INodeTypes.AZURE_COSMOS_DB),
    useGetNodeProperties(INodeTypes.AZURE_SQL_ELASTIC_POOLS),
    useGetNodeProperties(INodeTypes.AZURE_SQL_MANAGED_INSTANCE),
    useGetNodeProperties(INodeTypes.AZURE_DATABASE_MARIADB_SERVER),
    useGetNodeProperties(INodeTypes.AZURE_SQL),
    useGetNodeProperties(INodeTypes.AZURE_SQL_SERVER_REGISTRIES),
    useGetNodeProperties(INodeTypes.AZURE_SQL_VM),
    useGetNodeProperties(INodeTypes.AZURE_VIRTUAL_CLUSTERS),
    useGetNodeProperties(INodeTypes.AZURE_SQL_SERVER),
    useGetNodeProperties(INodeTypes.AZURE_MANAGED_DATABASE),
    useGetNodeProperties(INodeTypes.AZURE_CACHE_REDIS),
    useGetNodeProperties(INodeTypes.AZURE_SQL_DATA_WAREHOUSES),
    useGetNodeProperties(INodeTypes.AZURE_DATABASE_POSTGRESQL_SERVER),
    useGetNodeProperties(INodeTypes.AZURE_SQL_DATABASE),
    useGetNodeProperties(INodeTypes.AZURE_APPLICATION_INSIGHTS),
  ];
  return <NodeList onDragStart={onDragStart} nodeList={nodeList} />;
};
