import { Edge, Node } from "@xyflow/react";

export const paymentIdempotencyNodes: Node[] = [
  {
    id: "dbc97af5-a42f-495e-a733-50c2c677f501",
    type: "client",
    position: { x: 255.44711961693218, y: 104.03673810597985 },
    data: { label: "Web" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "cdaf8835-f6a1-4436-8314-66b25e6fd803",
    type: "client",
    position: { x: 203.13126202395924, y: 254.03026784786704 },
    data: { label: "Mobile" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "bf3f9f0a-c288-42b7-8110-599a9323f191",
    type: "client",
    position: { x: 257.483857722912, y: 416.96326189402015 },
    data: { label: "Dealer" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "36e750fe-6470-4e72-b41e-71a39c58a805",
    type: "microservice",
    position: { x: 806.175262966242, y: 259.49761310369854 },
    data: { label: "Payment Service" },
    measured: { width: 106, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "6e9ca841-65de-4f01-b373-e9da8fe483cb",
    type: "load_balancer",
    position: { x: 494.5, y: 259.21606736054014 },
    data: { label: "Load Balancer" },
    measured: { width: 99, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "4a444cb9-7ce6-45be-bcf9-20876442be67",
    type: "external_service",
    position: { x: 1754.359786563544, y: 250.1280246496601 },
    data: { label: "External" },
    measured: { width: 84, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "4989e67a-a91c-4867-8b59-7ce363e90da7",
    type: "azure_cosmos_db",
    position: { x: 782.5875637970514, y: 506.5788984455587 },
    data: { label: "Azure Cosmos DB" },
    measured: { width: 112, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "c888e17d-0494-4e5e-a822-bc3e8178ddbc",
    type: "message_queue",
    position: { x: 1100.7106896391474, y: 260.22692876422997 },
    data: { label: "Queue" },
    measured: { width: 154, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "cffe508d-e8f1-434d-9336-87cdd58b074b",
    type: "microservice",
    position: { x: 1412.6060291527888, y: 255.775418852859 },
    data: { label: "Payment Status Service" },
    measured: { width: 145, height: 72 },
    selected: false,
    dragging: false,
  },
  {
    id: "9ceec327-e05f-4ff2-b5a1-5eb4bf67460f",
    type: "text",
    position: { x: 952.9403295197569, y: 310.07745862496307 },
    data: { label: "Idempotency Key" },
    measured: { width: 104, height: 14 },
    selected: false,
    dragging: false,
  },
  {
    id: "8b95d964-ed99-4639-996f-1e56f4a0837f",
    type: "text",
    position: { x: 1572.5357497916573, y: 304.6440032387939 },
    data: { label: "along with idempotency Key" },
    measured: { width: 167, height: 14 },
    selected: true,
    dragging: false,
  },
  {
    id: "a153eeec-d400-41bc-bb8a-f0c5ee7fdeea",
    type: "text",
    position: { x: 703.849693805863, y: 604.43774149348 },
    data: { label: "Save the Idempotency Key to retrieve payment Status" },
    measured: { width: 298, height: 14 },
    selected: false,
    dragging: false,
  },
];
export const paymentIdempotencyEdges: Edge[] = [
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "36e750fe-6470-4e72-b41e-71a39c58a805",
    sourceHandle: "36e750fe-6470-4e72-b41e-71a39c58a805-source-bottom",
    target: "4989e67a-a91c-4867-8b59-7ce363e90da7",
    targetHandle: "4989e67a-a91c-4867-8b59-7ce363e90da7-source-left",
    id: "xy-edge__36e750fe-6470-4e72-b41e-71a39c58a80536e750fe-6470-4e72-b41e-71a39c58a805-source-bottom-4989e67a-a91c-4867-8b59-7ce363e90da74989e67a-a91c-4867-8b59-7ce363e90da7-source-left",
    selected: false,
    label: "Init Request",
    data: { label: "Init Request" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "36e750fe-6470-4e72-b41e-71a39c58a805",
    sourceHandle: "36e750fe-6470-4e72-b41e-71a39c58a805-source-right",
    target: "c888e17d-0494-4e5e-a822-bc3e8178ddbc",
    targetHandle: "c888e17d-0494-4e5e-a822-bc3e8178ddbc-source-left",
    id: "xy-edge__36e750fe-6470-4e72-b41e-71a39c58a80536e750fe-6470-4e72-b41e-71a39c58a805-source-right-c888e17d-0494-4e5e-a822-bc3e8178ddbcc888e17d-0494-4e5e-a822-bc3e8178ddbc-source-left",
    selected: false,
    label: "Push Payment Init Event",
    data: { label: "Push Payment Init Event" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "6e9ca841-65de-4f01-b373-e9da8fe483cb",
    sourceHandle: "6e9ca841-65de-4f01-b373-e9da8fe483cb-source-right",
    target: "36e750fe-6470-4e72-b41e-71a39c58a805",
    targetHandle: "36e750fe-6470-4e72-b41e-71a39c58a805-source-left",
    id: "xy-edge__6e9ca841-65de-4f01-b373-e9da8fe483cb6e9ca841-65de-4f01-b373-e9da8fe483cb-source-right-36e750fe-6470-4e72-b41e-71a39c58a80536e750fe-6470-4e72-b41e-71a39c58a805-source-left",
    selected: false,
    label: "Create Payment Request",
    data: { label: "Create Payment Request" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "dbc97af5-a42f-495e-a733-50c2c677f501",
    sourceHandle: "dbc97af5-a42f-495e-a733-50c2c677f501-source-right",
    target: "6e9ca841-65de-4f01-b373-e9da8fe483cb",
    targetHandle: "6e9ca841-65de-4f01-b373-e9da8fe483cb-source-left",
    id: "xy-edge__dbc97af5-a42f-495e-a733-50c2c677f501dbc97af5-a42f-495e-a733-50c2c677f501-source-right-6e9ca841-65de-4f01-b373-e9da8fe483cb6e9ca841-65de-4f01-b373-e9da8fe483cb-source-left",
    selected: false,
    label: "Create Payment Request",
    data: { label: "Create Payment Request" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "cdaf8835-f6a1-4436-8314-66b25e6fd803",
    sourceHandle: "cdaf8835-f6a1-4436-8314-66b25e6fd803-source-right",
    target: "6e9ca841-65de-4f01-b373-e9da8fe483cb",
    targetHandle: "6e9ca841-65de-4f01-b373-e9da8fe483cb-source-left",
    id: "xy-edge__cdaf8835-f6a1-4436-8314-66b25e6fd803cdaf8835-f6a1-4436-8314-66b25e6fd803-source-right-6e9ca841-65de-4f01-b373-e9da8fe483cb6e9ca841-65de-4f01-b373-e9da8fe483cb-source-left",
    selected: false,
    label: "Get the payment Order Status",
    data: { label: "Get the payment Order Status" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "36e750fe-6470-4e72-b41e-71a39c58a805",
    sourceHandle: "36e750fe-6470-4e72-b41e-71a39c58a805-source-bottom",
    target: "4989e67a-a91c-4867-8b59-7ce363e90da7",
    targetHandle: "4989e67a-a91c-4867-8b59-7ce363e90da7-source-right",
    id: "xy-edge__36e750fe-6470-4e72-b41e-71a39c58a80536e750fe-6470-4e72-b41e-71a39c58a805-source-bottom-4989e67a-a91c-4867-8b59-7ce363e90da74989e67a-a91c-4867-8b59-7ce363e90da7-source-right",
    selected: false,
    label: "Payment Status",
    data: { label: "Payment Status" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "4a444cb9-7ce6-45be-bcf9-20876442be67",
    sourceHandle: "4a444cb9-7ce6-45be-bcf9-20876442be67-source-left",
    target: "cffe508d-e8f1-434d-9336-87cdd58b074b",
    targetHandle: "cffe508d-e8f1-434d-9336-87cdd58b074b-source-right",
    selected: false,
    label: "Payment Status",
    data: { label: "Payment Status" },
    id: "xy-edge__4a444cb9-7ce6-45be-bcf9-20876442be674a444cb9-7ce6-45be-bcf9-20876442be67-source-left-cffe508d-e8f1-434d-9336-87cdd58b074bcffe508d-e8f1-434d-9336-87cdd58b074b-source-right",
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "cffe508d-e8f1-434d-9336-87cdd58b074b",
    sourceHandle: "cffe508d-e8f1-434d-9336-87cdd58b074b-source-left",
    target: "c888e17d-0494-4e5e-a822-bc3e8178ddbc",
    targetHandle: "c888e17d-0494-4e5e-a822-bc3e8178ddbc-source-right",
    id: "xy-edge__cffe508d-e8f1-434d-9336-87cdd58b074bcffe508d-e8f1-434d-9336-87cdd58b074b-source-left-c888e17d-0494-4e5e-a822-bc3e8178ddbcc888e17d-0494-4e5e-a822-bc3e8178ddbc-source-right",
    selected: false,
    label: "Push Payment Status Event",
    data: { label: "Push Payment Status Event" },
  },
  {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
    source: "bf3f9f0a-c288-42b7-8110-599a9323f191",
    sourceHandle: "bf3f9f0a-c288-42b7-8110-599a9323f191-source-right",
    target: "6e9ca841-65de-4f01-b373-e9da8fe483cb",
    targetHandle: "6e9ca841-65de-4f01-b373-e9da8fe483cb-source-left",
    id: "xy-edge__bf3f9f0a-c288-42b7-8110-599a9323f191bf3f9f0a-c288-42b7-8110-599a9323f191-source-right-6e9ca841-65de-4f01-b373-e9da8fe483cb6e9ca841-65de-4f01-b373-e9da8fe483cb-source-left",
    selected: false,
    label: "Order Completed",
    data: { label: "Order Completed" },
  },
];
