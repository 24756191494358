import { Box, Grid } from "@mui/material";
import { MUI_COLOR_SHADES } from "../../../../ui-library/colors";
import { IColorPalletProps } from "./ColorPallet.types";
import React from "react";

export const ColorPallet: React.FC<IColorPalletProps> = ({
  onColorChange,
  palletType,
}) => {
  return (
    <Grid container rowSpacing={2} sx={{ pt: 2 }}>
      {MUI_COLOR_SHADES?.map((color, index) => (
        <Grid container key={`shade-${index}`}>
          {color.shades.map((shade, index) => (
            <Grid
              item
              key={"color-" + shade.hexCode + index}
              onClick={() => {
                onColorChange(shade.hexCode, palletType);
              }}
            >
              <Box
                sx={{
                  bgcolor: shade.hexCode,
                  cursor: "pointer",
                  border: `1px solid white`,
                  "&:hover": {
                    border: `1px solid ${shade.hexCode}`,
                  },
                }}
                width={40}
                height={20}
                key={`color-${shade.hexCode}`}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};
