/* eslint-disable */
import React, { useState, useCallback, MouseEvent, useEffect } from "react";
import { CanvasDesigner } from "./CanvasDesigner";
import {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  type Node,
  type Edge,
  type FitViewOptions,
  type OnConnect,
  type OnNodesChange,
  type OnEdgesChange,
  type OnNodeDrag,
  type DefaultEdgeOptions,
  useReactFlow,
  EdgeTypes,
  reconnectEdge,
  OnSelectionChangeParams,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { CustomNode } from "../nodes/custom-node/CustomNode";
import { IEdgeTypes, INodeTypes } from "../nodes/node.types";
import CustomEdge from "../../ui-library/custom-edge/CustomEdge";
import { EditEdgeModal } from "./EditEdgeModal";
import { useBoolean } from "usehooks-ts";
import { useGetNodeProperties } from "../nodes/custom-node/hooks/useGetNodeProperties";
import { CustomTextNode } from "../nodes/custom-node/CustomTextNode";
import { useCanvasInformation } from "../../hooks/useCanvasInformation";

export const ConnectedCanvasDesigner: React.FC = () => {
  const {
    activeNode,
    activeEdge,
    setActiveEdge,
    setActiveNode,
    onCanvasSave,
    nodes,
    edges,
    setNodes,
    setEdges,
  } = useCanvasInformation();

  const { screenToFlowPosition } = useReactFlow();
  const { value: isEditEdgeLabelModalActive, toggle: toggleEditEdgeModal } =
    useBoolean(false);

  const fitViewOptions: FitViewOptions = {
    padding: 0.2,
  };

  const defaultEdgeOptions: DefaultEdgeOptions = {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
  };

  const onNodeDrag: OnNodeDrag = (_, node) => {
    // console.log("drag event", node.data);
  };

  const nodeTypes = {
    [INodeTypes.MICROSERVICE]: CustomNode,
    [INodeTypes.DATABASE]: CustomNode,
    [INodeTypes.MESSAGE_QUEUE]: CustomNode,
    [INodeTypes.CLIENT]: CustomNode,
    [INodeTypes.API_GATEWAY]: CustomNode,
    [INodeTypes.EXTERNAL_SERVICE]: CustomNode,
    [INodeTypes.LOAD_BALANCER]: CustomNode,
    [INodeTypes.CLOUD_SERVER]: CustomNode,
    [INodeTypes.DNS]: CustomNode,
    [INodeTypes.AZURE_BATCH_AI]: CustomNode,
    [INodeTypes.AZURE_MACHINE_LEARNING_STUDIO]: CustomNode,
    [INodeTypes.AZURE_GENOMICS]: CustomNode,
    [INodeTypes.AZURE_COMPUTER_VISION]: CustomNode,
    [INodeTypes.AZURE_FACE_APIS]: CustomNode,
    [INodeTypes.AZURE_CONTENT_MODERATORS]: CustomNode,
    [INodeTypes.AZURE_SPEECH_SERVICES]: CustomNode,
    [INodeTypes.AZURE_QNA_MAKERS]: CustomNode,
    [INodeTypes.AZURE_TRANSLATOR_TEXT]: CustomNode,
    [INodeTypes.AZURE_LANGUAGE_UNDERSTANDING]: CustomNode,
    [INodeTypes.AZURE_IMMERSIVE_READERS]: CustomNode,
    [INodeTypes.AZURE_ANOMALY_DETECTOR]: CustomNode,
    [INodeTypes.AZURE_FORM_RECOGNIZERS]: CustomNode,
    [INodeTypes.AZURE_EXPERIMENTATION_STUDIO]: CustomNode,
    [INodeTypes.AZURE_OBJECT_UNDERSTANDING]: CustomNode,
    [INodeTypes.AZURE_METRICS_ADVISOR]: CustomNode,
    [INodeTypes.AZURE_APPLIED_AI_SERVICES]: CustomNode,
    [INodeTypes.AZURE_LANGUAGE]: CustomNode,
    [INodeTypes.AZURE_COGNITIVE_SERVICES_DECISIONS]: CustomNode,
    [INodeTypes.AZURE_SERVERLESS_SEARCH]: CustomNode,
    [INodeTypes.AZURE_BONSAI]: CustomNode,
    [INodeTypes.AZURE_CONTENT_SAFETY]: CustomNode,
    [INodeTypes.AZURE_OPENAI]: CustomNode,
    [INodeTypes.AZURE_AI_STUDIO]: CustomNode,
    [INodeTypes.AZURE_COGNITIVE_SEARCH]: CustomNode,
    [INodeTypes.AZURE_COGNITIVE_SERVICES]: CustomNode,
    [INodeTypes.AZURE_BOT_SERVICES]: CustomNode,
    [INodeTypes.AZURE_MACHINE_LEARNING]: CustomNode,
    [INodeTypes.AZURE_LOG_ANALYTICS_WORKSPACES]: CustomNode,
    [INodeTypes.AZURE_EVENT_HUBS]: CustomNode,
    [INodeTypes.AZURE_STREAM_ANALYTICS_JOBS]: CustomNode,
    [INodeTypes.AZURE_ENDPOINT_ANALYTICS]: CustomNode,
    [INodeTypes.AZURE_SYNAPSE_ANALYTICS]: CustomNode,
    [INodeTypes.AZURE_WORKBOOKS]: CustomNode,
    [INodeTypes.AZURE_PRIVATE_LINK_SERVICES]: CustomNode,
    [INodeTypes.AZURE_POWER_BI_EMBEDDED]: CustomNode,
    [INodeTypes.AZURE_POWER_PLATFORM]: CustomNode,
    [INodeTypes.AZURE_DATA_FACTORIES]: CustomNode,
    [INodeTypes.AZURE_HD_INSIGHT_CLUSTERS]: CustomNode,
    [INodeTypes.AZURE_DATA_LAKE_ANALYTICS]: CustomNode,
    [INodeTypes.AZURE_DATA_EXPLORER_CLUSTERS]: CustomNode,
    [INodeTypes.AZURE_ANALYSIS_SERVICES]: CustomNode,
    [INodeTypes.AZURE_EVENT_HUB_CLUSTERS]: CustomNode,
    [INodeTypes.AZURE_DATA_LAKE_STORE_GEN1]: CustomNode,
    [INodeTypes.AZURE_DATABRICKS]: CustomNode,
    [INodeTypes.AZURE_APP_SERVICE_PLANS]: CustomNode,
    [INodeTypes.AZURE_APP_SERVICE_CERTIFICATES]: CustomNode,
    [INodeTypes.AZURE_APP_SERVICE_DOMAINS]: CustomNode,
    [INodeTypes.AZURE_CDN_PROFILES]: CustomNode,
    [INodeTypes.AZURE_NOTIFICATION_HUBS]: CustomNode,
    [INodeTypes.AZURE_APP_SERVICE_ENVIRONMENTS]: CustomNode,
    [INodeTypes.AZURE_DATABASE_MIGRATION_SERVICES]: CustomNode,
    [INodeTypes.AZURE_DATABASE_MYSQL_SERVER]: CustomNode,
    [INodeTypes.AZURE_PURVIEW_ACCOUNTS]: CustomNode,
    [INodeTypes.AZURE_ORACLE_DATABASE]: CustomNode,
    [INodeTypes.AZURE_SQL_SERVER_STRETCH_DATABASES]: CustomNode,
    [INodeTypes.AZURE_DATABASE_POSTGRESQL_SERVER_GROUP]: CustomNode,
    [INodeTypes.AZURE_SQL_EDGE]: CustomNode,
    [INodeTypes.AZURE_INSTANCE_POOLS]: CustomNode,
    [INodeTypes.AZURE_ELASTIC_JOB_AGENTS]: CustomNode,
    [INodeTypes.AZURE_COSMOS_DB]: CustomNode,
    [INodeTypes.AZURE_SQL_ELASTIC_POOLS]: CustomNode,
    [INodeTypes.AZURE_SQL_MANAGED_INSTANCE]: CustomNode,
    [INodeTypes.AZURE_DATABASE_MARIADB_SERVER]: CustomNode,
    [INodeTypes.AZURE_SQL]: CustomNode,
    [INodeTypes.AZURE_SQL_SERVER_REGISTRIES]: CustomNode,
    [INodeTypes.AZURE_SQL_VM]: CustomNode,
    [INodeTypes.AZURE_VIRTUAL_CLUSTERS]: CustomNode,
    [INodeTypes.AZURE_SQL_SERVER]: CustomNode,
    [INodeTypes.AZURE_MANAGED_DATABASE]: CustomNode,
    [INodeTypes.AZURE_CACHE_REDIS]: CustomNode,
    [INodeTypes.AZURE_SQL_DATA_WAREHOUSES]: CustomNode,
    [INodeTypes.AZURE_DATABASE_POSTGRESQL_SERVER]: CustomNode,
    [INodeTypes.AZURE_SQL_DATABASE]: CustomNode,
    [INodeTypes.AZURE_APP_SERVICES]: CustomNode,
    [INodeTypes.AZURE_APPLICATION_INSIGHTS]: CustomNode,
    [INodeTypes.APPLICATION]: CustomNode,
    [INodeTypes.REDIS]: CustomNode,
    [INodeTypes.TEXT]: CustomTextNode,
  };

  const edgeTypes: EdgeTypes = {
    [IEdgeTypes.DEFAULT]: CustomEdge,
  };

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds as Node[]));
    },
    [setNodes]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds as Edge[])),
    [setEdges]
  );

  const onConnect: OnConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds as Edge[])),
    [setEdges]
  );

  const onNodeDoubleClick = (event: MouseEvent, node: Node) => {
    setActiveNode(node);
  };

  const onNodeConfigurationPanelClose = () => {
    setActiveNode(undefined);
  };

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const getId = () => crypto.randomUUID();

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      // project was renamed to screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const nodeProperties = useGetNodeProperties(type);
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${nodeProperties.nodeName}` },
      };

      setNodes((nds) => nds?.concat(newNode));
    },
    [screenToFlowPosition]
  );

  const onNodeObjectEdit = (nodeData: any) => {
    const activeNodeId = activeNode?.id;
    if (!activeNodeId) {
      return;
    }
    const updatedNodes = nodes?.map((node) => {
      if (node.id === activeNodeId) {
        return {
          ...node,
          data: {
            ...node.data,
            ...nodeData.updated_src.data,
          },
          position: {
            ...node.position,
            ...nodeData.updated_src.position,
          },
          measured: {
            ...node.measured,
            ...nodeData.updated_src.measured,
          },
        };
      }
      return node;
    });
    if (updatedNodes) setNodes(updatedNodes);
  };

  const onEdgeDoubleClick = (event: MouseEvent, edge: Edge) => {
    toggleEditEdgeModal();
    setActiveEdge(edge);
  };

  const onUpdateEdgeLabel = (label: string) => {
    const activeEdgeId = activeEdge?.id;
    if (!activeEdgeId) {
      return;
    }
    const updatedEdges = edges?.map((edge) => {
      if (edge.id === activeEdgeId) {
        return {
          ...edge,
          label: label,
          data: {
            ...edge.data,
            label,
          },
        };
      }
      return edge;
    });
    if (updatedEdges) setEdges(updatedEdges);
    toggleEditEdgeModal();
  };

  const onReconnect = useCallback(
    (oldEdge: any, newConnection: any) =>
      setEdges((els) => reconnectEdge(oldEdge, newConnection, els as Edge[])),
    []
  );

  const onNodeClick = (event: MouseEvent, node: Node) => {
    setActiveNode(node);
  };

  const onEdgeClick = (event: MouseEvent, edge: Edge) => {
    setActiveEdge(edge);
  };

  const onSelectionChange = (elements: OnSelectionChangeParams) => {
    const selectedNodes = elements.nodes;

    // Check if a specific node is active
    const isNodeActive = selectedNodes.some(
      (node) => node.id === "targetNodeId"
    );
  };

  const onPaneClick = (event: any) => {
    setActiveNode(undefined);
    setActiveEdge(undefined);
  };

  return (
    <>
      <CanvasDesigner
        activeNode={activeNode}
        nodes={nodes as Node[]}
        edges={edges as Edge[]}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        onNodesChange={onNodesChange}
        onNodeDrag={onNodeDrag}
        onEdgesChange={onEdgesChange}
        onNodeDrop={onDrop}
        onNodeDragOver={onDragOver}
        fitViewOptions={fitViewOptions}
        defaultEdgeOptions={defaultEdgeOptions}
        onNodeClick={onNodeClick}
        onNodeDoubleClick={onNodeDoubleClick}
        onPanelClose={onNodeConfigurationPanelClose}
        onNodeDetailsEdit={onNodeObjectEdit}
        onCanvasSave={onCanvasSave}
        onEdgeClick={onEdgeClick}
        onEdgeDoubleClick={onEdgeDoubleClick}
        onReconnect={onReconnect}
        onSelectionChange={onSelectionChange}
        onPaneClick={onPaneClick}
      />
      <EditEdgeModal
        open={isEditEdgeLabelModalActive}
        handleClose={toggleEditEdgeModal}
        updateEdgeLabel={onUpdateEdgeLabel}
        label={(activeEdge?.label ?? activeEdge?.data?.label ?? "") + "" ?? ""}
      />
    </>
  );
};
