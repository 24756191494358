import React from "react";
import { IconButton, Popover, Tooltip, colors } from "@mui/material";
import { ILabelBackgroundColorProps } from "./LabelBackgroundColor.types";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import { IColorPalletType } from "../../color-pallet-section/ColorPallet.types";
import { ConnectedColorPallet } from "../../color-pallet-section/ConnectedColorPallet";

export const LabelBackgroundColor: React.FC<ILabelBackgroundColorProps> = ({
  isNodeSelected,
  onButtonClick,
  colorPalletElement,
  onPopoverClose,
  setTextBackgroundColor,
}) => {
  const open = Boolean(colorPalletElement);
  const id = open ? "label-background-popover" : undefined;

  return (
    <>
      <Tooltip title="Text Background Color">
        <IconButton
          aria-label="delete"
          size="large"
          disabled={isNodeSelected ? false : true}
          onClick={onButtonClick}
          sx={{
            color: isNodeSelected ? colors.blueGrey[700] : colors.grey[300],
          }}
        >
          <FontDownloadIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={colorPalletElement}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              width: "600px",
              pt: 1,
              pl: 1,
              pb: 1,
            },
          },
        }}
      >
        <ConnectedColorPallet
          palletType={IColorPalletType.TextBackground}
          onColorChange={setTextBackgroundColor}
        />
      </Popover>
    </>
  );
};
