import React from "react";
import { Grid } from "@mui/material";
import { IColorOptionsSectionProps } from "./ColorOptionsSection.types";
import { ConnectedNodeBackgroundColor } from "./node-background-color/ConnectedNodeBackgroundColor";
import { ConnectedLabelColor } from "./label-color/ConnectedLabelColor";
import { ConnectedLabelBackgroundColor } from "./label-background-color/ConnectedLabelBackgroundColor";

export const ColorOptionsSection: React.FC<
  IColorOptionsSectionProps
> = ({}) => {
  return (
    <Grid sx={{ pl: 1, pr: 1, width: "auto" }} container columnSpacing={1}>
      <Grid item>
        <ConnectedNodeBackgroundColor />
      </Grid>
      <Grid item>
        <ConnectedLabelColor />
      </Grid>
      <Grid item>
        <ConnectedLabelBackgroundColor />
      </Grid>
    </Grid>
  );
};
