import React from "react";
import { INodeListProps } from "./NodeList.types";
import { Grid, Typography } from "@mui/material";
import { getNodeListStyles } from "./NodeList.styles";

export const NodeList: React.FC<INodeListProps> = ({
  onDragStart,
  nodeList,
}) => {
  const styles = getNodeListStyles();

  return (
    <Grid
      container
      justifyContent={"space-around"}
      textAlign="center"
      sx={styles.rootContainer}
    >
      {nodeList
        .filter((node) => node.category != "azure")
        .map((node, index) => (
          <Grid
            item
            textAlign="center"
            sx={{
              ...styles.nodeContainer,
              backgroundColor: node.backgroundColor,
              background: node.backgroundColor,
            }}
            onDragStart={(event) => onDragStart(event, node.type)}
            draggable
            key={index}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              key={index}
              sx={{ p: 1 }}
            >
              <Grid item>
                <img src={node.imgSrc} alt={node.nodeName} width="30" />
              </Grid>
              <Grid item textAlign={"center"}>
                <Typography
                  variant="body2"
                  component="text"
                  sx={styles.nodeTitle}
                >
                  {node.nodeName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}

      <Grid item xs={12}>
        <Typography variant="h6" sx={styles.title}>
          Azure Services
        </Typography>
      </Grid>
      {nodeList
        .filter((node) => node.category == "azure")
        .map((node, index) => (
          <Grid
            item
            textAlign="center"
            sx={{
              ...styles.nodeContainer,
              backgroundColor: node.backgroundColor,
              background: node.backgroundColor,
            }}
            onDragStart={(event) => onDragStart(event, node.type)}
            draggable
            key={index}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              key={index}
              sx={{ p: 1 }}
            >
              <Grid item>
                <img src={node.imgSrc} alt={node.nodeName} width="30" />
              </Grid>
              <Grid item textAlign={"center"}>
                <Typography
                  variant="body2"
                  component="text"
                  sx={styles.nodeTitle}
                >
                  {node.nodeName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};
