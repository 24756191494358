import { Grid } from "@mui/material";
import ReactJson from "react-json-view";
import { getEditorStyles } from "./Editor.styles";
import { IEditorProps } from "./Editor.types";
import React, { useEffect } from "react";

export const Editor: React.FC<IEditorProps> = ({ src, onEdit }) => {
  const styles = getEditorStyles();

  const [nodeData, setNodeData] = React.useState(src);

  useEffect(() => {
    setNodeData(src);
  }, [src]);

  const handleEdit = (edit: any) => {
    onEdit(edit);
  };

  return (
    <Grid sx={styles.rootContainer}>
      <ReactJson
        src={nodeData}
        enableClipboard={true}
        onEdit={handleEdit}
        onAdd={handleEdit}
        onDelete={handleEdit}
      />
    </Grid>
  );
};
