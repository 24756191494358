import { colors } from "@mui/material";
import { ICustomEdgeStyles } from "./CustomEdge.types";

export const getCustomEdgeStyles = (
  isSelected: boolean,
  labelX: number,
  labelY: number
): ICustomEdgeStyles => {
  return {
    baseEdge: {
      strokeWidth: 2,
      stroke: isSelected ? colors.amber[500] : "white",
      cursor: "pointer",
    },
    edgeLabelRenderer: {
      position: "absolute",
      transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
      background: "#fff",
      padding: 2,
      borderRadius: 5,
      fontSize: 8,
      border: isSelected ? `1px solid ${colors.amber[500]}` : "1px solid #fff",
      maxWidth: 150,
      textAlign: "center",
    },
    input: {
      position: "absolute",
      transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
      background: "transparent",
      padding: 2,
      borderRadius: 5,
      fontSize: 8,
      border: "none",
      width: "auto",
    },
  };
};
