export interface IConnectedColorPalletProps {
  palletType: IColorPalletType;
  onColorChange: (color: string, palletType: IColorPalletType) => void;
}
export interface IColorPalletProps {
  palletType: IColorPalletType;
  onColorChange: (color: string, palletType: IColorPalletType) => void;
}
export interface IColorPalletStyles {}

export enum IColorPalletType {
  Text = "text",
  TextBackground = "textBackground",
  NodeBackground = "nodeBackground",
  CanvasBackground = "canvasBackground",
}
