import { Edge, Node, useUpdateNodeInternals } from "@xyflow/react";
import React, { createContext, useMemo } from "react";
import {
  nodes as initialNodes,
  edges as initialEdges,
} from "../components/canvas-designer/data/groww-system-design";

export interface IToolbarContext {
  canvasBackgroundColor: string;
  setCanvasBackgroundColor: (color: string) => void;
  activeNode?: Node;
  setActiveNode: (node: Node | undefined) => void;
  activeEdge?: Edge;
  setActiveEdge: (edge: Edge | undefined) => void;
  canvasData: ICanvasData;
  setCanvasData: (data: ICanvasData) => void;
  setBackgroundColor: (color: string) => void;
  setTextColor: (color: string) => void;
  setTextBackgroundColor: (color: string) => void;
  updateNode: (node: Node) => void;
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
  edges: Edge[] | undefined;
  nodes: Node[] | undefined;
  onCanvasSave: () => void;
}

export interface ICanvasData {
  nodes: Node[];
  edges: Edge[];
  title: string;
  description: string;
  canvasBackgroundColor: string;
}

export const ToolbarContext = createContext<IToolbarContext>({
  canvasBackgroundColor: "#5B3FB5",
  setCanvasBackgroundColor: () => {},
  activeNode: undefined,
  activeEdge: undefined,
  setActiveNode: () => {},
  setActiveEdge: () => {},
  canvasData: {
    nodes: [],
    edges: [],
    title: "",
    description: "",
    canvasBackgroundColor: "",
  },
  setCanvasData: (data) => {},
  updateNode: (data) => {},
  setBackgroundColor: () => {},
  setTextColor: () => {},
  setTextBackgroundColor: () => {},
  onCanvasSave: () => {},
  setNodes: () => {},
  setEdges: () => {},
  edges: [],
  nodes: [],
});

export const ToolbarProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [canvasBackgroundColor, setCanvasBackgroundColor] =
    React.useState("#5B3FB5");
  const [activeNode, setActiveNode] = React.useState<Node | undefined>(
    undefined
  );
  const [activeEdge, setActiveEdge] = React.useState<Edge | undefined>(
    undefined
  );
  const [nodes, setNodes] = React.useState<Node[]>([]);
  const [edges, setEdges] = React.useState<Edge[]>([]);

  const [canvasData, setCanvasData] = React.useState<ICanvasData>({
    nodes: [],
    edges: [],
    title: "",
    description: "",
    canvasBackgroundColor: "",
  });

  const updateNodeInternalCanvas = useUpdateNodeInternals();

  const updateNode = (node: Node) => {
    const updatedNodes = nodes.map((n) => {
      if (n.id === node.id) {
        return node;
      }
      return n;
    });
    // setCanvasData({ ...canvasData, nodes: updatedNodes });
    setNodes(updatedNodes);
    setActiveNode(node);
    // updateNodeInternalCanvas(node.id);
  };

  const contextValue: IToolbarContext = useMemo(() => {
    return {
      canvasData: canvasData,
      setCanvasData: (data: ICanvasData) => {
        setCanvasData(data);
      },
      canvasBackgroundColor: canvasBackgroundColor,
      setCanvasBackgroundColor: (color: string) => {
        setCanvasBackgroundColor(color);
      },
      activeNode: activeNode,
      setActiveNode: (node: Node | undefined) => {
        setActiveNode(node);
      },
      activeEdge: activeEdge,
      setActiveEdge: (edge: Edge | undefined) => {
        setActiveEdge(edge);
      },
      updateNode,
      setBackgroundColor: (color: string) => {
        if (activeNode) {
          const nodeInfo = nodes.filter((n) => n.id === activeNode.id)[0];
          const updatedNode = {
            ...nodeInfo,
            data: {
              ...nodeInfo.data,
              style: {
                ...(nodeInfo?.data?.style || {}),
                backgroundColor: color,
              },
            },
          };
          updateNode(updatedNode);
        }
      },
      setTextColor: (color: string) => {
        if (activeNode) {
          const nodeInfo = nodes.filter((n) => n.id === activeNode.id)[0];
          const updatedNode = {
            ...nodeInfo,
            data: {
              ...nodeInfo.data,
              style: { ...(nodeInfo?.data?.style || {}), labelColor: color },
            },
          };
          updateNode(updatedNode);
        }
      },
      setTextBackgroundColor: (color: string) => {
        if (activeNode) {
          const nodeInfo = nodes.filter((n) => n.id === activeNode.id)[0];
          const updatedNode = {
            ...nodeInfo,
            data: {
              ...nodeInfo.data,
              style: {
                ...(nodeInfo?.data?.style || {}),
                labelBackgroundColor: color,
              },
            },
          };
          updateNode(updatedNode);
        }
      },
      onCanvasSave: () => {
        console.log(JSON.stringify(nodes));
        console.log(JSON.stringify(edges));
        console.log(canvasData);
      },
      setNodes,
      setEdges,
      nodes,
      edges,
    };
  }, [
    canvasBackgroundColor,
    activeNode,
    activeEdge,
    nodes,
    edges,
    canvasData,
    updateNode,
  ]);

  return (
    <ToolbarContext.Provider value={contextValue}>
      {children}
    </ToolbarContext.Provider>
  );
};
