import React from "react";
import { ICustomHandleProps } from "./CustomHandle.types";
import { Handle as XYFlowHandle } from "@xyflow/react";
import { getCustomHandleStyles } from "./CustomHandle.styles";

export const CustomHandle: React.FC<ICustomHandleProps> = ({
  type,
  id,
  position,
  isConnectable,
  index,
  onHandleDragEnd,
  onHandleDragStart,
}) => {
  const styles = getCustomHandleStyles(type, position, index ?? 0);
  return (
    <XYFlowHandle
      type={type}
      id={id}
      position={position}
      isConnectable={isConnectable}
      style={styles.root}
      onDragStart={onHandleDragStart}
      onDragEnd={onHandleDragEnd}
    />
  );
};
