import {
  ReactFlow,
  Background,
  Panel,
  BackgroundVariant,
  Controls,
  ConnectionMode,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { ICanvasDesignerProps } from "./CanvasDesigner.types";
import { ConnectedCatalog } from "./panel/catalog/ConnectedCatalog";
import { ConnectedNodeDetailView } from "./panel/node-detail-view/ConnectedNodeDetailView";
import { ConnectedToolBar } from "./tool-bar/ConnectedToolBar";
import { useCanvasInformation } from "../../hooks/useCanvasInformation";
import { ConnectedNodeConfiguration } from "./panel/node-configuration/ConnectedNodeConfiguration";
import { Grid } from "@mui/material";

export const CanvasDesigner: React.FC<ICanvasDesignerProps> = ({
  activeNode,
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  nodeTypes,
  edgeTypes,
  onConnect,
  onNodeDrag,
  fitViewOptions,
  defaultEdgeOptions,
  onNodeDoubleClick,
  onNodeDrop,
  onNodeDragOver,
  onPanelClose,
  onNodeDetailsEdit,
  onCanvasSave,
  onEdgeDoubleClick,
  onReconnect,
  onNodeClick,
  onEdgeClick,
  onSelectionChange,
  onPaneClick,
}) => {
  const proOptions = { hideAttribution: true };
  const isEditable = true;
  const { canvasBackgroundColor } = useCanvasInformation();

  return (
    <>
      <ConnectedToolBar />
      <Grid container>
        <Grid item xs={2} sx={{ bgcolor: "white" }}>
          <ConnectedCatalog />
        </Grid>
        <Grid item xs={10}>
          <div style={{ height: "94vh" }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onConnect={onConnect}
              onNodeDrag={onNodeDrag}
              onDrop={onNodeDrop}
              onDragOver={onNodeDragOver}
              fitView
              fitViewOptions={fitViewOptions}
              defaultEdgeOptions={defaultEdgeOptions}
              proOptions={proOptions}
              onNodeDoubleClick={onNodeDoubleClick}
              selectionKeyCode={null}
              onEdgeDoubleClick={onEdgeDoubleClick}
              onReconnect={onReconnect}
              connectionMode={ConnectionMode.Loose}
              onNodeClick={onNodeClick}
              onEdgeClick={onEdgeClick}
              onSelectionChange={onSelectionChange}
              onPaneClick={onPaneClick}
            >
              <Background
                bgColor={canvasBackgroundColor ?? "#5B3FB5"}
                variant={BackgroundVariant.Dots}
                patternClassName="red"
              />
              <Controls
                showZoom={true}
                showInteractive={false}
                position="bottom-right"
              />
              <Panel position="top-right">
                {activeNode && !isEditable ? (
                  <ConnectedNodeDetailView
                    activeNode={activeNode}
                    onPanelClose={onPanelClose}
                  />
                ) : null}
                {activeNode && isEditable ? (
                  <ConnectedNodeConfiguration
                    activeNode={activeNode}
                    onPanelClose={onPanelClose}
                    onEdit={onNodeDetailsEdit}
                  />
                ) : null}
              </Panel>
              {/* <Panel position="top-center">
          <ConnectedHeaderTitle />
        </Panel> */}
              {/* {!activeNode && (
            <Panel position="top-right">
              <ConnectedActionContainer onCanvasSave={onCanvasSave} />
            </Panel>
          )} */}
            </ReactFlow>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
