import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

interface IEditEdgeModalProps {
  handleClose: () => void;
  open: boolean;
  label: string;
  updateEdgeLabel: (label: string) => void;
}

export const EditEdgeModal: React.FC<IEditEdgeModalProps> = ({
  handleClose,
  open,
  label,
  updateEdgeLabel,
}) => {
  const [edgeLabel, setEdgeLabel] = React.useState(label);

  const saveLabel = () => {
    updateEdgeLabel(edgeLabel);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());
          const email = formJson.email;
          console.log(email);
          handleClose();
        },
      }}
    >
      <DialogTitle>Edit</DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: 12 }}>Current Value</Typography>
        <DialogContentText>{label ?? ""}</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="Label"
          label="Label"
          type="text"
          fullWidth
          variant="standard"
          value={edgeLabel ?? ""}
          onChange={(event) => setEdgeLabel(event.target.value ?? "")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={saveLabel}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
