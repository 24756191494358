import { rotateAnimation } from "../../canvas-designer/panel/catalog/Catalog.styles";
import { IHeaderStyles } from "./Header.types";

export const getHeaderStyles = (): IHeaderStyles => {
  return {
    logo: {
      color: "white",
      cursor: "pointer",
      height: 40,
      width: 40,
      mr: 2,
      // animation: `${rotateAnimation} 8s linear infinite`,
    },
    appBar: {
      backgroundColor: "#fff",
      borderBottom: "0px solid",
      color: "#0b284b",
      pl: 2,
      pr: 2,
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
    },
    largeResolution: { display: { xs: "none", md: "flex" }, mr: 1 },
    smallResolution: { display: { xs: "flex", md: "none" } },
  };
};
