import { IHomeStyles } from "./Home.types";

export const getHomeStyles = (): IHomeStyles => {
  return {
    rootContainer: {
      margin: "auto 0px",
      color: "#0b284b",
      backgroundColor: "transparent",
    },
  };
};
