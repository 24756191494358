/* eslint-disable */
import React, { useState, useCallback, MouseEvent } from "react";
import { CanvasDesigner } from "./CanvasDesigner";
import {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  type Node,
  type Edge,
  type FitViewOptions,
  type OnConnect,
  type OnNodesChange,
  type OnEdgesChange,
  type OnNodeDrag,
  type DefaultEdgeOptions,
  useReactFlow,
  EdgeTypes,
  reconnectEdge,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { CustomNode } from "../nodes/custom-node/CustomNode";
import { IEdgeTypes, INodeTypes } from "../nodes/node.types";
import {
  nodes as cdnInitialNodes,
  edges as cdnInitialEdges,
} from "./data/groww-system-design";
import CustomEdge from "../../ui-library/custom-edge/CustomEdge";
import { EditEdgeModal } from "./EditEdgeModal";
import { useBoolean } from "usehooks-ts";
import { useGetFlowData } from "./data/useGetFlowData";
import { CustomTextNode } from "../nodes/custom-node/CustomTextNode";
import { useParams } from "react-router-dom";

export const ConnectedCanvasDesigner: React.FC = () => {
  const initialNodes: Node[] = cdnInitialNodes;
  const initialEdges: Edge[] = cdnInitialEdges;

  const params = useParams();

  const {
    nodes: canvasNodes,
    edges: canvasEdges,
    title,
    author,
  } = useGetFlowData(params.flowId ?? "");

  const [nodes, setNodes] = useState<Node[]>(canvasNodes);
  const [edges, setEdges] = useState<Edge[]>(canvasEdges);
  const [activeNode, setActiveNode] = useState<Node>();
  const [activeEdge, setActiveEdge] = useState<Edge>();

  const { screenToFlowPosition } = useReactFlow();
  const { value: isEditEdgeLabelModalActive, toggle: toggleEditEdgeModal } =
    useBoolean(false);

  const fitViewOptions: FitViewOptions = {
    padding: 0.2,
  };

  const defaultEdgeOptions: DefaultEdgeOptions = {
    animated: true,
    style: { stroke: "#fff", strokeWidth: 2 },
  };

  const onNodeDrag: OnNodeDrag = (_, node) => {
    // console.log("drag event", node.data);
  };

  const nodeTypes = {
    [INodeTypes.MICROSERVICE]: CustomNode,
    [INodeTypes.DATABASE]: CustomNode,
    [INodeTypes.MESSAGE_QUEUE]: CustomNode,
    [INodeTypes.CLIENT]: CustomNode,
    [INodeTypes.API_GATEWAY]: CustomNode,
    [INodeTypes.EXTERNAL_SERVICE]: CustomNode,
    [INodeTypes.LOAD_BALANCER]: CustomNode,
    [INodeTypes.CLOUD_SERVER]: CustomNode,
    [INodeTypes.DNS]: CustomNode,
    [INodeTypes.APPLICATION]: CustomNode,
    [INodeTypes.REDIS]: CustomNode,
    [INodeTypes.TEXT]: CustomTextNode,
    [INodeTypes.AZURE_COSMOS_DB]: CustomNode,
  };

  const edgeTypes: EdgeTypes = {
    [IEdgeTypes.DEFAULT]: CustomEdge,
  };

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect: OnConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const onNodeDoubleClick = (event: MouseEvent, node: Node) => {
    setActiveNode(node);
  };

  const onNodeConfigurationPanelClose = () => {
    setActiveNode(undefined);
  };

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const getId = () => crypto.randomUUID();

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      // project was renamed to screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type}` },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [screenToFlowPosition]
  );

  const onNodeObjectEdit = (nodeData: any) => {
    const activeNodeId = activeNode?.id;
    if (!activeNodeId) {
      return;
    }
    const updatedNodes = nodes.map((node) => {
      if (node.id === activeNodeId) {
        return {
          ...node,
          data: {
            ...node.data,
            ...nodeData.updated_src.data,
          },
          position: {
            ...node.position,
            ...nodeData.updated_src.position,
          },
          measured: {
            ...node.measured,
            ...nodeData.updated_src.measured,
          },
        };
      }
      return node;
    });
    setNodes(updatedNodes);
  };

  const onCanvasSave = () => {
    console.log(JSON.stringify(nodes));
    console.table(JSON.stringify(edges));
  };

  const onEdgeDoubleClick = (event: MouseEvent, edge: Edge) => {
    toggleEditEdgeModal();
    setActiveEdge(edge);
  };

  const onUpdateEdgeLabel = (label: string) => {
    const activeEdgeId = activeEdge?.id;
    if (!activeEdgeId) {
      return;
    }
    const updatedEdges = edges.map((edge) => {
      if (edge.id === activeEdgeId) {
        return {
          ...edge,
          label: label,
          data: {
            ...edge.data,
            label,
          },
        };
      }
      return edge;
    });
    setEdges(updatedEdges);
    toggleEditEdgeModal();
  };

  const onReconnect = useCallback(
    (oldEdge: any, newConnection: any) =>
      setEdges((els) => reconnectEdge(oldEdge, newConnection, els)),
    []
  );

  return (
    <>
      <CanvasDesigner
        title={title}
        author={author}
        activeNode={activeNode}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        onNodesChange={onNodesChange}
        onNodeDrag={onNodeDrag}
        onEdgesChange={onEdgesChange}
        onNodeDrop={onDrop}
        onNodeDragOver={onDragOver}
        fitViewOptions={fitViewOptions}
        defaultEdgeOptions={defaultEdgeOptions}
        onNodeDoubleClick={onNodeDoubleClick}
        onPanelClose={onNodeConfigurationPanelClose}
        onNodeDetailsEdit={onNodeObjectEdit}
        onCanvasSave={onCanvasSave}
        onEdgeDoubleClick={onEdgeDoubleClick}
        onReconnect={onReconnect}
      />
      {/* <EditEdgeModal
        open={isEditEdgeLabelModalActive}
        handleClose={toggleEditEdgeModal}
        updateEdgeLabel={onUpdateEdgeLabel}
        label={(activeEdge?.label ?? activeEdge?.data?.label ?? "") + "" ?? ""}
      /> */}
    </>
  );
};
