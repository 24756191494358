import React from "react";
import { NodeBackgroundColor } from "./NodeBackgroundColor";
import { IConnectedNodeBackgroundColorProps } from "./NodeBackgroundColor.types";
import { useCanvasInformation } from "../../../../../hooks/useCanvasInformation";

export const ConnectedNodeBackgroundColor: React.FC<
  IConnectedNodeBackgroundColorProps
> = () => {
  const { activeNode, setBackgroundColor } = useCanvasInformation();

  const isNodeSelected = activeNode !== undefined;
  const [colorPalletElement, setColorPalletElement] =
    React.useState<HTMLButtonElement | null>(null);

  const handleOpenColorPalletPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setColorPalletElement(event.currentTarget);
  };

  const handleCloseColorPalletPopover = () => {
    setColorPalletElement(null);
  };

  const handleColorChange = (color: string) => {
    setBackgroundColor(color);
    handleCloseColorPalletPopover();
  };

  return (
    <NodeBackgroundColor
      isNodeSelected={isNodeSelected}
      onButtonClick={handleOpenColorPalletPopover}
      onPopoverClose={handleCloseColorPalletPopover}
      setNodeBackgroundColor={handleColorChange}
      colorPalletElement={colorPalletElement}
    />
  );
};
