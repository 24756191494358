import { colors } from "@mui/material";
import { ICustomNode, ICustomNodeStyles } from "./CustomNode.types";

export const getCustomNodeStyles = (
  properties: ICustomNode
): ICustomNodeStyles => {
  return {
    rootContainer: {
      height: properties.height,
      width: properties.width,
      minWidth: 80,
      minHeight: 60,
      borderRadius: 3,
      border: `2px solid ${properties.isActive ? colors.amber[500] : "white"}`,
      // borderColor: colors.grey[50],
      paddingTop: 1,
      bgcolor: properties.backgroundColor ?? "primary.main",
      "&:hover": {
        bgcolor: properties.backgroundColor ?? "primary.dark",
      },
      background: properties.backgroundColor ?? "primary.main",
    },
    handle: {
      backgroundColor: "green",
      borderColor: "white",
    },
    label: {
      color: properties.styleProperties?.labelColor ?? "white",
      backgroundColor:
        properties.styleProperties?.labelBackgroundColor ?? "transperant",
      fontSize: 10,
      fontWeight: "bold",
      textTransform: "uppercase",
      wordWrap: "break-word",
      maxLines: 2,
      paddingRight: 1,
      paddingLeft: 1,
      borderRadius: 3,
    },
    image: {
      width: properties.imgSize ?? 30,
      height: properties.imgSize ?? 30,
    },
  };
};
