import { INodeTypes } from "../../node.types";
import microserviceImg from "../../../../assets/microservice.png";
import databaseImg from "../../../../assets/database.png";
import loadbalancerImg from "../../../../assets/load_balancer.png";
import mobileImg from "../../../../assets/mobile_message_notification.png";
import queueWhiteImg from "../../../../assets/queue_white.svg";
import externalImg from "../../../../assets/external.png";
import cloudServerImg from "../../../../assets/cloud-server.png";
import dnsImg from "../../../../assets/dns.png";
import redisSvg from "../../../../assets/redis.svg";
import textSvg from "../../../../assets/text.svg";

import { ICustomNode, IHandleType } from "../CustomNode.types";
import { colors } from "@mui/material";
import { Position } from "@xyflow/react";
import { cloudNodes } from "./cloud";

export const useGetNodeProperties = (type: string) => {
  const nodeProperties: ICustomNode = {
    backgroundColor: "primary.main",
    imgSrc: microserviceImg,
    numberOfSources: 1,
    numberOfTargets: 1,
    nodeName: "Node",
    type: type as INodeTypes,
  };

  const azureNodeProperties = {
    backgroundColor: `linear-gradient(135deg, ${colors.blue[500]}, ${colors.blue[900]})`,
    imgSrc: microserviceImg,
    numberOfSources: 1,
    numberOfTargets: 1,
    nodeName: "Node",
    type: type as INodeTypes,
  };

  const node = cloudNodes.filter((node) => node.type === type);

  if (node.length > 0) {
    return {
      ...azureNodeProperties,
      imgSrc: node[0].logo,
      nodeName: node[0].title,
      category: node[0].category,
      subCategory: node[0].subCategory,
    };
  }

  switch (type) {
    case INodeTypes.MICROSERVICE:
      return {
        ...nodeProperties,
        backgroundColor: `linear-gradient(135deg, ${colors.blue[500]}, ${colors.blue[900]})`,
        imgSrc: microserviceImg,
        nodeName: "Microservice",
      };
    case INodeTypes.DATABASE:
      return {
        ...nodeProperties,
        backgroundColor: `linear-gradient(135deg, ${colors.amber[600]}, ${colors.amber[900]})`,
        imgSrc: databaseImg,
        nodeName: "Database",
        handles: [
          {
            position: Position.Bottom,
            index: 2,
            type: IHandleType.Target,
          },
        ],
      };
    case INodeTypes.LOAD_BALANCER:
      return {
        ...nodeProperties,
        backgroundColor: `linear-gradient(135deg, ${colors.lightGreen[500]}, ${colors.lightGreen[900]})`,
        imgSrc: loadbalancerImg,
        nodeName: "Load Balancer",
      };
    case INodeTypes.API_GATEWAY:
      return {
        ...nodeProperties,
        backgroundColor: `linear-gradient(135deg, ${colors.lightGreen[500]}, ${colors.lightGreen[900]})`,
        imgSrc: loadbalancerImg,
        nodeName: "API Gateway",
      };
    case INodeTypes.CLIENT:
      return {
        ...nodeProperties,
        backgroundColor: `linear-gradient(135deg, ${colors.pink[900]}, ${colors.pink[500]})`,
        imgSrc: mobileImg,
        nodeName: "Client",
      };
    case INodeTypes.MESSAGE_QUEUE:
      return {
        ...nodeProperties,
        width: 150,
        backgroundColor: `linear-gradient(135deg, ${colors.green[500]}, ${colors.green[900]})`,
        imgSrc: queueWhiteImg,
        nodeName: "Queue",
      };
    case INodeTypes.EVENT:
      nodeProperties.backgroundColor = "#9c27b0";
      nodeProperties.nodeName = "Event";
      return {
        ...nodeProperties,
        backgroundColor: "#9c27b0",
        nodeName: "Event",
      };
    case INodeTypes.STREAM:
      return {
        ...nodeProperties,
        backgroundColor: "#00bcd4",
        nodeName: "Stream",
      };
    case INodeTypes.FUNCTION:
      return {
        ...nodeProperties,
        backgroundColor: "#4caf50",
        nodeName: "Function",
      };
    case INodeTypes.EXTERNAL_SERVICE:
      return {
        ...nodeProperties,
        imgSrc: externalImg,
        backgroundColor: `linear-gradient(135deg, ${colors.red[400]}, ${colors.red[900]})`,
        nodeName: "External",
      };
    case INodeTypes.CLOUD_SERVER:
      return {
        ...nodeProperties,
        imgSrc: cloudServerImg,
        backgroundColor: `linear-gradient(135deg, ${colors.grey[400]}, ${colors.grey[900]})`,
        nodeName: "Cloud Server",
      };
    case INodeTypes.DNS:
      return {
        ...nodeProperties,
        imgSrc: dnsImg,
        backgroundColor: `linear-gradient(135deg, ${colors.pink[900]}, ${colors.pink[500]})`,
        nodeName: "DNS",
      };
    case INodeTypes.APPLICATION:
      return {
        ...nodeProperties,
        backgroundColor: `linear-gradient(135deg, ${colors.blue[500]}, ${colors.blue[900]})`,
        imgSrc: cloudServerImg,
        nodeName: "Application",
      };
    case INodeTypes.REDIS:
      return {
        ...nodeProperties,
        backgroundColor: `linear-gradient(135deg, ${colors.red[300]}, ${colors.red[600]})`,
        imgSrc: redisSvg,
        nodeName: "Redis",
      };
    case INodeTypes.TEXT:
      return {
        ...nodeProperties,
        backgroundColor: `linear-gradient(135deg, ${colors.grey[500]}, ${colors.grey[900]})`,
        imgSrc: textSvg,
        nodeName: "Text",
      };
    default:
      return nodeProperties;
  }
};
