import { NodeProps } from "@xyflow/react";
import { IHandle } from "./custom-node/CustomNode.types";

export enum INodeTypes {
  MICROSERVICE = "microservice",
  DATABASE = "database",
  API = "api",
  EVENT = "event",
  MESSAGE_QUEUE = "message_queue",
  STREAM = "stream",
  LOAD_BALANCER = "load_balancer",
  API_GATEWAY = "api_gateway",
  GATEWAY = "gateway",
  FUNCTION = "function",
  EXTERNAL_SERVICE = "external_service",
  CLIENT = "client",
  CLOUD_SERVER = "cloud_server",
  DNS = "dns",
  APPLICATION = "application",
  REDIS = "redis",
  TEXT = "text",

  AWS_LAMBDA = "aws_lambda",
  AWS_DYNAMODB = "aws_dynamodb",
  AWS_S3 = "aws_s3",
  AWS_SNS = "aws_sns",
  AWS_SQS = "aws_sqs",
  AWS_API_GATEWAY = "aws_api_gateway",
  AWS_ELASTIC_LOAD_BALANCER = "aws_elastic_load_balancer",
  AWS_RDS = "aws_rds",
  AWS_EKS = "aws_eks",
  AWS_EC2 = "aws_ec2",
  AWS_CLOUDFRONT = "aws_cloudfront",
  AWS_CLOUDWATCH = "aws_cloudwatch",
  AWS_GLUE = "aws_glue",
  AWS_ELASTICACHE = "aws_elasticache",
  AWS_REDSHIFT = "aws_redshift",
  AWS_ROUTE_53 = "aws_route_53",
  AWS_STEP_FUNCTIONS = "aws_step_functions",
  AWS_FARGATE = "aws_fargate",
  AWS_BATCH = "aws_batch",
  AWS_BEANSTALK = "aws_beanstalk",
  AWS_COGNITO = "aws_cognito",
  AWS_IAM = "aws_iam",
  AWS_KINESIS = "aws_kinesis",
  AWS_LAMBDA_EDGE = "aws_lambda_edge",
  AWS_OPENSEARCH = "aws_opensearch",
  AWS_SAGEMAKER = "aws_sagemaker",
  AWS_SES = "aws_ses",
  AWS_SMS = "aws_sms",
  AWS_PINPOINT = "aws_pinpoint",
  AWS_DIRECT_CONNECT = "aws_direct_connect",
  AWS_VPC = "aws_vpc",
  AWS_CLOUDTRAIL = "aws_cloudtrail",
  AWS_CODEBUILD = "aws_codebuild",
  AWS_CODECOMMIT = "aws_codecommit",
  AWS_CODEDEPLOY = "aws_codedeploy",
  AWS_CODEPIPELINE = "aws_codepipeline",
  AWS_CLOUD9 = "aws_cloud9",
  AWS_CLOUD_FORMATION = "aws_cloud_formation",
  AWS_DATA_PIPELINE = "aws_data_pipeline",
  AWS_DMS = "aws_dms",
  AWS_EFS = "aws_efs",
  AWS_EBS = "aws_ebs",
  AWS_EKS_FARGATE = "aws_eks_fargate",
  AWS_FRAUD_DETECTOR = "aws_fraud_detector",
  AWS_FORECAST = "aws_forecast",
  AWS_GLOBAL_ACCELERATOR = "aws_global_accelerator",
  AWS_GUARD_DUTY = "aws_guard_duty",
  AWS_INSPECTOR = "aws_inspector",
  AWS_IOT_CORE = "aws_iot_core",
  AWS_IOT_GREENGRASS = "aws_iot_greengrass",
  AWS_IOT_ANALYTICS = "aws_iot_analytics",
  AWS_LAMBDA_STEP_FUNCTION = "aws_lambda_step_function",
  AWS_LIGHTSAIL = "aws_lightsail",
  AWS_MACIE = "aws_macie",
  AWS_MANAGED_BLOCKCHAIN = "aws_managed_blockchain",
  AWS_MARKETPLACE = "aws_marketplace",
  AWS_MSK = "aws_msk",
  AWS_OPSWORKS = "aws_opsworks",
  AWS_QUICKSIGHT = "aws_quicksight",
  AWS_ROBO_MAKER = "aws_robo_maker",
  AWS_S3_GLACIER = "aws_s3_glacier",
  AWS_SECRETS_MANAGER = "aws_secrets_manager",
  AWS_SECURITY_HUB = "aws_security_hub",
  AWS_SHIELD = "aws_shield",
  AWS_SNOWBALL = "aws_snowball",
  AWS_SQS_FIFO = "aws_sqs_fifo",
  AWS_STORAGE_GATEWAY = "aws_storage_gateway",
  AWS_TRUSTED_ADVISOR = "aws_trusted_advisor",
  AWS_WAF = "aws_waf",
  AWS_WORKDOCS = "aws_workdocs",
  AWS_WORKMAIL = "aws_workmail",
  AWS_WORKSPACES = "aws_workspaces",
  AWS_XRAY = "aws_xray",

  AZURE_API_MANAGEMENT = "azure_api_management",
  AZURE_APP_SERVICE = "azure_app_service",
  AZURE_AZURE_FUNCTIONS = "azure_azure_functions",
  AZURE_AZURE_SEARCH = "azure_azure_search",
  AZURE_BATCH = "azure_batch",
  AZURE_BATCH_AI = "azure_batch_ai",
  AZURE_BLOB_STORAGE = "azure_blob_storage",
  AZURE_CDN = "azure_cdn",
  AZURE_COGNITIVE_SERVICES = "azure_cognitive_services",
  AZURE_COMPUTER_VISION = "azure_computer_vision",
  AZURE_CONTAINER_INSTANCES = "azure_container_instances",
  AZURE_CONTAINER_REGISTRY = "azure_container_registry",
  AZURE_CONTENT_DELIVERY_NETWORK = "azure_content_delivery_network",
  AZURE_COSMOS_DB = "azure_cosmos_db",
  AZURE_DATA_CATALOG = "azure_data_catalog",
  AZURE_DATA_FACTORY = "azure_data_factory",
  AZURE_DATA_LAKE_ANALYTICS = "azure_data_lake_analytics",
  AZURE_DATA_LAKE_STORE = "azure_data_lake_store",
  AZURE_DATABASE_FOR_MYSQL = "azure_database_for_mysql",
  AZURE_DATABASE_FOR_POSTGRESQL = "azure_database_for_postgresql",
  AZURE_DATABASE_MIGRATION_SERVICE = "azure_database_migration_service",
  AZURE_DATABRICKS = "azure_databricks",
  AZURE_DEVTEST_LABS = "azure_devtest_labs",
  AZURE_EVENT_GRID = "azure_event_grid",
  AZURE_EVENT_HUBS = "azure_event_hubs",
  AZURE_FUNCTIONS = "azure_functions",
  AZURE_GENOMICS = "azure_genomics",
  AZURE_IOT_CENTRAL = "azure_iot_central",
  AZURE_IOT_EDGE = "azure_iot_edge",
  AZURE_IOT_HUB = "azure_iot_hub",
  AZURE_KEY_VAULT = "azure_key_vault",
  AZURE_KINESIS = "azure_kinesis",
  AZURE_LOAD_BALANCER = "azure_load_balancer",
  AZURE_LOGIC_APPS = "azure_logic_apps",
  AZURE_MACHINE_LEARNING = "azure_machine",
  AZURE_MACHINE_LEARNING_STUDIO = "azure_machine_learning",
  AZURE_MEDIA_SERVICES = "azure_media_services",
  AZURE_MONITOR = "azure_monitor",
  AZURE_NOTIFICATION_HUBS = "azure_notification_hubs",
  AZURE_REDIS_CACHE = "azure_redis_cache",
  AZURE_SEARCH = "azure_search",
  AZURE_SERVICE_BUS = "azure_service_bus",
  AZURE_SIGNALR_SERVICE = "azure_signalr_service",
  AZURE_SQL_DATABASE = "azure_sql_database",
  AZURE_SQL_DATA_WAREHOUSE = "azure_sql_data_warehouse",
  AZURE_STORAGE = "azure_storage",
  AZURE_TABLE_STORAGE = "azure_table_storage",
  AZURE_TIME_SERIES_INSIGHTS = "azure_time_series_insights",
  AZURE_VIRTUAL_MACHINE = "azure_virtual_machine",
  AZURE_VIRTUAL_NETWORK = "azure_virtual_network",
  AZURE_WEB_APPS = "azure_web_apps",
  AZURE_WEB_JOBS = "azure_web_jobs",
  AZURE_WEB_ROLES = "azure_web_roles",
  AZURE_WEB_SITES = "azure_web_sites",
  AZURE_WORKFLOW = "azure_workflow",
  AZURE_WORKSPACE = "azure_workspace",
  AZURE_FACE_APIS = "azure_face_apis",
  AZURE_CONTENT_MODERATORS = "azure_content_moderators",
  AZURE_SPEECH_SERVICES = "azure_speech_services",
  AZURE_QNA_MAKERS = "azure_qna_makers",
  AZURE_TRANSLATOR_TEXT = "azure_translator_text",
  AZURE_LANGUAGE_UNDERSTANDING = "azure_language_understanding",
  AZURE_IMMERSIVE_READERS = "azure_immersive_readers",
  AZURE_ANOMALY_DETECTOR = "azure_anomaly_detector",
  AZURE_FORM_RECOGNIZERS = "azure_form_recognizers",
  AZURE_EXPERIMENTATION_STUDIO = "azure_experimentation_studio",
  AZURE_OBJECT_UNDERSTANDING = "azure_object_understanding",
  AZURE_METRICS_ADVISOR = "azure_metrics_advisor",
  AZURE_APPLIED_AI_SERVICES = "azure_applied_ai_services",
  AZURE_LANGUAGE = "azure_language",
  AZURE_COGNITIVE_SERVICES_DECISIONS = "azure_cognitive_services_decisions",
  AZURE_SERVERLESS_SEARCH = "azure_serverless_search",
  AZURE_BONSAI = "azure_bonsai",
  AZURE_CONTENT_SAFETY = "azure_content_safety",
  AZURE_OPENAI = "azure_openai",
  AZURE_AI_STUDIO = "azure_ai_studio",
  AZURE_COGNITIVE_SEARCH = "azure_cognitive_search",
  AZURE_BOT_SERVICES = "azure_bot_services",
  AZURE_LOG_ANALYTICS_WORKSPACES = "azure_log_analytics_workspaces",
  AZURE_STREAM_ANALYTICS_JOBS = "azure_stream_analytics_jobs",
  AZURE_ENDPOINT_ANALYTICS = "azure_endpoint_analytics",
  AZURE_SYNAPSE_ANALYTICS = "azure_synapse_analytics",
  AZURE_WORKBOOKS = "azure_workbooks",
  AZURE_PRIVATE_LINK_SERVICES = "azure_private_link_services",
  AZURE_POWER_BI_EMBEDDED = "azure_power_bi_embedded",
  AZURE_POWER_PLATFORM = "azure_power_platform",
  AZURE_DATA_FACTORIES = "azure_data_factories",
  AZURE_HD_INSIGHT_CLUSTERS = "azure_hd_insight_clusters",
  AZURE_DATA_EXPLORER_CLUSTERS = "azure_data_explorer_clusters",
  AZURE_ANALYSIS_SERVICES = "azure_analysis_services",
  AZURE_EVENT_HUB_CLUSTERS = "azure_event_hub_clusters",
  AZURE_DATA_LAKE_STORE_GEN1 = "azure_data_lake_store_gen1",
  AZURE_APP_SERVICE_PLANS = "azure_app_service_plans",
  AZURE_APP_SERVICE_CERTIFICATES = "azure_app_service_certificates",
  AZURE_APP_SERVICE_DOMAINS = "azure_app_service_domains",
  AZURE_CDN_PROFILES = "azure_cdn_profiles",
  AZURE_APP_SERVICES = "azure_app_services",
  AZURE_APP_SERVICE_ENVIRONMENTS = "azure_app_service_environments",
  AZURE_DATABASE_MIGRATION_SERVICES = "azure_database_migration_services",
  AZURE_DATABASE_MYSQL_SERVER = "azure_database_mysql_server",
  AZURE_PURVIEW_ACCOUNTS = "azure_purview_accounts",
  AZURE_ORACLE_DATABASE = "azure_oracle_database",
  AZURE_SQL_SERVER_STRETCH_DATABASES = "azure_sql_server_stretch_databases",
  AZURE_DATABASE_POSTGRESQL_SERVER_GROUP = "azure_database_postgresql_server_group",
  AZURE_SQL_EDGE = "azure_sql_edge",
  AZURE_INSTANCE_POOLS = "azure_instance_pools",
  AZURE_ELASTIC_JOB_AGENTS = "azure_elastic_job_agents",
  AZURE_SQL_ELASTIC_POOLS = "azure_sql_elastic_pools",
  AZURE_SQL_MANAGED_INSTANCE = "azure_sql_managed_instance",
  AZURE_DATABASE_MARIADB_SERVER = "azure_database_mariadb_server",
  AZURE_SQL = "azure_sql",
  AZURE_SQL_SERVER_REGISTRIES = "azure_sql_server_registries",
  AZURE_SQL_VM = "azure_sql_vm",
  AZURE_VIRTUAL_CLUSTERS = "azure_virtual_clusters",
  AZURE_SQL_SERVER = "azure_sql_server",
  AZURE_MANAGED_DATABASE = "managed_database",
  AZURE_CACHE_REDIS = "azure_cache_redis",
  AZURE_SQL_DATA_WAREHOUSES = "azure_sql_data_warehouses",
  AZURE_DATABASE_POSTGRESQL_SERVER = "azure_database_postgresql_server",
  AZURE_APPLICATION_INSIGHTS = "azure_application_insights",

  GOOGLE_APIS = "google_apis",
  GOOGLE_APP_ENGINE = "google_app_engine",
  GOOGLE_BIG_QUERY = "google_big_query",
  GOOGLE_CLOUD_FUNCTIONS = "google_cloud_functions",
  GOOGLE_CLOUD_STORAGE = "google_cloud_storage",
  GOOGLE_COMPUTE_ENGINE = "google_compute_engine",
  GOOGLE_CONTAINER_ENGINE = "google_container_engine",
  GOOGLE_DATAFLOW = "google_dataflow",
  GOOGLE_DATAPROC = "google_dataproc",
  GOOGLE_FIREBASE = "google_firebase",
  GOOGLE_FIRESTORE = "google_firestore",
  GOOGLE_KUBERNETES_ENGINE = "google_kubernetes_engine",
  GOOGLE_LOAD_BALANCING = "google_load_balancing",
  GOOGLE_MEMORY_STORE = "google_memory_store",
  GOOGLE_PUB_SUB = "google_pub_sub",
  GOOGLE_SQL = "google_sql",
  GOOGLE_STACKDRIVER = "google_stackdriver",
  GOOGLE_VPN = "google_vpn",
  GOOGLE_WORKFLOW = "google_workflow",
  GOOGLE_WORKSPACE = "google_workspace",
  GOOGLE_STORAGE = "google_storage",
  GOOGLE_DATABASE = "google_database",
  GOOGLE_NETWORKING = "google_networking",
  GOOGLE_BIG_DATA = "google_big_data",
  GOOGLE_MANAGEMENT = "google_management",
  GOOGLE_IDENTITY = "google_identity",
  GOOGLE_SECURITY = "google_security",
  GOOGLE_DEVOPS = "google_devops",
  GOOGLE_MACHINE_LEARNING = "google_machine_learning",
  GOOGLE_CLOUD_SQL = "google_cloud_sql",
  GOOGLE_CLOUD_SPANNER = "google_cloud_spanner",
  GOOGLE_CLOUD_CDN = "google_cloud_cdn",
  GOOGLE_CLOUD_LOAD_BALANCING = "google_cloud_load_balancing",
  GOOGLE_CLOUD_IAM = "google_cloud_iam",
  GOOGLE_CLOUD_KMS = "google_cloud_kms",
  GOOGLE_CLOUD_PUBSUB = "google_cloud_pubsub",
  GOOGLE_CLOUD_DATAFLOW = "google_cloud_dataflow",
  GOOGLE_CLOUD_DATAPROC = "google_cloud_dataproc",
  GOOGLE_CLOUD_BIGQUERY = "google_cloud_bigquery",
  GOOGLE_CLOUD_AI = "google_cloud_ai",
  GOOGLE_CLOUD_ML_ENGINE = "google_cloud_ml_engine",
}

export enum IEdgeTypes {
  DEFAULT = "default",
}

export interface INodeCSSProperties {
  backgroundColor: string;
  labelColor: string;
  labelBackgroundColor: string;
}

export interface INodeProps extends NodeProps {
  data: {
    type: INodeTypes;
    label: string;
    handles: IHandle[];
    description?: string;
    isNodeSelected?: boolean;
    isNodeActive?: boolean;
    style?: INodeCSSProperties;
  };
}
