import { HandleType, Position } from "@xyflow/react";
import { ICustomHandleStyles } from "./CustomHandle.types";

export const getCustomHandleStyles = (
  type: HandleType,
  position: Position,
  handleIndex: number
): ICustomHandleStyles => {
  return {
    root: {
      backgroundColor: type === "source" ? "white" : "white",
      borderColor: "white",
      [position === Position.Left || position === Position.Right
        ? "top"
        : "left"]: handleIndex,
    },
  };
};
