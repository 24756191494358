import { colors } from "@mui/material";
import { INodeListStyles } from "./NodeList.types";

export const getNodeListStyles = (): INodeListStyles => {
  return {
    rootContainer: {
      width: 280,
      backgroundColor: "white",
    },
    title: { pl: 2, pt: 1, color: colors.blue[800], fontWeight: "bold" },
    hideButton: {},
    nodeTitle: {
      color: "white",
      fontSize: 10,
      fontWeight: "bold",
      textTransform: "uppercase",
      wordWrap: "break-word",
      maxLines: 1,
    },
    nodeContainer: {
      pr: 1,
      maxHeight: 80,
      m: 2,
      borderRadius: 2,
      border: "1px solid white",
      width: 100,
    },
  };
};
