import React, { useEffect, useMemo } from "react";
import { getCustomNodeStyles } from "./CustomNode.styles";
import { Typography } from "@mui/material";
import { useUpdateNodeInternals } from "@xyflow/react";
import { ICustomNodeProps } from "./CustomNode.types";
import { useGetNodeProperties } from "./hooks/useGetNodeProperties";

export const CustomTextNode: React.FC<ICustomNodeProps> = ({
  id: nodeId,
  data,
  type,
  selected,
}) => {
  const { label, style: stylesOverrides } = data;
  const nodeProperties = useGetNodeProperties(type);

  // const handles = nodeProperties.handles || [];

  const styles = useMemo(
    () =>
      getCustomNodeStyles({
        ...nodeProperties,
        styleProperties: stylesOverrides,
        isActive: selected,
      }),
    [selected, nodeProperties]
  );

  useEffect(() => {
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals(nodeId);
  }, [nodeId, updateNodeInternals]);

  return (
    <Typography variant="body2" sx={styles.label}>
      {label}
    </Typography>
  );
};
